// ** React Imports
import * as React from 'react'

// ** Component Imports
import MUIPagination from '../../components/MUIDatagrid/MUIPagination'

// ** MUI Imports
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { styled } from '@mui/material'

export const CustomCollapseTableRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableRow-root': {
    // borderBottom: '0.5px var(--border-color) solid',
    borderBottom: 'unset'
  },
  '& .MuiTableCell-root': {
    // borderBottom: '0.5px var(--border-color) solid',
    borderBottom: 'unset',
    fontSize: '13.5px',
    fontWeight: '300',
    padding: '12px'
  }
}))

export const CustomCollapseTableCell = styled(TableCell)(({ theme }) => ({
  // Add your custom styles here
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  // Add your custom styles here
}))

export const CustomCollapse = styled(Collapse)(({ theme }) => ({
  // Add your custom styles here
}))

export const CustomBox = styled(Box)(({ theme }) => ({
  // Add your custom styles here
}))

export const CustomTypography = styled(Typography)(({ theme }) => ({
  // Add your custom styles here
}))

export const CustomCollapseTable = styled(Table)(({ theme }) => ({
  '& *': {
    color: 'var(--text-color)',
    fontFamily: 'inherit'
  },
  '& .MuiTableCell-root': {
    fontFamily: 'inherit',
    color: 'var(--text-color)',
    zIndex: 1
  },

  // For coin display component
  '& .coinTagContainer, & .tableButton, & .tableButton path': {
    color: 'var(--primary-color)'
  },
  '& .tableButton:hover path': {
    color: 'black'
  }
}))

export const CustomCollapseTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-head': {
    color: 'var(--gray-color) !important',
    fontFamily: 'inherit',
    fontWeight: '400 !important',
    borderBottom: '1.25px var(--border-color) solid !important',
    borderTop: '1.25px var(--border-color) solid',
    backgroundColor: 'var(--secondary-color)',
    zIndex: 0
  }
}))

export const CustomCollapseTableBody = styled(TableBody)(({ theme }) => ({
  // Add your custom styles here
}))

export const CustomCollapseTableContainer = styled(TableContainer)(({ theme }) => ({
  // Add your custom styles here
}))

function CollapsableTable({
  columns,
  rows,
  renderCollapseContent,
  hideFooter = false,
  hideHeader = false,
  isCollapseDisabled = () => {},
  height,
  isTableLayoutFixed = true,
  showScroll
}) {
  // ** Collapsable Row Component
  function Row({ row, renderCollapseContent }) {
    const [open, setOpen] = React.useState(false)

    return (
      <>
        <CustomCollapseTableRow
          sx={{
            '& > *:first-child': { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' },
            '& > *:last-child': { borderTopRightRadius: '5px', borderBottomRightRadius: '5px' },
            '& > *': { borderBottom: 'unset' },
            '&:hover': { backgroundColor: 'var(--background-color)' },
            ':hover .coinTagContainer': { backgroundColor: 'var(--secondary-color)' }
          }}
        >
          <CustomCollapseTableCell sx={{ opacity: isCollapseDisabled(row) ? 0.35 : 1, zIndex: -1, minWidth: '40px' }}>
            <IconButton
              disabled={isCollapseDisabled(row)}
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <BiChevronUp /> : <BiChevronDown />}
            </IconButton>
          </CustomCollapseTableCell>
          {columns?.map((cell, index) => (
            <CustomCollapseTableCell key={index} sx={{ width: cell.width }}>
              {cell.renderCell(row)}
            </CustomCollapseTableCell>
          ))}
        </CustomCollapseTableRow>
        <CustomCollapseTableRow>
          <CustomCollapseTableCell
            style={{ padding: 0 }}
            //  colSpan={row.cells.length + 1}
            colSpan={6}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              {renderCollapseContent(row)}
            </Collapse>
          </CustomCollapseTableCell>
        </CustomCollapseTableRow>
      </>
    )
  }

  return (
    <>
      <CustomCollapseTableContainer sx={{ maxHeight: height }}>
        <CustomCollapseTable
          stickyHeader={showScroll}
          aria-label='collapsible table'
          sx={{ tableLayout: isTableLayoutFixed }}
        >
          <CustomCollapseTableHead sx={{ zIndex: 999, display: hideHeader && 'none' }}>
            <CustomCollapseTableRow>
              <CustomCollapseTableCell sx={{ width: '40px', minWidth: '40px', zIndex: '999 !important' }} />
              {columns?.map((column, index) => (
                <CustomCollapseTableCell
                  key={index}
                  sx={{ width: column.width, minWidth: column.width, zIndex: '999 !important' }}
                >
                  {column.headerTitle}
                </CustomCollapseTableCell>
              ))}
            </CustomCollapseTableRow>
          </CustomCollapseTableHead>
          <CustomCollapseTableBody>
            {rows?.map((row, index) => (
              <Row key={index} row={row} renderCollapseContent={renderCollapseContent} />
            ))}
          </CustomCollapseTableBody>
        </CustomCollapseTable>
      </CustomCollapseTableContainer>

      {!hideFooter && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '14.5px 0',
            borderTop: '1px var(--border-color) solid'
          }}
        >
          <div style={{ color: 'var(--gray-color)' }}>
            {/* {`${page * pageSize - (pageSize - 1)}-${page * pageSize > totalCount ? totalCount : page * pageSize}${
              totalCount ? ` of ${totalCount}` : ''
            }`} */}
            10-15 of 15
          </div>
          <MUIPagination pageSize={10} totalCount={110} page={1} />
        </Box>
      )}
    </>
  )
}

export default CollapsableTable
