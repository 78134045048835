// ** React Imports
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// ** Component Imports
import Accordions from '../../../../components/Accordions/Accordions'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import { _getFAQCategories, _getFAQQuestions } from '../../../FAQs/redux/actions'
import { setFAQCategories } from '../../../FAQs/redux/slices/FAQCategoriesSlice'
import { setFAQQuestions } from '../../../FAQs/redux/slices/FAQQuestionsSlice'

// ** Redux Actions (Adjust import as per your file structure)

function FAQ() {
  const dispatch = useDispatch()
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const categories = useSelector(state => state.FAQCategories.value) // Assuming your categories are stored in Redux state

  useEffect(() => {
    // Fetch FAQ categories
    _getFAQCategories(
      data => {
        dispatch(setFAQCategories(data))
      },
      err => {
        console.log(err)
      }
    )
  }, [dispatch]) // Only dispatch dependency needed here

  useEffect(() => {
    // Fetch FAQ questions
    _getFAQQuestions(
      data => {
        dispatch(setFAQQuestions(data))
        // Map category_id to category names and filter by "Buy Crypto"
        const filteredQuestions = data.map(question => ({
          ...question,
          category: categories.find(category => category.id === question.category_id)
        })).filter(question => question.category.name === 'Buy Crypto')

        setFilteredQuestions(filteredQuestions)
      },
      err => {
        console.log(err)
      }
    )
  }, [dispatch, categories])
  
  return (
    <div style={{ margin: '150px 0' }}>
      <SectionHeader title='FAQ' />
      <Accordions containerStyle={{ maxWidth: 700, margin: '0 auto' }} accordions={filteredQuestions} />
    </div>
  )
}

export default FAQ
