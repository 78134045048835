// ** API Imports
import { API } from '../../../../../../utility/API'

// ************************************//
export const _fetchSubscriptionDetails = (id, callback, callbackErr) => {
  API.get(`trading/account/subscriptions/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
// ************************************//
export const _redeemFixedStaking = (id, callback, callbackErr) => {
  API.post(`trading/account/staking/redeem_early`, { id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
// ************************************//
export const _unstakeFlexibleStaking = ({ id, amount }, callback, callbackErr) => {
  API.post(`trading/account/unstake`, { id, amount })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
// ************************************//
