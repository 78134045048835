// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Component Imports
import Button from '../../../../components/Button/Button'
import WenbitModal from '../../../../components/Modal/Modal'
import AmountDisplay from '../../../../components/AmountDisplay'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'

// ** Helpers Imports
import { closeViewStakingSubscriptionHistoryModal } from './helpers'
import { formatDate } from '../../../../utility/formatDate'
import { capitalize } from '@mui/material'

function ViewStakingSubscriptionHistoryModal() {
  // ** States
  const modalOpen = useSelector(state => state.viewStakingSubscriptionHistoryModalState.open)
  const modalData = useSelector(state => state.viewStakingSubscriptionHistoryModalState.data)

  // ** Handlers
  const handleClose = () => {
    closeViewStakingSubscriptionHistoryModal()
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title='Staking Subscription'
        render={() => (
          <div className={styles.container}>
            <CoinDisplay useReduxStore coinId={modalData?.currency_id} />

            {/* Total Amount */}
            <div className={styles.totalAmountContainer}>
              <span className={styles.totalAmountTitle}>Total Amount</span>
              <span className={styles.totalAmountValue}>
                <AmountDisplay coinId={modalData?.currency_id} amount={modalData?.amount} />{' '}
                {modalData?.currency_id?.toUpperCase()}
              </span>
            </div>

            {/* Details */}
            <div className={styles.detailsContainer}>
              <div className={styles.infoRow}>
                <span>Type</span>
                <span>{capitalize(modalData?.type ?? '')}</span>
              </div>

              <div className={styles.infoRow}>
                <span>Subscription Date</span>
                <span>{formatDate(modalData?.subscription_date)}</span>
              </div>

              {modalData?.type === 'fixed' && (
                <div className={styles.infoRow}>
                  <span>Duration</span>
                  <span>{modalData?.duration} Days</span>
                </div>
              )}

              {modalData?.type === 'flexible' && (
                <div className={styles.infoRow}>
                  <span>Daily Rewards</span>
                  <span>
                    <AmountDisplay amount={modalData?.daily_reward} coinId={modalData?.currency_id} />{' '}
                    {modalData?.currency_id?.toUpperCase()}
                  </span>
                </div>
              )}

              <div className={styles.infoRow}>
                <span>Earned</span>
                <span>
                  <AmountDisplay amount={modalData?.earned} coinId={modalData?.currency_id} />{' '}
                  {modalData?.currency_id?.toUpperCase()}
                </span>
              </div>

              <div className={styles.infoRow}>
                <span>State</span>
                <span>{capitalize(modalData?.state ?? '')}</span>
              </div>
            </div>
          </div>
        )}
      />
    </>
  )
}

export default ViewStakingSubscriptionHistoryModal
