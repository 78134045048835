// ** React Imports
import React, { useEffect, useState } from 'react'

// ** React Router Imports
import { useNavigate } from 'react-router-dom'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Icons Imports
import { PiPiggyBankBold } from 'react-icons/pi'
import { ReactComponent as LinkIcon } from '../../../../../../../../assets/icons/imported/link.svg'
import { ReactComponent as WalletIcon } from '../../../../../../../../assets/icons/imported/wallet.svg'

// ** Component Imports
import CollapsableTable, {
  CustomCollapseTable,
  CustomCollapseTableBody,
  CustomCollapseTableCell,
  CustomCollapseTableContainer,
  CustomCollapseTableHead,
  CustomCollapseTableRow
} from '../../../../../../../../components/CollapsableTable'
import CoinDisplay from '../../../../../../../../components/CoinDisplay/CoinDisplay'
import TableButton from '../../../../../../../../components/TableButton/TableButton'
import { _getCoinView } from '../../../../../../redux/actions'
import AmountDisplay from '../../../../../../../../components/AmountDisplay'
import { handleDeposit } from '../../../../../../../../utility/deposit-withdraw-helpers/handleDeposit'
import { showStakingSubscribeModalWithApiCall } from '../../../../../../../modals/staking/staking-subscribe-modal/helpers'
import { handleWithdraw } from '../../../../../../../../utility/deposit-withdraw-helpers/handleWithdraw'
import { capitalize, useMediaQuery, Box, IconButton } from '@mui/material'
import CoinTag from '../../../../../../../../components/CoinTag/CoinTag'
import { BiChevronUp } from 'react-icons/bi'

function CoinView() {
  // ** Media Queries
  const isMobileView = useMediaQuery('(max-width:866px)')

  // ** Hooks
  const navigate = useNavigate()

  // ** States
  const [coinsData, setCoinsData] = useState([])
  const [coinsLoading, setCoinsLoading] = useState(false)

  // ** Handlers
  const fetchCoinsData = () => {
    setCoinsLoading(true)
    _getCoinView(
      res => {
        setCoinsLoading(false)
        setCoinsData(res.data)
      },
      err => {
        console.error(``)
        setCoinsLoading(false)
      }
    )
  }

  useEffect(() => {
    fetchCoinsData()
  }, [])

  const renderCollapseContent = row => {
    const wallets = row.wallets

    if (isMobileView)
      return (
        <Box sx={{ borderBottom: '1px var(--border-color) solid !important' }}>
          {wallets.map(wallet => (
            <Box
              sx={{
                m: '10px',
                ml: '60px',
                '& > *': { fontSize: '12px !important' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Box>
                  {wallet.wallet_type === 'earn' ? (
                    <PiPiggyBankBold size={18} />
                  ) : (
                    wallet.wallet_type === 'spot' && <WalletIcon width={16} />
                  )}
                </Box>
                <span>{capitalize(wallet.wallet_type)} Wallet</span>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <Box>
                  <AmountDisplay coinId={row.currency_id} amount={wallet.balance} />
                </Box>
                <Box sx={{ color: 'var(--gray-color) !important', fontSize: '10px' }}>
                  <AmountDisplay coinId='usdt' amount={wallet.usdt_equivalent} />
                  USDT
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )

    if (!isMobileView)
      return (
        <CustomCollapseTableContainer>
          <CustomCollapseTable>
            <CustomCollapseTableHead
              sx={{
                zIndex: 999,
                '& .MuiTableCell-root': { p: '0 !important' },
                '& .MuiTableCell-head': { border: 'none !important' }
              }}
            >
              <CustomCollapseTableRow sx={{ height: 0 }}>
                <CustomCollapseTableCell sx={{ width: '40px', height: 0 }} />
                {columns?.map((column, index) => (
                  <CustomCollapseTableCell key={index} sx={{ width: column.width }}>
                    {/* {column.headerTitle} */}
                  </CustomCollapseTableCell>
                ))}
              </CustomCollapseTableRow>
            </CustomCollapseTableHead>
            <CustomCollapseTableBody>
              {wallets.map(wallet => (
                <CustomCollapseTableRow
                  key={wallet.currency_id}
                  sx={{
                    '&': {
                      borderBottom: '1.15px var(--border-color) solid !important'
                    },
                    ':hover .coinTagContainer': { backgroundColor: 'var(--secondary-color)' }
                  }}
                >
                  <CustomCollapseTableCell sx={{ width: '40px', minWidth: '40px' }}>
                    <IconButton sx={{ opacity: 0 }} aria-label='expand row' size='small'>
                      <BiChevronUp />
                    </IconButton>
                  </CustomCollapseTableCell>
                  <CustomCollapseTableCell
                    sx={{
                      width: '220px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '9px'
                    }}
                  >
                    {wallet.wallet_type === 'earn' ? (
                      <PiPiggyBankBold size={18} />
                    ) : (
                      wallet.wallet_type === 'spot' && <WalletIcon width={16} />
                    )}
                    <span>{capitalize(wallet.wallet_type)} Wallet</span>
                  </CustomCollapseTableCell>
                  <CustomCollapseTableCell sx={{ width: '120px' }}>{wallet.balance}</CustomCollapseTableCell>
                  <CustomCollapseTableCell sx={{ width: '120px' }}>{wallet.usdt_equivalent}</CustomCollapseTableCell>
                  <CustomCollapseTableCell sx={{ width: '380px' }}>{wallet.ratio}%</CustomCollapseTableCell>
                </CustomCollapseTableRow>
              ))}
            </CustomCollapseTableBody>
          </CustomCollapseTable>
        </CustomCollapseTableContainer>
        // <>
        //   {row.wallets.map((wallet, index) => (
        //     <CustomCollapseTableRow
        //       sx={{
        //         '& > *': { borderBottom: 'unset' },
        //         '&': {
        //           borderBottom: index === 0 && '1.15px var(--border-color) solid !important'
        //         },
        //         ':hover .coinTagContainer': { backgroundColor: 'var(--secondary-color)' }
        //       }}
        //     >
        //       <CustomCollapseTableCell sx={{ width: '40px' }} />
        //       <CustomCollapseTableCell
        //         sx={{ width: '220px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '9px' }}
        //       >
        //         {wallet.wallet_type === 'earn' ? (
        //           <PiPiggyBankBold size={18} />
        //         ) : (
        //           wallet.wallet_type === 'spot' && <WalletIcon width={16} />
        //         )}
        //         <span>{capitalize(wallet.wallet_type)} Wallet</span>
        //       </CustomCollapseTableCell>
        //       <CustomCollapseTableCell sx={{ width: '120px' }}>
        //         <div>{wallet.balance}</div>
        //       </CustomCollapseTableCell>
        //       <CustomCollapseTableCell sx={{ width: '120px' }}>{wallet.usdt_equivalent}</CustomCollapseTableCell>
        //       <CustomCollapseTableCell sx={{ width: '120px' }}>
        //         <div>{wallet.ratio}%</div>
        //       </CustomCollapseTableCell>
        //     </CustomCollapseTableRow>
        //   ))}
        // </>
      )
  }

  const columns = isMobileView
    ? [
        {
          id: 'currency_id',
          width: '100%',
          headerTitle: 'Coin',
          renderCell: row => (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <CoinDisplay
                coinImgURL={row.icon_url}
                coinName={
                  <Box sx={{ mb: '-10px' }}>
                    <span>{row.coin_name}</span>
                    <CoinTag tag={row.currency_id.toUpperCase()} style={{ scale: '0.8', zIndex: '-999' }} />
                  </Box>
                }
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  gap: '2px'
                }}
              >
                <AmountDisplay amount={row.total_balance} coinId={row.currency_id} />
                <Box sx={{ color: 'var(--gray-color) !important', fontSize: '12px' }}>
                  <AmountDisplay amount={row.usdt_equivalent} coinId='usdt' />
                  USDT
                </Box>
              </Box>
            </Box>
          )
        }
      ]
    : [
        {
          id: 'id',
          width: '220px',
          headerTitle: 'Coin',
          renderCell: row => (
            <CoinDisplay coinImgURL={row.icon_url} coinTag={row.currency_id.toUpperCase()} coinName={row.coin_name} />
          )
        },
        {
          id: 'balance',
          width: '120px',
          headerTitle: 'Balance',
          renderCell: row => <AmountDisplay coinId={row.currency_id} amount={row.total_balance} />
        },
        {
          id: 'amount',
          width: '120px',
          headerTitle: 'Amount(USDT)',
          renderCell: row => <AmountDisplay coinId={row.currency_id} amount={row.usdt_equivalent} />
        },
        {
          id: 'action',
          width: '380px',
          headerTitle: 'Action',
          renderCell: row => (
            <div className={styles.actionButtonsContainer}>
              <TableButton
                buttonText='Deposit'
                buttonIcon={<LinkIcon width={11} />}
                onClick={() => {
                  handleDeposit(row.currency_id)
                }}
              />
              <TableButton
                buttonText='Withdraw'
                buttonIcon={<LinkIcon width={11} />}
                onClick={() => {
                  handleWithdraw({ selectedCoinId: row.currency_id })
                }}
              />
              <TableButton
                buttonText='Convert'
                buttonIcon={<LinkIcon width={11} />}
                onClick={() => navigate(`/convert/${row.currency_id.toUpperCase()}_USDT`)}
              />
              {row.stakable && (
                <TableButton
                  buttonText='Stake'
                  buttonIcon={<LinkIcon width={11} />}
                  onClick={() => {
                    showStakingSubscribeModalWithApiCall(row.currency_id)
                  }}
                />
              )}
            </div>
          )
        }
      ]
  return (
    <>
      <CollapsableTable
        hideFooter
        columns={columns}
        rows={coinsData}
        renderCollapseContent={renderCollapseContent}
        height='465px'
        showScroll
      />
    </>
  )
}

export default CoinView
