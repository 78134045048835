import React, { useEffect, useState } from "react"

// ** Styles Imports
import Styles from "./onOffRamp.module.css"

// ** Constants Imports
import { faq } from "./faq"

// ** Assets Imports
import { ReactComponent as Visa } from "../../../../assets/images/payments/visa.svg"
import { ReactComponent as VisaLight } from "../../../../assets/icons/imported/visa-light.svg"
import { ReactComponent as PayPal } from "../../../../assets/images/payments/paypal-text.svg"
import { ReactComponent as Maestro } from "../../../../assets/images/payments/maestro-text.svg"

// ** Icons Imports
import { ReactComponent as LinkIcon } from "../../../../assets/icons/imported/link.svg"
import { ReactComponent as GraphIcon } from "../../components/StepsCard/assets/graph-icon.svg"
import { ReactComponent as AddUserIcon } from "../../components/StepsCard/assets/add-user-icon.svg"
import { ReactComponent as CreditCardIcon } from "../../components/StepsCard/assets/credit-card-icon.svg"

// ** MUI Imports
import { Box, Grid, useMediaQuery } from "@mui/material"

// ** Components Imports
import Card from "../../components/StepsCard"
import Accordions from "../../../../components/Accordions/Accordions"
import TableButton from "../../../../components/TableButton/TableButton"

import SectionHeader from "../../../../components/SectionHeader/SectionHeader"

import BuyCryptoForm from "../../components/BuyCryptoForm"
import { useSelector } from "react-redux"

const OnOffRamp = () => {
  // ** States
  const theme = useSelector((state) => state.theme.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  // ** Title Document
  document.title = `${exchangeName}: Buy Crypto`

  // ** Media Queries
  const isTabletView = useMediaQuery("(max-width:800px)")

  return (
    <div className={Styles.pageContainer}>
      <Grid container sx={{ mb: 20 }}>
        {!isTabletView && (
          <Grid item sm={7} className={Styles.headerLeft}>
            <h1>
              Buy crypto <br /> in a few easy steps
            </h1>
            <div style={{ margin: "60px 0" }}>
              Buy Bitcoin & 99+ cryptocurrency with 50+ fiat currencies
            </div>

            <div>We accept</div>
            <div className={Styles.paymentIconsContainer}>
              {theme === "dark" ? (
                <Visa width={50} height={20} />
              ) : (
                <VisaLight width={50} height={20} />
              )}

              <Maestro />
            </div>
          </Grid>
        )}
        <Grid
          item
          sm={isTabletView ? 9 : 5}
          sx={{
            width: "100%",
            height: "fit-content",
            margin: "0 auto",
            backgroundColor: "var(--secondary-color)",
            p: "25px",
            borderRadius: "10px"
          }}
        >
          <div className={Styles.buyCryptoButtonContainer}>
            <h2>Buy Crypto</h2>
            <TableButton
              //   onClick={redirectToConvertHistory}
              buttonText="Fiat Deposit History"
              buttonIcon={
                <LinkIcon
                  style={{
                    width: 12
                  }}
                />
              }
            />
          </div>
          {isTabletView && <h1>in a Few Easy Steps</h1>}
          <BuyCryptoForm />
        </Grid>
      </Grid>

      <SectionHeader title="3 easy steps" />
      <div className={Styles.cardsContainer}>
        <Card
          title="Sign Up"
          description={
            <>
              Join the ranks of <br />
              our elite trading community.
            </>
          }
          icon={<AddUserIcon width={40} />}
        />
        <Card
          title="Verify"
          description={
            <>
              Keep our security team happy.
              <br />
              We want to make sure you’re elite.
            </>
          }
          icon={<CreditCardIcon width={40} />}
        />
        <Card
          title="Trade"
          description={
            <>
              Take on the market
              <br />
              like a boss.
            </>
          }
          icon={<GraphIcon width={40} />}
        />
      </div>

      {/* FAQs */}
      <SectionHeader title="FAQ" />
      <Accordions
        containerStyle={{ maxWidth: 700, margin: "0 auto" }}
        accordions={faq}
      />
    </div>
  )
}

export default OnOffRamp
