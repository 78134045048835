import { API, BINANCE_API } from "../../../../utility/API"
import axios from "axios"
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"

//************************************//
export const _getExchangeInfo = (callback, callbackErr) => {
  API.get(`authenticate/public/customization/exchange_informations`)
    .then(function (data) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
