// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Styles Imports
import Styles from './coinDisplay.module.css'

// ** Component Imports
import CoinTag from '../CoinTag/CoinTag'

function CoinDisplay({ coinName, coinImgURL, coinTag, fontSize = 13.5, useReduxStore = false, coinId = null }) {
  // ** Redux Saved Currencies
  const currencies = useReduxStore ? useSelector(state => state.currencies.value) : null

  if (useReduxStore) {
    const selectedCoin = currencies.find(coin => coin.id === coinId)

    if (!selectedCoin) return <></>

    return (
      <div className={Styles.container}>
        <img src={selectedCoin.icon_url} className={Styles.coinImg} />

        <span className={Styles.coinName} style={{ fontSize }}>
          {selectedCoin.name}
        </span>

        <CoinTag tag={coinTag ?? selectedCoin.id.toUpperCase()} />
      </div>
    )
  }

  return (
    <div className={Styles.container}>
      {coinImgURL && <img src={coinImgURL} className={Styles.coinImg} />}
      {coinName && (
        <span className={Styles.coinName} style={{ fontSize }}>
          {coinName}
        </span>
      )}
      {coinTag && <CoinTag tag={coinTag.toUpperCase()} />}
    </div>
  )
}

export default CoinDisplay
