import React, { useEffect } from 'react'

// redux
import { useSelector } from "react-redux"

// assets
// import LogoDark from "../../../assets/icons/wen-logos/wen-header-dark.svg"
// import LogoLight from "../../../assets/icons/wen-logos/wen-header-light.svg"

function WenbitLogo({ size = 120, style, ...rest }) {
  const theme = useSelector((state) => state.theme.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)
  const assets = useSelector(state => state.exchangeAssets.value)

  return (
    <img
      src={theme === 'dark' ? assets?.find(asset => asset.logo_type === 'client_side_logo' && asset.theme === 'dark')?.image.url : assets?.find(asset => asset.logo_type === 'client_side_logo' && asset.theme === 'light')?.image.url}
      alt={exchangeName}
      style={{
        width: size,
        ...style
      }}
      {...rest}
    />
  )
}

export default WenbitLogo
