import { createSlice } from '@reduxjs/toolkit'

const viewStakingSubscriptionModalSlice = createSlice({
  name: 'viewStakingSubscriptionModalState',
  initialState: {
    open: false,
    data: null
  },
  reducers: {
    setViewStakingSubscriptionModalOpen: (state, action) => {
      state.open = action.payload
    },
    setViewStakingSubscriptionModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setViewStakingSubscriptionModalOpen, setViewStakingSubscriptionModalData } =
  viewStakingSubscriptionModalSlice.actions

export default viewStakingSubscriptionModalSlice.reducer
