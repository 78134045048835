import { API } from '../../../../utility/API'

//************************************//
export const _getOrders = (params, callback, callbackErr) => {
  API.get(`trading/market/orders`, { params })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getConversions = (params, callback, callbackErr) => {
  API.get('trading/market/conversions', { params })
    .then(function ({ data, headers }) {
      console.log(headers)
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getTrades = (params, callback, callbackErr) => {
  API.get(`trading/market/trades`, { params })
    .then(function ({ data, headers }) {
      callback({ data, total: headers['total'] })
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getWithdrawalHistory = (data, callback, callbackErr) => {
  API.get(`trading/account/withdraws?limit=${data.pageLimit}&page=${data.page}`)
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getDepositHistory = (data, callback, callbackErr) => {
  API.get(`trading/account/deposits?limit=${data.pageLimit}&page=${data.page}`)
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getWalletHistory = (params, callback, callbackErr) => {
  API.get(`trading/account/wallets`, { params })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getBalances = (data, callback, callbackErr) => {
  API.get(`trading/account/balances`, {
    params: data
  })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _updateBalancePreference = (params, callback, callbackErr) => {
  API.put(`authenticate/resource/users/balance_preference`, params)
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getBuyCryptoHistory = (params, callback, callbackErr) => {
  API.get(`trading/account/trade_gateway/history`, { params })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getStakingHistory = (params, callback, callbackErr) => {
  API.get(`/trading/account/staking_history`, { params })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getStakingDurations = (params, callback, callbackErr) => {
  API.get(`/trading/earn/durations`, { params })
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getEarnWallets = (params, callback, callbackErr) => {
  API.get(`/trading/account/earn/balances`, { params })
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getWalletView = (callback, callbackErr) => {
  API.get(`/trading/account/wallet_view`)
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getCoinView = (callback, callbackErr) => {
  API.get(`/trading/account/coin_view`)
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
