// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

const FormToProgressBar = () => {
  return (
    <div className={styles.dateDotsContainer}>
      {/* From Dot */}
      <div className={styles.fromDot}>
        <div />
      </div>

      {/* Progress Bar */}
      <div className={styles.dateProgressBar}>
        <div className={styles.progress} />
      </div>

      {/* To Dot */}
      <div className={styles.toDot} />
    </div>
  )
}

function FixedSummary({ fromDate, toDate, rewardsAmountLabel, rewardsPercentage }) {
  return (
    <div>
      {/* Estimate Reward */}
      <div className={styles.estimateContainer}>
        <span>Total Est. Rewards {rewardsPercentage}</span>
        <span>{rewardsAmountLabel}</span>
      </div>

      {/* From - To Date */}
      <div className={styles.datesContainer}>
        <FormToProgressBar />
        <div className={styles.dateRowsContainer}>
          <div className={styles.dateRow}>
            <span>First Date of Staking</span>
            <span>{fromDate || '--.--.--'}</span>
          </div>
          <div className={styles.dateRow}>
            <span>Interest End Date</span>
            <span>{toDate || '--.--.--'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FixedSummary
