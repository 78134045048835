import { API } from '../../../../utility/API'

//************************************//
export const _getPublicStakingProducts = (
  { page = 1, limit = 10, searchQuery = null, matchAsset = null, duration = null },
  callback,
  callbackErr
) => {
  API.get(`/trading/earn/staking_periods`, {
    params: {
      page,
      limit,
      search: { currency_id: searchQuery, currency_name: searchQuery },
      match_asset: matchAsset,
      duration
    }
  })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function (error) {
      callbackErr(error.response)
    })
}
//************************************//
export const _getStakingDurations = (callback, callbackErr) => {
  API.get(`/trading/earn/durations`)
    .then(function (response) {
      callback(response)
    })
    .catch(function (error) {
      callbackErr(error.response)
    })
}
