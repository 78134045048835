import React, { useState, useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// styles
import Styles from "./careerApply.module.css"

// assets
// import wenLogo from "../../../assets/icons/wen-logos/logo-small.png"

// icons
import { ReactComponent as TwitterIcon } from "../../../assets/icons/imported/twitter.svg"
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/imported/linkedin.svg"
import { ReactComponent as InstagramIcon } from "../../../assets/icons/imported/instagram.svg"
import { ReactComponent as ChainLinkIcon } from "../../../assets/icons/imported/chain-link.svg"

// components
import Input from "../../../components/Input/Input"
import Button from "../../../components/Button/Button"
import FormRow from "../../../components/FormRow/FormRow"
import ShimmerLine from "../../../components/shimmer-components/ShimmerLine"
import RequiredIndicator from "../../../components/RequiredIndicator/RequiredIndicator"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import CheckboxInput from "../../../components/CheckboxInput/CheckboxInput"
import FileInput from "../../../components/Input/FileInput/FileInput"
import { _addApplication, _getJob, _uploadFiles } from "../redux/actions"

// ** Hooks Imports
import { useNavigate, useParams } from "react-router-dom"

// ** Utils Imports
import { showErrorSnackbar, showSuccessSnackbar } from "../../snackbar/helpers"
import { formatDate } from "../../../utility/formatDate"
import { useSelector } from "react-redux"

// schema
const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string()
    .required("First name is required")
    .matches(/^[a-zA-Z0-9 ]+$/, "First Name cannot contain spacial characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .matches(/^[a-zA-Z0-9 ]+$/, "Last Name cannot contain spacial characters"),
  phoneNumber: Yup.string()
    .notOneOf(["+"], "Invalid phone number")
    .min(10, "Invalid phone number")
    .required("Phone number is required"),
  location: Yup.string()
    .required("Location is required")
    .matches(/^[a-zA-Z0-9 ]+$/, "Location cannot contain spacial characters"),
  currentCompany: Yup.string(),
  internshipAvailability: Yup.string().required("This field is required"),
  visaRequired: Yup.boolean().required("Please select an option"),
  contactMe: Yup.boolean(),
  CV: Yup.array().min(1, "Resume is required").required("Resume is required"),
  coverLetter: Yup.array()
})

function CareerApply() {
  // ** Route Params
  const { jobId } = useParams()

  // ** States
  const [job, setJob] = useState("")
  const [jobLoading, setJobLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)
  const assets = useSelector(state => state.exchangeAssets.value)

  const theme = useSelector((state) => state.theme.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit"
  })

  const navigate = useNavigate()

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  const onSubmit = async (formData) => {
    setLoading(true) // Start loading
    let coverLetterResponse = false
    let cvResponse = false

    // Upload CV
    try {
      cvResponse = await new Promise((resolve, reject) => {
        _uploadFiles(formData.CV[0], resolve, reject)
      })
    } catch (error) {
      setLoading(false)
      showErrorSnackbar({
        alertMessage: "Failed to upload files"
      })
    }

    // If a cover letter is added -> upload it as well
    if ("coverLetter" in formData) {
      try {
        // Upload Cover Letter
        coverLetterResponse = await new Promise((resolve, reject) => {
          _uploadFiles(formData.coverLetter[0], resolve, reject)
        })
      } catch (error) {
        setLoading(false)
        showErrorSnackbar({
          alertMessage: "Failed to upload files"
        })
      }
    }

    // Add Application
    _addApplication(
      {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        location: formData.location,
        company: formData.currentCompany,
        intenrship_availability: formData.internshipAvailability,
        resume: cvResponse.url,
        cover_letter: coverLetterResponse ? coverLetterResponse.url : null,
        // application_status_id: 1,
        job_id: jobId,
        visa_required: formData.visaRequired ? 1 : 0,
        contact_me: formData.contactMe ? 1 : 0
      },
      () => {
        setLoading(false)
        showSuccessSnackbar({
          alertMessage: "Application submitted successfully"
        })
        navigate("/careers")
      },
      () => {
        setLoading(false)
        showErrorSnackbar({
          alertMessage: "Failed to submit application, please try again"
        })
      }
    )
  }

  const fetchJob = () => {
    setJobLoading(true)
    _getJob(
      jobId,
      (data) => {
        setJobLoading(false)
        setJob(data.data.Job)
      },
      (err) => {
        setJobLoading(false)
        showErrorSnackbar({ alertMessage: "Failed to load page" })
      }
    )
  }

  useEffect(() => {
    fetchJob()
  }, [])

  return (
    <div className={Styles.pageContainer}>
      <section className={Styles.header}>
        <div className={Styles.leftSection}>
          <img src={assets?.find(asset => asset.logo_type === 'emblem_logo')?.image.url} />
          <div className={Styles.jobDetailsContainer}>
            <h1>
              {loading ? <ShimmerLine height={30} width={120} /> : job?.title}
            </h1>
            <p>
              {loading ? <ShimmerLine height={30} width={200} /> : job?.summary}
            </p>
            <span>{formatDate(job?.created_at)}</span>
          </div>
        </div>
        <div className={Styles.rightSection}>
          <Button text="Apply" width="120px" />
          <div className={Styles.iconsContainer}>
            {job?.instagram_link && (
              <a
                href={`${
                  job.instagram_link.startsWith("http://") ||
                  job.instagram_link.startsWith("https://")
                    ? job.instagram_link
                    : `https://${job.instagram_link}`
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            )}
            {job?.twitter_link && (
              <a
                href={`${
                  job.twitter_link.startsWith("http://") ||
                  job.twitter_link.startsWith("https://")
                    ? job.twitter_link
                    : `https://${job.twitter_link}`
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
            )}
            {job?.linkedin_link && (
              <a
                href={`${
                  job.linkedin_link.startsWith("http://") ||
                  job.linkedin_link.startsWith("https://")
                    ? job.linkedin_link
                    : `https://${job.linkedin_link}`
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
            )}
            {job?.custom_link && (
              <a
                href={`${
                  job.custom_link.startsWith("http://") ||
                  job.custom_link.startsWith("https://")
                    ? job.custom_link
                    : `https://${job.custom_link}`
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ChainLinkIcon width={22} />
              </a>
            )}
          </div>
        </div>
      </section>

      {job?.description && (
        <section
          style={{ margin: "100px 0" }}
          dangerouslySetInnerHTML={{ __html: job.description }}
        ></section>
      )}

      <section className={Styles.applyFormSection}>
        <div className={Styles.formHeader}>
          <h2>Apply for this Job</h2>
          <RequiredIndicator />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Input
              variant="secondary"
              required
              inputLabel="First Name"
              placeholder="Alex"
              error={errors.firstName}
              {...register("firstName")}
            />
            <Input
              variant="secondary"
              required
              inputLabel="Last Name"
              placeholder="Jones"
              error={errors.lastName}
              {...register("lastName")}
            />
          </FormRow>
          <FormRow>
            <Input
              variant="secondary"
              required
              inputLabel="Email"
              placeholder="example@mail.com"
              error={errors.email}
              {...register("email")}
            />
            <Input
              variant="secondary"
              required
              type="phoneNumber"
              inputLabel="Phone Number"
              error={errors.phoneNumber}
              register={{ ...register("phoneNumber") }}
            />
          </FormRow>
          <FormRow>
            <Input
              variant="secondary"
              required
              inputLabel="Location"
              placeholder="Location"
              error={errors.location}
              {...register("location")}
            />
            <Input
              variant="secondary"
              inputLabel="Current Company"
              placeholder="Current Company"
              error={errors.currentCompany}
              {...register("currentCompany")}
            />
          </FormRow>

          <div className={Styles.divider}></div>

          <h3>Internship Availability</h3>

          <Input
            required
            type="textarea"
            variant="secondary"
            style={{ minHeight: 200 }}
            placeholder="Speak your mind"
            error={errors.internshipAvailability}
            containerStyle={{ margin: "40px 0" }}
            register={{ ...register("internshipAvailability") }}
            inputLabel="Could you start the internship as soon as possible?"
          />

          <FormRow>
            <FileInput
              error={errors.CV}
              label="Resume/CV"
              maxSize={5000000}
              onFileChange={(files) => {
                setValue("CV", files)
                trigger("CV")
              }}
              accept={[".pdf", ".docx"]}
              mainContainerStyle={{ margin: "0 auto" }}
              required
            />
            <FileInput
              error={errors.coverLetter}
              label="Cover Letter"
              onFileChange={(files) => {
                setValue("coverLetter", files)
                trigger("coverLetter")
              }}
              accept={[".pdf", ".docx"]}
              mainContainerStyle={{ margin: "0 auto" }}
            />
          </FormRow>

          <div className={Styles.divider}></div>

          <h3>Visa Requirement</h3>

          <Input
            required
            inputLabel="Do you require Visa sponsorship to work in your location?"
            type="radioGroup"
            radios={[
              { label: "No", value: "false" },
              { label: "Yes, sponsorship is required", value: "true" }
            ]}
            error={errors.visaRequired}
            register={{ ...register("visaRequired") }}
          />

          <div className={Styles.divider}></div>

          <CheckboxInput
            checkboxId="contact-me"
            label={`Yes, ${exchangeName} can contact me about future job opportunities for up to 1 years`}
            register={{ ...register("contactMe") }}
          />
          <div
            style={{ magin: "0 auto !important" }}
            key={`reCaptcha-${theme}`}
            className={Styles.reCaptchaContainer}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={recaptchaOnChange}
              theme={theme}
            />
          </div>

          {jobLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 40
              }}
            >
              <ShimmerLine height="38.5px" width="140px" />
            </div>
          ) : (
            <Button
              fitContent
              type="submit"
              disabled={!recaptchaConfirmed || loading}
              text={loading ? "Uploading files" : "Submit Application"}
              style={{ margin: "40px auto 0 auto" }}
              isLoading={loading}
            />
          )}
        </form>
      </section>
    </div>
  )
}

export default CareerApply
