import { createSlice } from "@reduxjs/toolkit"

const exchangeAssetsSlice = createSlice({
  name: "exchangeAssets",
  initialState: {
    value: []
  },
  reducers: {
    setExchangeAssets: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setExchangeAssets } = exchangeAssetsSlice.actions

export default exchangeAssetsSlice.reducer
