import React, { useState, useEffect } from "react"

// styles
import Styles from "./careers.module.css"

// components
import Button from "../../../components/Button/Button"
import PageHeader from "../../../components/PageHeader/PageHeader"
import JobsGrid from "../../../components/careers-components/JobsGrid/JobsGrid"
import { _getJobs } from "../redux/actions"
import { useSelector } from "react-redux"

function Careers() {
  // ** States
  const [jobs, setJobs] = useState([])
  const [jobsLoading, setJobsLoading] = useState(false)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Careers`

  // ** Handlers
  const fetchJobs = () => {
    setJobsLoading(true)
    _getJobs(
      (data) => {
        setJobsLoading(false)
        setJobs(data.data.data)
      },
      (err) => {
        setJobsLoading(false)
      }
    )
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  return (
    <div className={Styles.pageContainer}>
      <PageHeader
        sectionTitle="Company"
        title="Bit by bit, making a bigger bit"
        description="Slowly but surely, we're building something epic. And you're thinking, 'Wow, wouldn't it be sick to work at WenBit?' You're damn right it would be."
      />

      <Button
        fitContent
        text="Find Open Jobs"
        className={Styles.findJobsButton}
        type="link"
        linkTo="/careers/browse"
      />

      {/* company stats */}
      <div className={Styles.statsContainer}>
        <div>
          <span>150+</span>
          <span>Countries / Regions</span>
        </div>
        <div>
          <span>2,500+</span>
          <span>Employees</span>
        </div>
        <div>
          <span>45M+</span>
          <span>Customers</span>
        </div>
      </div>

      <PageHeader
        size="small"
        sectionTitle="Join our team"
        title={
          <>
            We always seek fresh faces
            <br /> and top talent to join our crew
          </>
        }
        description={
          <>
            We don't care about your fancy degrees or resume fluff,
            <br />
            we want people with attitude, drive, and out-of-the-box thinking.{" "}
            <br />
            At WenBit, we don't just grind hard - we party hard too.
          </>
        }
      />

      <JobsGrid jobs={jobs} jobsLoading={jobsLoading} />
    </div>
  )
}

export default Careers
