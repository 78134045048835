// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

function SidebarContentWrapper({ title, secondaryTitle, children }) {
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabHeader}>
        <div className={styles.titlesContainer}>
          {title && <h5>{title}</h5>}
          {secondaryTitle && <span>{secondaryTitle}</span>}
        </div>
      </div>

      {children}
    </div>
  )
}

export default SidebarContentWrapper
