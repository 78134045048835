// ** React Imports
import React, { useState } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { _updateBalancePreference } from '../../../../redux/actions'
import { setUser } from '../../../../../user/redux/slices/userDataSlice'

// ** Styles Imports
import styles from './styles.module.css'

// ** Utils Imports
import { showErrorSnackbar } from '../../../../../snackbar/helpers'
import { handleDeposit } from '../../../../../../utility/deposit-withdraw-helpers/handleDeposit'
import { handleWithdraw } from '../../../../../../utility/deposit-withdraw-helpers/handleWithdraw'

// ** Icons Imports
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { ReactComponent as WalletIcon } from '../../../../../../assets/icons/imported/wallet.svg'

// ** Component Imports
import { Box, useMediaQuery } from '@mui/material'
import Button from '../../../../../../components/Button/Button'
import AmountDisplay from '../../../../../../components/AmountDisplay'
import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen
} from '../../../../../modals/select-currency/redux/selectCurrencyModalSlice'

function WalletsOverviewTabHeader() {
  // ** Media Queries
  const isMobileView = useMediaQuery('(max-width:1020px)')

  // ** Hooks
  const dispatch = useDispatch()

  // ** Show/Hide Price
  const totalBalance = useSelector(state => state.fundsData.data)
  const hideBalance = useSelector(state => state.userData.value.hide_balance)

  // ** States
  const [hideBalanceLoading, setHideBalanceLoading] = useState(false)

  // ** Handlers
  const handleEarn = () => {}

  const handleToggleHidePrice = () => {
    setHideBalanceLoading(true)
    _updateBalancePreference(
      { preference: !hideBalance },
      data => {
        dispatch(setUser(data.data))
        setHideBalanceLoading(false)
      },
      () => {
        setHideBalanceLoading(false)
        showErrorSnackbar({ alertMessage: 'Something went wrong' })
      }
    )
  }
  return (
    <div className={`${styles.card} ${styles.tabHeader}`}>
      {/* First Row */}
      <Box
        className={styles.headerFirstRowContainer}
        sx={isMobileView && { flexDirection: 'column', '& > *': { width: '100%' } }}
      >
        <div className={styles.titlesContainer}>
          <h3 className={styles.title}>Overview</h3>
          <span className={styles.subTitle}>All your crypto bits, stored in one place. Cool huh?</span>
        </div>

        <Box className={styles.buttonsContainer} sx={{ '& > *': isMobileView && { width: '33% !important' } }}>
          <Button
            text='Deposit'
            onClick={() => {
              dispatch(
                setSelectCurrencyModalData({
                  title: 'Select coin to deposit',
                  showTabs: false,
                  type: 'coin',
                  searchPlaceholder: 'Search coin name',
                  currenciesToHide: [],
                  disableBackdropClick: true,
                  callback: coin => {
                    handleDeposit(coin.id)
                  }
                })
              )
              dispatch(setSelectCurrencyModalOpen(true))
            }}
          />
          <Button text='Withdraw' onClick={handleWithdraw} variant='dark' />
          <Button
            type='link'
            text='Earn'
            linkTo='/staking'
            onClick={handleEarn}
            style={{ backgroundColor: 'var(--text-color)', color: 'var(--text-color-inverted)' }}
          />
        </Box>
      </Box>

      {/* Second Row */}
      <div className={styles.headerSecondRowContainer}>
        <div className={styles.accountBalanceContainer}>
          {/* Account Balance First Row */}
          <div className={styles.accountBalanceFirstRow}>
            <div className={styles.accountBalanceTitle}>
              <WalletIcon width={20} />
              <span>Account Balance</span>
            </div>

            <button disabled={hideBalanceLoading} onClick={handleToggleHidePrice} className={styles.hidePriceButton}>
              {`${hideBalance ? 'Show' : 'Hide'} Price`} {hideBalance ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>

          {/* Account Balance Second Row */}
          <div>
            <span className={styles.accountBalanceUSDT} style={{ marginLeft: 'auto' }}>
              {hideBalance ? '********' : <AmountDisplay coinId='usdt' amount={totalBalance?.total_funds} />}{' '}
              <span style={{ fontSize: 15 }}>USDT</span>
            </span>
            {isMobileView && (
              <span className={styles.accountBalanceBTC}>
                {' '}
                &#8776;{' '}
                {hideBalance ? '********' : <AmountDisplay coinId='btc' amount={totalBalance?.total_balance_btc} />} BTC
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletsOverviewTabHeader
