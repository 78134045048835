import React from "react"

// styles
import Styles from "./KYCVerificationTab.module.css"

// icons
import { ReactComponent as CloseIcon } from "../../../../assets/icons/imported/close.svg"
import { ReactComponent as CheckMarkIcon } from "../../../../assets/icons/imported/checkmark.svg"

// redux
import { useSelector } from "react-redux"

// components
import KYCStatus from "./KYCStatus"

// utils
import { formatDateTime } from "../../../../utility/formatDateTime"

function KYCVerificationTab() {
  const userData = useSelector((state) => state.userData.value)
  const theme = useSelector((state) => state.theme.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: KYC`

  const kyc_status = userData?.labels?.find(
    (label) => label.key === "document"
  )?.value
  // console.log(kyc_status)
  return (
    <div className={Styles.tabContainer}>
      <h5>KYC Verification</h5>

      {/* tab header */}
      <div className={Styles.tabHeader}>
        <div>
          <span>Full Name</span>
          <span>
            {userData.first_name
              ? `${userData.first_name} ${userData.last_name}`
              : "Not Set"}{" "}
          </span>
        </div>
        <div>
          <span>UID</span>
          <span>UID: {userData.uid}</span>
        </div>
      </div>

      {/* KYC Status container */}
      <div
        className={Styles.KYCStatusContainer}
        style={{
          borderTop: theme === "light" && "1px #ccced9 solid",
          borderBottom: theme === "light" && "1px #ccced9 solid"
        }}
      >
        <KYCStatus status={kyc_status} />
      </div>

      {/* KYC TABLE */}
      <div
        className={Styles.kycTableContainer}
        style={{
          backgroundColor: theme === "light" && "white"
        }}
      >
        {/* 1st column */}
        <div className={Styles.kycTableColumn}>
          <span></span>
          <span>
            <h3>Fiat & Crypto Deposit</h3>
            <span>
              Deposit your hard-earned cash or your crypto stash - either way,
              we'll take it.
            </span>
          </span>
          <span>
            <h3>Fiat & Crypto Withdrawal</h3>
            <span>
              Withdraw your funds whenever you want, wherever you want. We won't
              hold you back.
            </span>
          </span>
          <span>
            <h3>Spot Trading</h3>
            <span>
              Get ready to ride the market wave! Buy, sell, and trade your
              favourite cryptos in real time without delays or hassle.
            </span>
          </span>
          <span>
            <h3>Conversion</h3>
            <span>
              Not sure which crypto to choose? No worries! Our conversion tool
              makes it easy to swap one currency for another.
            </span>
          </span>
        </div>

        {/* 2nd column */}
        <div className={Styles.kycTableColumn}>
          <span className={Styles.kycTableColumnHeader}>NO KYC</span>
          <span>
            <CloseIcon color="var(--primary-color)" />
          </span>
          <span>
            <CloseIcon color="var(--primary-color)" />
          </span>
          <span>
            <CloseIcon color="var(--primary-color)" />
          </span>
          <span>
            <CloseIcon color="var(--primary-color)" />
          </span>
        </div>

        {/* 3rd column */}
        <div className={Styles.kycTableColumn}>
          <span className={Styles.kycTableColumnHeader}>KYC VERIFIED</span>
          <span>
            <CheckMarkIcon color="var(--primary-color)" />
          </span>
          <span>
            <CheckMarkIcon color="var(--primary-color)" />
          </span>
          <span>
            <CheckMarkIcon color="var(--primary-color)" />
          </span>
          <span>
            <CheckMarkIcon color="var(--primary-color)" />
          </span>
        </div>
      </div>

      <div className={Styles.tabFooter}>
        This account was created on {formatDateTime(userData?.created_at)}
      </div>
    </div>
  )
}

export default KYCVerificationTab
