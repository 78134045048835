// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Icons Imports
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

// ** Styles Imports
import styles from './styles.module.css'
import { Link, useLocation } from 'react-router-dom'

function SidebarDropdown({
  icon,
  label,
  dropdownHeight = '',
  dropdownButtons = [],
  dropdownStyle,
  containerStyle,
  ...rest
}) {
  /**
   * For the dropdownButtons prop, it's an array of object
   * with the following properties:
   * {
   *    icon,
   *    label,
   *    href,
   *    onClick (if href is not defined)
   * }
   */

  // ** Hooks
  const location = useLocation(null)

  // ** Theme
  const theme = useSelector(state => state.theme.value)

  // ** States
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isButtonSelected, setIsButtonSelected] = useState(false)

  // ** Handlers
  const handleOpenToggle = () => setIsDropdownOpen(prev => !prev)

  useEffect(() => {
    // Check if any dropdown button is selected
    const isSelected = dropdownButtons.some(button => location.pathname.includes(button.href))
    setIsButtonSelected(isSelected)
    setIsDropdownOpen(isSelected)
  }, [location.pathname, dropdownButtons])

  return (
    <>
      <button
        onClick={handleOpenToggle}
        className={`${styles.button} ${isButtonSelected && styles.selectedButton}`}
        style={containerStyle}
        {...rest}
      >
        {icon}
        <span style={{ marginRight: 'auto' }}>{label}</span>

        {isDropdownOpen ? <BiChevronUp size={18.5} /> : <BiChevronDown size={18.5} />}
      </button>
      <div
        className={`${styles.dropdownButtonsContainer} ${isDropdownOpen && styles.dropdownOpen}`}
        style={{ height: isDropdownOpen && dropdownHeight, ...dropdownStyle }}
      >
        {dropdownButtons.map((button, index) => (
          <Link
            id={index}
            to={button.href}
            className={`${styles.dropdownButton} ${
              location.pathname.includes(button.href) && styles.dropdownButtonSelected
            }`}
            onClick={button?.onClick}
          >
            {button.icon}
            <span>{button.label}</span>
          </Link>
        ))}
      </div>
    </>
  )
}

export default SidebarDropdown
