// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Utils Imports
import { debounce } from '@mui/material'

// ** Component Imports
import Input from '../../../../components/Input/Input'
import Search from '../../../../components/Search/Search'
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'
import { _getStakingDurations } from '../../redux/actions'

function StakingTableFilters({ filteringParams, setFilteringParams }) {
  // ** States
  const [durationOptions, setDurationOptions] = useState([])

  // ** Handlers
  const fetchDuration = () => {
    _getStakingDurations(
      res =>
        setDurationOptions([
          { label: 'All Durations', value: null },
          ...res.data.map(duration => {
            return { label: duration === 0 ? 'Flexible' : duration, value: duration }
          })
        ]),
      err => console.error('Error while fetching staking durations')
    )
  }
  const handleSearch = e => {
    setFilteringParams(prevParams => ({ ...prevParams, searchQuery: e.target.value }))
  }

  const handleMatchAssetChange = e => {
    setFilteringParams(prevParams => {
      return {
        ...prevParams,
        match_asset: e.target.checked
      }
    })
  }

  const handleDurationChange = duration => {
    // setFilteringParams(prevParams => {
    //   return {
    //     ...prevParams,
    //     duration
    //   }
    // })
  }

  useEffect(() => {
    fetchDuration()
  }, [])

  return (
    <div className={styles.container}>
      <Search placeholder='Search coin name' onChange={debounce(handleSearch, 500)} />

      <Input
        type='select'
        selectDefaultValue='All'
        selectOptions={durationOptions}
        containerStyle={{ marginBottom: '-10px', height: 89 }}
        variant='secondary'
        onChange={(e, v) => handleDurationChange(v)}
      />

      <CheckboxInput label='Match my assets' onChange={handleMatchAssetChange} />
    </div>
  )
}

export default StakingTableFilters
