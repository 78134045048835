// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Component Imports
import CoinView from './components/CoinView'
import WalletView from './components/WalletView'
import Button from '../../../../../../components/Button/Button'
import SidebarContentWrapper from '../../../../../../components/sidebar-layout-components/SidebarContentWrapper'

function WalletsOverviewTabContent() {
  // ** States
  const [selectedTab, setSelectedTab] = useState('coin') // coin - wallet

  useEffect(() => {
    // If no tab was selected, set coin as the default selected tab
    if (selectedTab !== 'coin' || selectedTab !== 'wallet') {
      setSelectedTab('coin')
    }
  }, [])

  return (
    <SidebarContentWrapper>
      <div className={styles.tabsContainer}>
        <Button
          text='Coin View'
          type='tab'
          isTabSelected={selectedTab === 'coin'}
          onClick={() => setSelectedTab('coin')}
        />
        <Button
          text='Wallet View'
          type='tab'
          isTabSelected={selectedTab === 'wallet'}
          onClick={() => setSelectedTab('wallet')}
        />
      </div>

      {selectedTab === 'coin' && <CoinView />}
      {selectedTab === 'wallet' && <WalletView />}
    </SidebarContentWrapper>
  )
}

export default WalletsOverviewTabContent
