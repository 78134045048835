import React, { useEffect, useState, useCallback } from "react"

// redux
import { useDispatch, useSelector } from "react-redux"

// components
import InsightCard from "./InsightCard"
import Button from "../../../../components/Button/Button"
import { formatNumber } from "../../../helpers/formatNumber"
import CoinTag from "../../../../components/CoinTag/CoinTag"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"
import ShimmerCircle from "../../../../components/shimmer-components/ShimmerCircle"
import MarketsListMobile from "../MarketsListMobile/MarketsListMobile"
import WenbitModal from "../../../../components/Modal/Modal"
import Tooltip from "../../../../components/Tooltip/Tooltip"

// styles
import Styles from "./marketInsight.module.css"

// icons
import { FiInfo } from "react-icons/fi"
import { FaRegClock } from "react-icons/fa"
import { CgArrowsExchangeV } from "react-icons/cg"
import { TbActivityHeartbeat } from "react-icons/tb"
import { ReactComponent as UpArrowIcon } from "../../../../assets/icons/imported/up-arrow.svg"
import { ReactComponent as HighLowIcon } from "../../../../assets/icons/imported/high-low-price.svg"
import { ReactComponent as BuyArrowIcon } from "../../../../assets/icons/imported/buy-arrow.svg"
import { ReactComponent as SellArrowIcon } from "../../../../assets/icons/imported/sell-arrow.svg"
import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen,
} from "../../../modals/select-currency/redux/selectCurrencyModalSlice"
import { clearTrades, resetLastTrade } from "../../redux/slices/tradesSlice"
import { clearOrderBook } from "../../redux/slices/orderBookSlice"
import { setSelectedMarket } from "../../redux/slices/selectedMarketSlice"
import { setPreviousMarket } from "../../redux/slices/previousMarketSlice"
import { setPreferredCurrency } from "../../redux/slices/preferredCurrencySlice"

const convertCurrency = (toCurrency, fromCurrency, rate, amount) => {
  const formattedToCurrency = toCurrency?.toLowerCase()
  const formattedFromCurrency = fromCurrency?.toLowerCase()

  let convertedAmount
  if (formattedToCurrency === formattedFromCurrency) {
    convertedAmount = amount
  } else {
    convertedAmount = amount / rate
  }

  return convertedAmount
}

const convertCryptoCurrency = (toCurrency, fromCurrency, rate, amount) => {
  const formattedToCurrency = toCurrency?.toLowerCase()
  const formattedFromCurrency = fromCurrency?.toLowerCase()

  let convertedAmount
  if (formattedToCurrency === formattedFromCurrency) {
    convertedAmount = amount
  } else {
    convertedAmount = amount * rate
  }

  return convertedAmount
}

function MarketInsight() {
  // ** Hooks
  const dispatch = useDispatch()
  // ** States
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const marketTickers = useSelector((state) => state.marketTickers.value)
  const tickers = marketTickers[selectedMarket.id]
  const marketsList = useSelector((state) => state.marketList.value)
  const lastTrade = useSelector((state) => state.trades.last)
  const selectedMarketDetails = marketTickers[selectedMarket.id]
  const currencies = useSelector((state) => state.currencies.value)
  const globalConversions = useSelector((state) => state.globalConversions.value)
  const preferredCurrency = useSelector((state) => state.preferredCurrency.value)
  const [selectPairModalOpen, setSelectPairModalOpen] = useState(false)
  const [hideMarketsList, setHideMarketsList] = useState(window.innerWidth > 990)
  const [convertedPrice, setConvertedPrice] = useState(null)
  const [targetedMarket, setTargetedMarket] = useState(null)
  const [cryptoCurrencies, setCryptoCurrencies] = useState([])

  const handleResize = useCallback(() => {
    setHideMarketsList(window.innerWidth > 990)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    setTargetedMarket(marketsList?.find((el) => el.id === selectedMarket.id))
    dispatch(resetLastTrade())
  }, [selectedMarket, marketsList, dispatch])

  useEffect(() => {
    if (currencies.length > 0) {
      const crypto = currencies.filter((currency) => currency.type === "coin").map((currency) => currency.id)
      setCryptoCurrencies(crypto)
    }
  }, [currencies])

  useEffect(() => {
    if (lastTrade?.price && selectedMarket && cryptoCurrencies.length && globalConversions?.length) {
      const quoteCode = selectedMarket?.name?.split("/")[1]
      const isCryptoCurrency = cryptoCurrencies.includes(quoteCode?.toLowerCase())
      let priceInPreferredCurrency

      if (isCryptoCurrency && quoteCode !== "USDT") {
        const cryptoToUsdRate = globalConversions?.find(
          (rate) => rate.currency_from === quoteCode && rate.currency_to === "USD"
        )?.rate

        if (cryptoToUsdRate) {
          const priceInUsd = convertCryptoCurrency(preferredCurrency.id, "USD", cryptoToUsdRate, lastTrade.price)
          const usdToPreferredRate = globalConversions?.find(
            (rate) => rate.currency_from === "USD" && rate.currency_to === preferredCurrency.id
          )?.rate

          if (usdToPreferredRate) {
            priceInPreferredCurrency = convertCurrency(preferredCurrency.id, "USD", usdToPreferredRate, priceInUsd)
          } else {
            const preferredRateToUsd = globalConversions?.find(
              (rate) => rate.currency_to === "USD" && rate.currency_from === preferredCurrency.id
            )?.rate
            priceInPreferredCurrency = convertCurrency("USD", preferredCurrency.id, preferredRateToUsd, priceInUsd)
          }
        }
      // } else if (quoteCode === "USDT" && preferredCurrency.id === "USD") {
      //   const rate = globalConversions?.find(
      //     (rate) => rate.currency_from === "USDT" && rate.currency_to === preferredCurrency.id
      //   )?.rate
      //   priceInPreferredCurrency = (lastTrade.price * rate)

      } else {
        const usdToPreferredRate = globalConversions?.find(
          (rate) => rate.currency_from === "USD" && rate.currency_to === preferredCurrency.id
        )?.rate

        if (usdToPreferredRate) {
          priceInPreferredCurrency = convertCurrency(preferredCurrency.id, "USD", usdToPreferredRate, lastTrade.price)
        } else {
          const preferredRateToUsd = globalConversions?.find(
            (rate) => rate.currency_to === "USD" && rate.currency_from === preferredCurrency.id
          )?.rate
          priceInPreferredCurrency = convertCurrency("USD", preferredCurrency.id, preferredRateToUsd, lastTrade.price)
        }
      }
      setConvertedPrice(priceInPreferredCurrency)
    }
  }, [lastTrade, selectedMarket, preferredCurrency.id, globalConversions, cryptoCurrencies])

  const formattedPrice = (price) => {
    return `${preferredCurrency.symbol}${formatNumber(price)}`
  }

  return (
    <div className={Styles.card}>
      {/* left container */}
      <div className={Styles.leftContainer}>
        <div>
          {targetedMarket ? (
            <img src={targetedMarket?.icon_url} />
          ) : (
            <ShimmerCircle size="50px" />
          )}

          {targetedMarket ? (
            <span>{`${targetedMarket?.coin_name.toUpperCase()}`}</span>
          ) : (
            <ShimmerLine height="18px" width="50px" />
          )}

          {targetedMarket && (
            <CoinTag
              className={Styles.marketIdTag}
              tag={`${targetedMarket?.name?.split("/")[0]}/${targetedMarket?.name?.split("/")[1]}`}
              size={12}
            />
          )}
          {!hideMarketsList && (
            <Button
              className={Styles.selectPairButton}
              text="Select Pair"
              icon={<UpArrowIcon style={{ transform: "rotateX(180deg)" }} />}
              iconPosition="right"
              variant="link"
              onClick={() => setSelectPairModalOpen(true)}
            />
          )}
        </div>

        <div>
          <InsightCard
            titleIcon={<TbActivityHeartbeat size={13.5} color="var(--primary-color)" />}
            title="24 changes"
            price={(tickers?.last * (parseFloat(tickers?.price_change_percent) / 100)).toFixed(5)}
            percentageChange={parseFloat(tickers?.price_change_percent)}
            tooltipText="Check out the price value change over the last 24 hours"
          />
          <InsightCard
            titleIcon={<FaRegClock size={10.5} color="var(--primary-color)" />}
            title={`24 volume (${selectedMarket?.name?.split("/")[0]})`}
            price={parseFloat(tickers?.amount).toFixed(4)}
            percentageChange={-12}
            tooltipText="See how much crypto was sold"
          />
          <InsightCard
            titleIcon={<FaRegClock size={10.5} color="var(--primary-color)" />}
            title={`24 volume (${selectedMarket?.name?.split("/")[1]})`}
            price={parseFloat(tickers?.volume).toFixed(4)}
            percentageChange={2.8}
            tooltipText="Find out how much crypto was sold"
          />
        </div>
      </div>

      {/* right container */}
      <div className={Styles.rightContainer}>
        {/* price header */}
        <div>
          <div>
            {lastTrade?.price ? (
              <div>
                <span
                  style={{
                    color: lastTrade.taker_type === "buy" ? "var(--negative-color)" : "var(--positive-color)",
                    fontSize: "28px",
                  }}
                >
                  {formatNumber(lastTrade?.price)}
                </span>
                <span
                  style={{
                    color: "white",
                    fontSize: "15px",
                    fontWeight: "250",
                  }}
                >
                  {convertedPrice ? formattedPrice(convertedPrice) : "Loading..."}
                </span>
              </div>
            ) : (
              <ShimmerLine height="25px" width="100px" />
            )}

            <span
              style={{
                color: selectedMarketDetails?.price_change_percent[0] === "+"
                  ? "var(--positive-color)"
                  : "var(--negative-color)",
              }}
            >
              {selectedMarketDetails?.price_change_percent ?? "Loading..."}
              {selectedMarketDetails?.price_change_percent[0] === "+" ? (
                <BuyArrowIcon width={12} />
              ) : (
                <SellArrowIcon width={12} />
              )}
            </span>
          </div>
          <div>
            {targetedMarket?.coin_name.charAt(0).toUpperCase() + targetedMarket?.coin_name.slice(1)} Price (USDT)
            <Tooltip text="Get the latest update on the current crypto price" />
          </div>
        </div>

        {/* time range selector */}
        <div>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <HighLowIcon width={11} style={{ marginRight: 5 }} />
            High/Low Price
            <Tooltip text="Discover the lowest and highest crypto prices in the last 24 hours" />
          </span>

          <div
            style={{
              backgroundColor: "black",
              borderRadius: 3.5,
              width: "fit-content",
            }}
          ></div>
        </div>

        {/* bar container */}
        <div>
          <div
            style={{
              width: `${((lastTrade?.price - selectedMarketDetails?.low) / (selectedMarketDetails?.high - selectedMarketDetails?.low)) * 100}%`,
            }}
          ></div>
        </div>

        {/* price bar info */}
        <div>
          <span>
            Low : {formatNumber(selectedMarketDetails?.low) ?? "Loading..."}
          </span>
          <span>
            High : {formatNumber(selectedMarketDetails?.high) ?? "Loading..."}
          </span>
        </div>
      </div>

      {/* MODALS */}
      <WenbitModal
        open={selectPairModalOpen}
        title="PAIRS"
        render={() => <MarketsListMobile setCloseModal={setSelectPairModalOpen} />}
        onClose={() => setSelectPairModalOpen(false)}
      />
    </div>
  )
}

export default MarketInsight
