import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from "../../../../utility/API"


// Create the slice
export const exchangeInfoSlice = createSlice({
  name: 'exchangeInfo',
  initialState: {
    value: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    setExchangeInfo: (state, action) => {
      state.value = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setExchangeInfo } = exchangeInfoSlice.actions

export default exchangeInfoSlice.reducer
