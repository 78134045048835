// ** Helpers Imports
import { showStakingSubscribeModal } from '../../modals/staking/staking-subscribe-modal/helpers'

/**
 * Function that handles the subsription action for a coin
 * @param {string} stakingProduct The product that the user want to subscribe to
 */
export const handleStakingSubscribe = stakingProduct => {
  showStakingSubscribeModal(stakingProduct)
}
