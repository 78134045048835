import React, { useState, useEffect } from "react"

// styles
import Styles from "./beneficiariesTab.module.css"

// actions
import { _getBeneficiaries } from "../../redux/actions"

// redux
import { useDispatch, useSelector } from "react-redux"
import { setBeneficiary } from "../../../../redux/slices/beneficiarySlice"
import { setBeneficiaries } from "../../../../redux/slices/beneficiariesSlice"
import { setCreateBeneficiaryModalOpen } from "../../../modals/beneficiaries/redux/slices/createBeneficiaryModalSlice"

// components
import Search from "../../../../components/Search/Search"
import Button from "../../../../components/Button/Button"
import TableButton from "../../../../components/TableButton/TableButton"
import CoinDisplay from "../../../../components/CoinDisplay/CoinDisplay"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"
import Autocomplete from "../../../../components/Autocomplete/Autocomplete"
import MobileRowDatagrid from "../../../../components/MUIDatagrid/MobileRowDatagrid"

// utils
import { capitalize } from "@mui/material"
import { formatBeneficiaryCellText } from "./helpers"
import isLandscape from "../../../../utility/isLandscape"
import { showViewBeneficiaryModal } from "../../../modals/beneficiaries/view/helpers"
import { showActionsBeneficiaryModal } from "../../../modals/beneficiaries/actions/helpers"
import { setViewBeneficiaryModalOpen } from "../../../modals/beneficiaries/redux/slices/viewBeneficiaryModalSlice"
import { setDeleteBeneficiaryModalOpen } from "../../../modals/beneficiaries/redux/slices/deleteBeneficiaryModalSlice"
import { setActivateBeneficiaryModalOpen } from "../../../modals/beneficiaries/redux/slices/activateBeneficiaryModalSlice"
import { setActionsBeneficiaryModalOpen } from "../../../modals/beneficiaries/redux/slices/actionsBeneficiaryModalSlice"

function BeneficiariesTab() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const beneficiaries = useSelector((state) => state.beneficiaries.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Beneficiaries`

  const limit = 7
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const [tableLoading, setTableLoading] = useState(false)
  const currencies = useSelector((state) => state.currencies.value)

  // ** Handlers
  const handleMobileEditPress = (beneficiary) => {
    showActionsBeneficiaryModal(beneficiary)
  }

  // -----TABLE FILTERING STATES
  // ---------------------------

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 767) // Old: 754px

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 767) // Old: 754px
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const fetchBeneficiaries = () => {
    setTableLoading(true)
    _getBeneficiaries(
      {
        page,
        limit
      },
      (data) => {
        setTotalRows(data.total)
        dispatch(setBeneficiaries(data.data))
        setTableLoading(false)
      },
      (err) => {
        setTableLoading(false)
      }
    )
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    fetchBeneficiaries()
  }, [page])

  const beneficiariesColumns = isMobileView
    ? [
        {
          minWidth: 180,
          flex: 1,
          field: "",
          renderCell: (data) => {
            const coin = currencies.find((el) => el.id === data.row.currency)
            return (
              <MobileRowDatagrid
                showOptions
                options={[
                  {
                    label: (
                      <span
                        style={{
                          color:
                            data.row.state !== "Pending"
                              ? "#bfbfbf"
                              : "var(--success-color)"
                        }}
                      >
                        {data.row.state !== "Pending"
                          ? "Activated"
                          : "Activate"}
                      </span>
                    ),
                    onClick: () => {
                      dispatch(setActivateBeneficiaryModalOpen(true))
                      dispatch(setBeneficiary({ id: data.row.id }))
                    },
                    menuItemProps: { disabled: data.row.state !== "Pending" }
                  },
                  {
                    label: "View",
                    onClick: () => showViewBeneficiaryModal(data.row)
                  },
                  {
                    label: (
                      <span style={{ color: "var(--danger-color)" }}>
                        Delete
                      </span>
                    ),
                    onClick: () => {
                      dispatch(setDeleteBeneficiaryModalOpen(true))
                      dispatch(setBeneficiary({ id: data.row.id }))
                    }
                  }
                ]}
                renderRowHeader={() => (
                  <CoinDisplay
                    coinImgURL={coin?.icon_url}
                    coinName={coin?.name}
                    coinTag={coin?.id}
                  />
                )}
                rows={[
                  {
                    title: "Name",
                    value: data.row.name
                  },
                  {
                    title: "Status",
                    value: (
                      <span
                        style={{
                          color:
                            data.row.state === "Active"
                              ? "var(--success-color)"
                              : (data.row.state === "In Progress" ||
                                  data.row.state === "frb_processing") &&
                                "var(--warning-color)"
                        }}
                      >
                        {capitalize(data.row.state)}
                      </span>
                    )
                  },
                  {
                    title: "Actions",
                    value: (
                      <Button
                        variant="link"
                        text="Edit"
                        onClick={() => handleMobileEditPress(data.row)}
                      />
                    )
                  }
                ]}
              />
            )
          }
        }
      ]
    : [
        {
          flex: 1,
          field: "currency",
          headerName: "Currency",
          minWidth: 190,
          maxWidth: 205,
          renderCell: (data) => {
            const coin = currencies?.find((el) => data.row.currency === el.id)
            return (
              <CoinDisplay
                coinName={coin?.name}
                coinTag={coin?.id?.toUpperCase()}
                coinImgURL={coin?.icon_url}
              />
            )
          }
        },
        {
          flex: 1,
          field: "name",
          headerName: "Name",
          minWidth: 60,
          maxWidth: 95,
          renderCell: (data) => (
            <span style={{ color: "var(--gray-color)" }}>{data.row.name}</span>
          )
        },
        {
          flex: 1,
          field: "description",
          headerName: "Description",
          minWidth: 130,
          renderCell: (data) => (
            <span>{formatBeneficiaryCellText(data.row.description)}</span>
          )
        },
        {
          flex: 1,
          field: "state",
          headerName: "Status",
          minWidth: 70,
          maxWidth: 100,
          renderCell: (data) => (
            <span
              style={{
                color:
                  data.row.state === "Active"
                    ? "var(--success-color)"
                    : data.row.state === "Pending"
                    ? "var(--gray-color)"
                    : data.row.state === "In Progress" && "var(--warning-color)"
              }}
            >
              {capitalize(data.row.state)}
            </span>
          )
        },
        {
          flex: 1,
          field: "id",
          headerName: "Action",
          minWidth: 175,
          maxWidth: 185,
          renderCell: (data) => (
            <div className={Styles.actionButtonsContainer}>
              <TableButton
                buttonText={
                  data.row.state !== "Pending" ? "Activated" : "Activate"
                }
                disabled={data.row.state !== "Pending"}
                style={{
                  color:
                    data.row.state !== "Pending"
                      ? "#bfbfbf"
                      : "var(--success-color)",
                  width: 60
                }}
                onClick={() => {
                  dispatch(setActivateBeneficiaryModalOpen(true))
                  dispatch(setBeneficiary({ id: data.row.id }))
                }}
              />
              <TableButton
                buttonText="View"
                onClick={() => showViewBeneficiaryModal(data.row)}
              />
              <TableButton
                buttonText="Delete"
                style={{
                  color: "var(--danger-color)"
                }}
                onClick={() => {
                  dispatch(setDeleteBeneficiaryModalOpen(true))
                  dispatch(setBeneficiary({ id: data.row.id }))
                  // console.log("")
                }}
              />
            </div>
          )
        }
      ]

  return (
    <div className={Styles.tabContainer}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <h5>Beneficiaries</h5>

        <Button
          text="Create new"
          style={{
            width: "fit-content"
          }}
          variant="link"
          onClick={() => {
            dispatch(setCreateBeneficiaryModalOpen(true))
          }}
        />
      </div>

      {/*  Sorting Row */}
      <div className={Styles.sortingRow} style={{ display: "none" }}>
        <Search placeholder="Search beneficiary name" />

        <Autocomplete
          options={currencies}
          getOptionLabel={(coin) => coin?.id?.toUpperCase()}
        />
      </div>

      <MUIDatagrid
        disableSorting
        page={page}
        pageLimit={limit}
        totalRowsCount={totalRows}
        onPageChange={handlePageChange}
        // onSortModelChange={handleSortModelChange}
        loading={tableLoading}
        getRowId={(row) => row.id}
        rows={beneficiaries}
        columns={beneficiariesColumns}
        hideHeader={isMobileView}
        rowHeight={isMobileView && 160}
      />
    </div>
  )
}

export default BeneficiariesTab
