import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Styles from "./faqs.module.css"
import { ReactComponent as MailIcon } from "./icons/mail.svg"
import { ReactComponent as PhoneIcon } from "./icons/phone.svg"
import { ReactComponent as MessageIcon } from "./icons/message.svg"
import SideNavButton from "../../components/SideNavButton/SideNavButton"
import Accordions from "../../components/Accordions/Accordions"
import { _getFAQCategories, _getFAQQuestions } from "./redux/actions"
import { setFAQCategories } from "./redux/slices/FAQCategoriesSlice"
import { setFAQQuestions } from "./redux/slices/FAQQuestionsSlice"
import { _getExchangeInfo } from "../contact-support/redux/actions"
import { setExchangeInfo } from "../contact-support/redux/slices/exchangeInfoSlice"

function FAQs() {
  const dispatch = useDispatch()
  const FAQCategories = useSelector((state) => state.FAQCategories.value || [])
  const FAQQuestions = useSelector((state) => state.FAQQuestions.value || [])
  const [selectedTab, setSelectedTab] = useState(null)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)
  const exchangeInfo = useSelector(state => state.exchangeInfo.value)

  document.title = `${exchangeName}: FAQ`

  useEffect(() => {
    _getFAQCategories(
      (data) => {
        dispatch(setFAQCategories(data))
        setSelectedTab(data[0]?.id)
      },
      (err) => {
        console.log(err)
      }
    )

    _getExchangeInfo(
      (data) => {
        dispatch(setExchangeInfo(data.data[0]))
      },
      (err) => {
        console.log(err)
      }
    )

    _getFAQQuestions(
      (data) => {
        dispatch(setFAQQuestions(data))
      },
      (err) => {
        console.log(err)
      }
    )
  }, [dispatch])

  const filteredQuestions = FAQQuestions.filter(
    (question) => question.category_id === selectedTab
  )

  return (
    <div className={Styles.pageContainer}>
      <section className={Styles.headerContainer}>
        <span>Quick Answers</span>
        <h1>FAQ</h1>
        <p>
          Crypto got you tangled up in knots? Fear not, fellow crypto
          adventurer! Get ready for an exhilarating journey through our FAQ
          section, where we unravel the intricacies of WenBit, piece by
          mind-blowing piece!
        </p>
      </section>

      <section className={Styles.bodyContainer}>
        <div className={Styles.navButtonsContainer}>
          {FAQCategories.length > 0 &&
            FAQCategories.map((category) => (
              <SideNavButton
                text={category.name}
                key={category.id}
                isSelected={selectedTab === category.id}
                onClick={() => setSelectedTab(category.id)}
              />
            ))}
        </div>

        <div className={Styles.FAQContentContainer}>
          <Accordions accordions={filteredQuestions} />
        </div>
      </section>

      <section className={Styles.headerContainer}>
        <span>We got Answers</span>
        <h1>Are you not entertained?</h1>
        <p>
          If you cannot find a question in our FAQ, you can always contact us.
          We will answer to you shortly!
        </p>
      </section>

      <section className={Styles.cardsContainer}>
        <div className={Styles.card}>
          <div>
            <MailIcon width={40} />
          </div>
          <span>Email</span>
          {exchangeInfo?.email ?? ''}
          <p>
            Email us for a swift,
            <br />
            laser-focus support.
          </p>
        </div>

        <div className={Styles.card}>
          <div>
            <PhoneIcon width={40} />
          </div>
          <span>Phone</span>
          {exchangeInfo?.phone_number ?? ''}
          <p>
            Reach out for
            <br />
            turbocharged assistance.
          </p>
        </div>

        <div className={Styles.card}>
          <div>
            <MessageIcon width={40} />
          </div>
          <span>Chat</span>
          {exchangeInfo?.customer_support_hours ?? ""}
          <p>
            Our live chat
            <br />
            ROCKS!
          </p>
        </div>
      </section>
    </div>
  )
}

export default FAQs
