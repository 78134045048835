// ** React Imports
import React from 'react'

// ** Component Imports
import WalletsOverviewTabHeader from './components/WalletsOverviewTabHeader'
import WalletsOverviewTabContent from './components/WalletsOverviewTabContent'

function WalletsOverviewTab() {
  return (
    <div>
      <WalletsOverviewTabHeader />
      <WalletsOverviewTabContent />
    </div>
  )
}

export default WalletsOverviewTab
