// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

function FlexibleSummary({ dailyRewardLabel, dailyRewardPercentage }) {
  return (
    <div>
      <div className={styles.summaryNumbersContainer}>
        <span>Daily Real-Time Reward APR {dailyRewardPercentage}</span>
        <span>{dailyRewardLabel}</span>
      </div>

      <p className={styles.summaryText}>
        Real-Time APR is subject to change every minute and rewards are accrued and directly accumulated in your Earn
        Wallet every minute. APR does not mean the actual or predicted returns in fiat currency.
      </p>
    </div>
  )
}

export default FlexibleSummary
