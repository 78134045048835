// ** React Imports
import React, { useState } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { _updateBalancePreference } from '../../../../redux/actions'
import { setUser } from '../../../../../user/redux/slices/userDataSlice'

// ** Styles Imports
import styles from './styles.module.css'

// ** Utils Imports
import { formatNumber } from '../../../../../helpers/formatNumber'
import { showErrorSnackbar } from '../../../../../snackbar/helpers'
import { handleDeposit } from '../../../../../../utility/deposit-withdraw-helpers/handleDeposit'
import { handleWithdraw } from '../../../../../../utility/deposit-withdraw-helpers/handleWithdraw'

// ** Icons Imports
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { ReactComponent as WalletIcon } from '../../../../../../assets/icons/imported/wallet.svg'

// ** Component Imports
import Button from '../../../../../../components/Button/Button'

function EarnWalletHeader() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Show/Hide Price
  const totalFunds = useSelector(state => state.fundsData.data.total_funds)

  // ** States
  const [hideBalanceLoading, setHideBalanceLoading] = useState(false)
  const hideBalance = useSelector(state => state.userData.value.hide_balance)
  const TotalBtcBalance = useSelector(state => state.fundsData.data.total_balance_btc)

  // ** Handlers
  const handleEarn = () => {}

  const handleToggleHidePrice = () => {
    setHideBalanceLoading(true)
    _updateBalancePreference(
      { preference: !hideBalance },
      data => {
        dispatch(setUser(data.data))
        setHideBalanceLoading(false)
      },
      () => {
        setHideBalanceLoading(false)
        showErrorSnackbar({ alertMessage: 'Something went wrong' })
      }
    )
  }
  return (
    <div className={`${styles.card} ${styles.tabHeader}`}>
      {/* First Row */}
      <div className={styles.headerFirstRowContainer}>
        <div className={styles.titlesContainer}>
          <h3 className={styles.title}>Earn Wallet</h3>
          <span className={styles.subTitle}>All your crypto bits, stored in one place. Cool huh?</span>
        </div>

        <div className={styles.buttonsContainer}>
          <Button text='History' onClick={handleDeposit} />
        </div>
      </div>

      {/* Second Row */}
      <div className={styles.headerSecondRowContainer}>
        <div className={styles.accountBalanceContainer}>
          {/* Account Balance First Row */}
          <div className={styles.accountBalanceFirstRow}>
            <div className={styles.accountBalanceTitle}>
              <WalletIcon width={20} />
              <span>Account Balance</span>
            </div>

            <button disabled={hideBalanceLoading} onClick={handleToggleHidePrice} className={styles.hidePriceButton}>
              {`${hideBalance ? 'Show' : 'Hide'} Price`} {hideBalance ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>

          {/* Account Balance Second Row */}
          <div>
            <span className={styles.accountBalanceUSDT} style={{ marginLeft: 'auto' }}>
              {hideBalance ? '********' : `${formatNumber(totalFunds, 2)}`} <span style={{ fontSize: 15 }}>USDT</span>
            </span>
            <span className={styles.accountBalanceBTC}>
              {' '}
              &#8776; {hideBalance ? '********' : `${formatNumber(TotalBtcBalance, 6)}`} BTC
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarnWalletHeader
