import { createSlice } from '@reduxjs/toolkit'

export const globalConversionsSlice = createSlice({
  name: 'globalConversions',
  initialState: {
    value: {}
  },
  reducers: {
    setGlobalConversions: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setGlobalConversions } = globalConversionsSlice.actions

export default globalConversionsSlice.reducer