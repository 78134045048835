import { createSlice } from '@reduxjs/toolkit'

export const fiatCurrenciesSlice = createSlice({
    name: 'fiatCurrencies',
    initialState: {
        value: []
    },
    reducers: {
        setFiatCurrencies: (state, payload) => {
            state.value = payload.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setFiatCurrencies } = fiatCurrenciesSlice.actions

export default fiatCurrenciesSlice.reducer