import React from 'react'

// styles
import Styles from './tableButton.module.css'
import './tableButton.css'

// redux
import { useSelector } from 'react-redux'

// ** React Router
import { Link } from 'react-router-dom'

function TableButton({
  buttonIcon,
  buttonText,
  style,
  onClick,
  disabled,
  href,
  iconPosition = 'right',
  size = 11,
  ...rest
}) {
  const Tag = href ? Link : 'button'
  return (
    <Tag
      to={href ?? ''}
      className={`tableButton ${Styles.button}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        fontSize: size,
        ...style
      }}
      {...rest}
    >
      {iconPosition === 'left' && buttonIcon}
      {buttonText}
      {iconPosition === 'right' && buttonIcon}
    </Tag>
  )
}

export default TableButton
