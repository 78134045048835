// React
import React, { useEffect, useRef, useState } from 'react'

// styles
import Styles from './styles.module.css'

// icons
import { ReactComponent as EyeIcon } from '../../../../assets/icons/imported/eye.svg'

// components
import Filters from '../../../../components/Filters'
import Button from '../../../../components/Button/Button'
import CoinTag from '../../../../components/CoinTag/CoinTag'
import AmountDisplay from '../../../../components/AmountDisplay'
import TableButton from '../../../../components/TableButton/TableButton'
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'

// redux
import { useSelector } from 'react-redux'
import { _getStakingDurations, _getStakingHistory } from '../../redux/actions'

// helpers
import { showViewStakingSubscriptionModal } from '../../../modals/staking/view-staking-subscription-modal/helpers'

// utilities
import { formatDate } from '../../../../utility/formatDate'
import { formatDateTime } from '../../../../utility/formatDateTime'

// DATAGRID
import MobileRowDatagrid from '../../../../components/MUIDatagrid/MobileRowDatagrid'

// React Router
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { showViewStakingSubscriptionHistoryModal } from '../../../modals/staking/view-staking-subscription-history/helpers'

function StakingHistoryTab() {
  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Trade History`

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 700)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // Hooks
  const navigate = useNavigate()
  const location = useLocation()

  // URL Params
  const [filteringParams, setFilteringParams] = useSearchParams()

  // table states
  const [rows, setRows] = useState([])
  const currencies = useSelector(state => state.currencies.value)

  const pageLimit = 10
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)

  const fetchStakingHistory = () => {
    setTableLoading(true)
    _getStakingHistory(
      {
        page: filteringParams.get('page'),
        limit: pageLimit,
        time_from: filteringParams.get('time_from'),
        time_to: filteringParams.get('time_to'),
        currency_id: filteringParams.get('coin'),
        type: filteringParams.get('staking_type')
      },
      res => {
        setRows(res.data)
        setTableLoading(false)
        setTotalRows(res.total)
      },
      err => setTableLoading(false)
    )
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set('page', value)
      return newParams
    })
  }

  const changeStakingType = type => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set('staking_type', type)
      return newParams
    })
  }

  const handleViewStakingSubscription = subscription => {
    showViewStakingSubscriptionHistoryModal(subscription)
  }

  useEffect(() => {
    // If no page is provided by URL
    if (!filteringParams.get('page')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('page', 1)
        return newParams
      })
    } else {
      // Else if page is provided by URL, set the page state
      setPage(Number(filteringParams.get('page')))
    }

    // If no staking type is provided by URL (flexible/locked)
    if (!filteringParams.get('staking_type')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('staking_type', 'flexible')
        return newParams
      })
    }
  }, [])

  // const stakingColumns = isMobileView
  // ? [
  // {
  //   field: 'market',
  //   headerName: 'Pair',
  //   minWidth: 170,
  //   renderCell: data => {
  //     const marketPair = marketList.find(el => el.id === data.row.market)
  //     return (
  //       <MobileRowDatagrid
  //         renderRowHeader={() => (
  //           <CoinDisplay
  //             coinImgURL={marketPair?.icon_url}
  //             coinName={marketPair?.coin_name}
  //             coinTag={marketPair?.name.toUpperCase()}
  //           />
  //         )}
  //         rows={[
  //           {
  //             title: 'Side',
  //             value: (
  //               <span className={Styles.sideColumn}>
  //                 {/* {
  //                   data.row.side === 'sell' ? (
  //                       <SellArrowSquare width={26} />
  //                   ) : (
  //                       <BuyArrowSquare width={26}/>
  //                   )
  //               } */}
  //                 <span>{data.row.side === 'sell' ? 'Sell' : 'Buy'}</span>
  //               </span>
  //             )
  //           },
  //           { title: 'Price', value: data.row.price },
  //           { title: 'Amount', value: data.row.amount },
  //           {
  //             title: 'Date',
  //             value: <span>{formatDateTime(data.row.created_at)}</span>
  //           }
  //         ]}
  //       />
  //     )
  //   },
  //   flex: 1
  // }
  // ]
  // :
  const stakingColumns = isMobileView
    ? [
        {
          field: 'market',
          headerName: 'Pair',
          minWidth: 170,
          renderCell: ({ row }) => {
            return (
              <MobileRowDatagrid
                renderRowHeader={() => <CoinDisplay useReduxStore coinId={row.currency_id} />}
                rows={[
                  {
                    title: 'Amount',
                    value: <AmountDisplay amount={row.amount} coinId={row.currency_id} />
                  },
                  {
                    title: row.type === 'flexible' ? 'Rewards Amount' : 'Duration Reward',
                    value: (
                      <AmountDisplay
                        amount={row.type === 'flexible' ? row.daily_reward : row.duration_reward}
                        coinId={row.currency_id}
                      />
                    )
                  },
                  {
                    title: 'Start Date',
                    value: <span>{formatDateTime(row.start_date)}</span>
                  },
                  {
                    title: 'State',
                    value: (
                      <span
                        style={{
                          color: `var(--${
                            row.state === 'staked'
                              ? 'success'
                              : row.state === 'processing'
                              ? 'warning'
                              : row.state === 'unstaked'
                              ? 'gray'
                              : row.state === 'rejected' && 'danger'
                          }-color)`
                        }}
                      >
                        {row.state === 'processing'
                          ? 'Processing'
                          : row.state === 'staked'
                          ? 'Active'
                          : row.state === 'unstaked'
                          ? 'Redeemed'
                          : row.state === 'rejected' && 'Rejected'}
                      </span>
                    )
                  }
                ]}
              />
            )
          },
          flex: 1
        }
      ]
    : filteringParams.get('staking_type') === 'flexible'
    ? [
        {
          field: 'id',
          headerName: 'Coin',
          minWidth: 180,
          renderCell: ({ row }) => {
            return <CoinDisplay useReduxStore coinId={row.currency_id} />
          },
          flex: 1
        },
        {
          field: 'amount',
          headerName: 'Amount',
          minWidth: 165,
          renderCell: ({ row }) => {
            return (
              <>
                {row.amount} {row.currency_id.toUpperCase()}
              </>
            )
          },
          flex: 1
        },
        {
          field: 'daily_reward',
          headerName: 'Rewards Amount',
          minWidth: 180,
          renderCell: ({ row }) => {
            return (
              <>
                {Number(row.daily_reward).toFixed(currencies.find(coin => coin.id === row.currency_id).precision)}{' '}
                {row.currency_id.toUpperCase()}
              </>
            )
          },
          flex: 1
        },
        {
          field: 'state',
          headerName: 'Active',
          minWidth: 100,
          renderCell: ({ row }) => {
            return (
              <span
                style={{
                  color: `var(--${
                    row.state === 'staked'
                      ? 'success'
                      : row.state === 'processing'
                      ? 'warning'
                      : row.state === 'unstaked'
                      ? 'gray'
                      : row.state === 'rejected' && 'danger'
                  }-color)`
                }}
              >
                {row.state === 'processing'
                  ? 'Processing'
                  : row.state === 'staked'
                  ? 'Active'
                  : row.state === 'unstaked'
                  ? 'Redeemed'
                  : row.state === 'rejected' && 'Rejected'}
              </span>
            )
          },
          flex: 1
        },
        {
          field: 'action',
          headerName: 'Action',
          minWidth: 140,
          renderCell: ({ row }) => (
            <TableButton
              buttonText='View'
              buttonIcon={<EyeIcon width={14} />}
              iconPosition='left'
              onClick={() => handleViewStakingSubscription(row)}
            />
          )
        }
      ]
    : [
        {
          field: 'id',
          headerName: 'Coin',
          minWidth: 180,
          renderCell: ({ row }) => {
            return <CoinDisplay useReduxStore coinId={row.currency_id} />
          },
          flex: 1
        },
        {
          field: 'amount',
          headerName: 'Amount',
          minWidth: 120,
          renderCell: ({ row }) => {
            return (
              <>
                {row.amount} {row.currency_id.toUpperCase()}
              </>
            )
          },
          flex: 1
        },
        {
          field: 'duration_reward',
          headerName: 'Rewards Amount',
          minWidth: 180,
          renderCell: ({ row }) => {
            return (
              <>
                {Number(row.duration_reward).toFixed(currencies.find(coin => coin.id === row.currency_id).precision)}{' '}
                {row.currency_id.toUpperCase()}
              </>
            )
          },
          flex: 1
        },
        {
          field: 'duration',
          headerName: 'Duration',
          minWidth: 100,
          renderCell: ({ row }) => {
            return <>{row.duration === 0 ? 'Flexible' : `${row.duration} days`}</>
          },
          flex: 1
        },
        {
          field: 'active',
          headerName: 'Active',
          minWidth: 80,
          renderCell: ({ row }) => {
            return (
              <span style={{ color: `var(--${row.active ? 'success' : 'danger'}-color)` }}>
                {row.active ? 'Active' : 'Redeemed'}
              </span>
            )
          },
          flex: 1
        },
        {
          field: 'action',
          headerName: 'Action',
          minWidth: 140,
          renderCell: ({ row }) => (
            <TableButton
              buttonText='View'
              buttonIcon={<EyeIcon width={14} />}
              iconPosition='left'
              onClick={() => handleViewStakingSubscription(row)}
            />
          )
        }
      ]

  return (
    <div className={Styles.tabContainer}>
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>Staking History</h5>
          <span>All that you earn, from your invested funds.</span>
        </div>
      </div>

      <div className={Styles.tabsContainer}>
        <Button
          text='Flexible'
          type='tab'
          onClick={() => {
            setRows([])
            changeStakingType('flexible')
          }}
          isTabSelected={filteringParams.get('staking_type') === 'flexible'}
        />
        <Button
          text='Locked'
          type='tab'
          onClick={() => {
            setRows([])
            changeStakingType('fixed')
          }}
          isTabSelected={filteringParams.get('staking_type') === 'fixed'}
        />

        <Filters
          onChange={fetchStakingHistory}
          resetValues={{ staking_type: filteringParams.get('staking_type') }}
          mobileBreakpoint='920px'
          filteringParams={filteringParams}
          setFilteringParams={setFilteringParams}
          filterDateRange
          filterCoin
        />
      </div>

      <MUIDatagrid
        page={page}
        rows={rows}
        disableSorting
        pageLimit={pageLimit}
        loading={tableLoading}
        columns={stakingColumns}
        hideHeader={isMobileView}
        totalRowsCount={totalRows}
        getRowId={row => row.id}
        onPageChange={handlePageChange}
        rowHeight={isMobileView && 170}
      />
    </div>
  )
}

export default StakingHistoryTab
