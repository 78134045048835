import { createSlice } from "@reduxjs/toolkit"

const exchangeCustomizationSlice = createSlice({
  name: "exchangeCustomization",
  initialState: {
    value: {}
  },
  reducers: {
    setExchangeCustomization: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setExchangeCustomization } = exchangeCustomizationSlice.actions

export default exchangeCustomizationSlice.reducer
