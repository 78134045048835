import { createSlice } from '@reduxjs/toolkit'

const viewStatkingSubscriptionHistoryModalSlice = createSlice({
  name: 'viewStakingSubscriptionHistoryModalState',
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setViewStatkingSubscriptionHistoryModalOpen: (state, action) => {
      state.open = action.payload
    },
    setViewStatkingSubscriptionHistoryModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setViewStatkingSubscriptionHistoryModalOpen, setViewStatkingSubscriptionHistoryModalData } =
  viewStatkingSubscriptionHistoryModalSlice.actions

export default viewStatkingSubscriptionHistoryModalSlice.reducer
