// ** React Imports
import * as React from 'react'

// ** Component Imports
import EarnWalletTable from './components/EarnWalletTable'
import EarnWalletHeader from './components/EarnWalletHeader'

function EarnWalletTab() {
  return (
    <>
      <EarnWalletHeader />
      <EarnWalletTable />
    </>
  )
}

export default EarnWalletTab
