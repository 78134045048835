// ** React Imports
import React, { useState } from 'react'

// ** React Redux
import { useSelector } from 'react-redux'

// ** Utils Imports
import { formatDate } from '../../../../../../utility/formatDate'

// ** Styles Imports
import styles from './styles.module.css'
import CoinDisplay from '../../../../../../components/CoinDisplay/CoinDisplay'
import CheckboxInput from '../../../../../../components/CheckboxInput/CheckboxInput'
import Button from '../../../../../../components/Button/Button'
import AmountDisplay from '../../../../../../components/AmountDisplay'
import { _redeemFixedStaking } from '../../redux/actions'
import { hideViewStakingSubscriptionModal } from '../../helpers'
import { showSuccessSnackbar } from '../../../../../snackbar/helpers'

function LockedView() {
  // ** States
  const [isAgreementChecked, setIsAgreementChecked] = useState(false)
  const [unsubscribePending, setUnsubscribePending] = useState(false)
  const modalData = useSelector(state => state.viewStakingSubscriptionModalState.data)

  const handleUnsubscribe = () => {
    setUnsubscribePending(true)
    _redeemFixedStaking(
      modalData?.id,
      res => {
        setUnsubscribePending(false)
        hideViewStakingSubscriptionModal()
        showSuccessSnackbar({ alertMessage: 'Unstake successful' })
      },
      err => {
        setUnsubscribePending(false)
        console.error(`Error while unstaking fixed staking: ${err}`)
      }
    )
  }

  return (
    <div>
      <CoinDisplay useReduxStore coinId={modalData?.currency_id} />

      {/* Total Amount */}
      <div className={styles.totalAmountContainer}>
        <span className={styles.totalAmountTitle}>Total Amount</span>
        <span className={styles.totalAmountValue}>
          <AmountDisplay coinId={modalData?.currency_id} amount={modalData?.total_amount} />{' '}
          {modalData?.currency_id?.toUpperCase()}
        </span>
      </div>

      {/* Details */}
      <div className={styles.detailsContainer}>
        <div className={styles.infoRow}>
          <span>Estimated Annual Yield</span>
          <span>{modalData?.estimate_annual_yield}%</span>
        </div>

        <div className={styles.infoRow}>
          <span>Subscription Date</span>
          <span>{formatDate(modalData?.subscription_date)}</span>
        </div>

        <div className={styles.infoRow}>
          <span>Locked Period</span>
          <span>{modalData?.locked_period} Days</span>
        </div>

        <div className={styles.infoRow}>
          <span>Interest End Date</span>
          <span>{formatDate(modalData?.interest_end_date)}</span>
        </div>

        <div className={styles.infoRow}>
          <span>Accrue Days</span>
          <span>{modalData?.accure_days} Days</span>
        </div>

        <div className={styles.infoRow}>
          <span>Est. Next Distribution Amount</span>
          <span>
            <AmountDisplay coinId={modalData?.currency_id} amount={modalData?.est_nxt_distribution_amount} />
          </span>
        </div>

        <div className={styles.infoRow}>
          <span>Redeem to</span>
          <span>Spot Wallet</span>
        </div>
      </div>

      {/* Agree Checkbox */}
      <div className={styles.agreeCheckbox}>
        <CheckboxInput
          label='I agree that by redeeming in advance will result in the loss of the whole rewards, or you can get the rewards until the Subscription term ends.'
          checkboxId='terms'
          onChange={e => setIsAgreementChecked(e.target.checked)}
          checkboxStyle={{ color: '#F7931A', backgroundColor: 'white' }}
          containerStyle={{ backgroundColor: '#FFD098', borderRadius: '10px', padding: '15px', color: 'black' }}
        />
      </div>

      <Button
        text='Unsubscribe Early'
        isLoading={unsubscribePending}
        disabled={!isAgreementChecked}
        onClick={handleUnsubscribe}
      />
    </div>
  )
}

export default LockedView
