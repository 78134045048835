// ** React Imports
import React, { useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Styles Imports
import styles from './styles.module.css'

// ** Slider Component Imports
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

// ** Utils Imports
import { formatDate } from '../../../../../../utility/formatDate'

// form validations
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Component Imports
import Input from '../../../../../../components/Input/Input'
import Button from '../../../../../../components/Button/Button'
import AmountDisplay from '../../../../../../components/AmountDisplay'
import CoinDisplay from '../../../../../../components/CoinDisplay/CoinDisplay'
import { _unstakeFlexibleStaking } from '../../redux/actions'
import { hideViewStakingSubscriptionModal } from '../../helpers'
import { showSuccessSnackbar } from '../../../../../snackbar/helpers'

function FlexibleView() {
  // ** Constants
  const sliderMarks = {
    0: '0%',
    0.25: '25%',
    0.5: '50%',
    0.75: '75%',
    1: '100%'
  }

  // ** States
  const [sliderValue, setSliderValue] = useState(0)
  const [amountValue, setAmountValue] = useState(undefined)
  const [unsubscribePending, setUnsubscribePending] = useState(false)
  const modalData = useSelector(state => state.viewStakingSubscriptionModalState.data)

  // ** Schema
  const schema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Amount is required')
      .required('Amount is required')
      .positive('Amount is required')
      .max(modalData?.total_amount, `Maximum amount is ${modalData?.total_amount}`)
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  })

  // ** Handlers
  const handleSliderChange = value => {
    setValue('amount', modalData?.total_amount * value)
    setAmountValue(modalData?.total_amount * value)
    setSliderValue(value)
    trigger('amount')
  }

  const handleAmountChange = e => {
    const amount = e.target.value
    setAmountValue(amount)
    setValue('amount', e.target.value)
    trigger('amount')
  }

  const handleMax = () => {
    setAmountValue(modalData?.total_amount)
    setValue('amount', modalData?.total_amount)
    trigger('amount')
    setSliderValue(1)
  }

  const onSubmit = formData => {
    setUnsubscribePending(true)
    console.log(formData)
    _unstakeFlexibleStaking(
      { id: modalData?.id, amount: formData.amount },
      res => {
        setUnsubscribePending(false)
        hideViewStakingSubscriptionModal()
        showSuccessSnackbar({ alertMessage: 'Unstake successful' })
      },
      err => {
        setUnsubscribePending(false)
        console.error(`Error while unstaking flexible staking: ${err}`)
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CoinDisplay useReduxStore coinId={modalData?.currency_id} />

      {/* Total Amount */}
      <div className={styles.totalAmountContainer}>
        <span className={styles.totalAmountTitle}>Total Amount</span>
        <span className={styles.totalAmountValue}>
          <AmountDisplay coinId={modalData?.currency_id} amount={modalData?.total_amount} />{' '}
          {modalData?.currency_id?.toUpperCase()}
        </span>
      </div>

      {/* Details */}
      <div className={styles.detailsContainer}>
        <div className={styles.infoRow}>
          <span>Estimated Annual Yield</span>
          <span>{modalData?.estimate_annual_yield}%</span>
        </div>

        <div className={styles.infoRow}>
          <span>Cumulative Real-time APR Rewards</span>
          <span>
            <AmountDisplay amount={modalData?.cumulative_real_time_reward} coinId={modalData?.currency_id} />
          </span>
        </div>

        <div className={styles.infoRow}>
          <span>Cumulative Total Rewards</span>
          <span>
            <AmountDisplay amount={modalData?.cumulative_total_reward} coinId={modalData?.currency_id} />
          </span>
        </div>
      </div>

      {/* Amount Input */}
      <Input
        type='number'
        placeholder='Enter Amount'
        inputLabel='Unsubscribe Amount'
        containerStyle={{ margin: '25px 0 0 0' }}
        onChange={e => {
          handleAmountChange(e)
          register('amount').onChange(e)
        }}
        value={amountValue}
        error={errors.amount}
        indicatorLabel={`Available for Unsubscription ${
          modalData?.total_amount
        } ${modalData?.currency_id?.toUpperCase()}`}
        showBorder
        icon={
          <div className={styles.inputEndContainer}>
            <div className={styles.inputCoinName}>{modalData?.currency_id?.toUpperCase()}</div>
            <div className={styles.divider} />
            <Button
              text='MAX'
              variant='link'
              style={{
                padding: 0,
                width: 'fit-content'
              }}
              onClick={handleMax}
            />
          </div>
        }
      />

      <Slider
        min={0}
        max={1}
        step={0.01}
        marks={sliderMarks}
        value={sliderValue}
        onChange={handleSliderChange}
        trackStyle={[{ backgroundColor: 'var(--primary-color)' }]}
        railStyle={{ backgroundColor: 'var(--gray-color)' }}
        handleStyle={{
          height: 12,
          width: 12,
          marginTop: -3.5,
          backgroundColor: 'white',
          border: `2px solid var(--primary-color)`,
          borderRadius: '50%',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        dotStyle={{
          backgroundColor: 'var(--gray-color)',
          borderColor: 'var(--gray-color)'
        }}
        activeDotStyle={{
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)'
        }}
        style={{ margin: '20px auto 40px auto', width: '90%' }}
      />

      <Button type='submit' text='Unsubscribe' isLoading={unsubscribePending} />
    </form>
  )
}

export default FlexibleView
