import React, { useEffect } from "react"

// styles
import Styles from "./contactSupport.module.css"

// icons
import { ReactComponent as MailIcon } from "./icons/mail.svg"
import { ReactComponent as PhoneIcon } from "./icons/phone.svg"
import { ReactComponent as MessageIcon } from "./icons/message.svg"
import { useDispatch, useSelector } from "react-redux"
import { _getExchangeInfo } from './redux/actions' // Update the path accordingly
import { setExchangeInfo } from './redux/slices/exchangeInfoSlice'

function ContactSupport() {
  const dispatch = useDispatch()
  const exchangeInfo = useSelector(state => state.exchangeInfo.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Support`

  useEffect(() => {
    _getExchangeInfo(
      (data) => {
        dispatch(setExchangeInfo(data.data[0]))
      },
      (err) => {
        console.log(err)
      }
    )
  }, [dispatch])

  return (
    <div className={Styles.pageContainer}>
      <section className={Styles.headerContainer}>
        <span>Support</span>
        <h1>Contact support</h1>
        <p>
          Having technical issues with your WenBit? Don't sweat it, we've got
          your back. Get in touch with our expert team and we'll get you up and
          running in no time. <br />
          At WenBit, we're committed to providing you with the best possible
          experience. So don't hesitate to reach out - we're here to help.
        </p>
        Located in: {exchangeInfo?.company_address ?? ""}
      </section>

      <section className={Styles.cardsContainer}>
        {/* MAIL */}
        <div className={Styles.card}>
          <div>
            <MailIcon width={40} />
          </div>
          <span>Email</span>
          {exchangeInfo?.email ?? ''}
          <p>
            Email us for a swift,
            <br />
            laser-focus support.
          </p>
        </div>

        {/* PHONE */}
        <div className={Styles.card}>
          <div>
            <PhoneIcon width={40} />
          </div>
          <span>Phone</span>
          {exchangeInfo?.phone_number ?? ''}
          <p>
            Reach out for
            <br />
            turbocharged assistance.
          </p>
        </div>

        {/* CHAT */}
        <div className={Styles.card}>
          <div>
            <MessageIcon width={40} />
          </div>
          <span>Chat</span>
          {exchangeInfo?.customer_support_hours ?? ""}
          <p>
            Our live chat
            <br />
            ROCKS!
          </p>
        </div>
      </section>
    </div>
  )
}

export default ContactSupport
