import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// redux
import { _logout } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { setBackdropOpen } from '../../redux/slices/backdropSlice'
import { logoutUser } from '../../modules/user/redux/slices/userDataSlice'

// styles
import Styles from './drawerNavigation.module.css'

// constants
import { walletRoutes } from '../../constants/routesConstants'

// icons
import { PiPiggyBankBold } from 'react-icons/pi'
import { ReactComponent as TVIcon } from '../../assets/icons/imported/tv.svg'
import { ReactComponent as LockIcon } from '../../assets/icons/imported/lock.svg'
import { ReactComponent as MenuIcon } from '../../assets/icons/imported/menu.svg'
import { ReactComponent as ClockIcon } from '../../assets/icons/imported/clock.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/imported/close.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/imported/profile.svg'
import { ReactComponent as EarthIcon } from '../../assets/icons/imported/global.svg'
import { ReactComponent as WalletIcon } from '../../assets/icons/imported/wallet.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/imported/logout.svg'
import { ReactComponent as MarketIcon } from '../../assets/icons/imported/market.svg'
import { ReactComponent as HomeIcon } from '../../assets/icons/imported/dashboard.svg'
import { ReactComponent as ConvertIcon } from '../../assets/icons/imported/convert.svg'
import { ReactComponent as DocumentIcon } from '../../assets/icons/imported/document.svg'
import { ReactComponent as ExchangeIcon } from '../../assets/icons/imported/market-cap.svg'
import { ReactComponent as BeneficiariesIcon } from '../../assets/icons/imported/beneficiaries.svg'

// components
import Button from '../Button/Button'
import { Drawer, IconButton } from '@mui/material'
import SideNavButton from '../SideNavButton/SideNavButton'
import KYCStatusBadge from '../KYCStatusBadge/KYCStatusBadge'
import DrawerNavigationMenuButton from './DrawerNavigationMenuButton'

// MUI
import { FormattedMessage } from 'react-intl'
import { setSnackbarData, setSnackbarOpen } from '../../modules/snackbar/redux/snackbarSlice'

// utils
import handleLogout from '../../utility/user/handleLogout'
import { hideEmail } from '../../modules/helpers/HideEmail'
import { formatNumber } from '../../modules/helpers/formatNumber'
import { showLanguageCurrencySelectModal } from '../../modules/modals/language-currency-select/helpers'
import { closeDrawerNavigation, setOpenDrawerNavigation } from './helpers'
import SidebarDropdown from '../sidebar-layout-components/SidebarDropdown'
import { TbCalendarDollar } from 'react-icons/tb'

function DrawerNavigation() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const language = useSelector(state => state.language)
  const theme = useSelector(state => state.theme.value)
  const open = useSelector(state => state.drawerNavigationState.open)
  const TotalFunds = useSelector(state => state.fundsData.data.total_funds)
  const hideBalance = useSelector(state => state.userData.value.hide_balance)
  const TotalBtcBalance = useSelector(state => state.fundsData.data.total_balance_btc)

  const languageOptions = [
    { value: 'en', label: 'English' }
    // { value: "es", label: "Española" },
    // { value: "ar", label: "عربي" },
    // { value: "ru", label: "Русский" },
    // { value: "ur", label: "اردو" },
    // { value: "zh", label: "中国人" }
  ]

  const userData = useSelector(state => state.userData.value)
  const hasDocumentLabel = userData?.labels?.some(label => label.key === 'document')

  const [isWalletDropdownOpen, setIsWalletDropdownOpen] = useState(false)

  const handleWalletDropdownToggle = e => {
    setIsWalletDropdownOpen(prev => !prev)
  }

  function getEmailUserName(email) {
    const emailParts = email.split('@')
    const username = emailParts[0]
    return username
  }

  const toggleDrawer = open => event => {
    // console.log(open, event)
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpenDrawerNavigation(open)
  }

  const handleMenuItemClick = path => {
    setOpenDrawerNavigation(false)
    navigate(path)
  }

  const handleMobileLogout = () => {
    handleLogout().then(() => {
      setOpenDrawerNavigation(false)
    })
    // dispatch(setBackdropOpen(true))
    // _logout(
    //   () => {
    //     dispatch(logoutUser(""))
    //     dispatch(setBackdropOpen(false))

    //     // history.push("/markets")
    //   },
    //   (error) => {
    //     setOpenDrawerNavigation(false)
    //     dispatch(setSnackbarOpen(true))
    //     dispatch(
    //       setSnackbarData({
    //         alertMessage: error.errors[0].length ? (
    //           <FormattedMessage id={error.errors[0]} />
    //         ) : (
    //           "Unknown Error"
    //         ),
    //         severity: "error",
    //         position: "topRight"
    //       })
    //     )
    //   }
    // )
  }

  //keep wallet dropdown if current route is a wallet route
  useLayoutEffect(() => {
    if (open) {
      // on open
      if (walletRoutes.includes(location.pathname)) {
        setIsWalletDropdownOpen(true)
      } else {
        setIsWalletDropdownOpen(false)
      }
    }
  }, [open])

  return (
    <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
      <div
        style={{
          overflow: 'overlay'
        }}
      >
        <div className={Styles.closeButtonContainer}>
          <IconButton aria-label='menu' onClick={toggleDrawer(false)}>
            <CloseIcon width={15} height={15} color='var(--text-color)' />
          </IconButton>
        </div>

        <div className={Styles.navHeaderContainer}>
          {userData?.email ? (
            <div className={Styles.userDataContainer}>
              <div className={Styles.userDataHeader}>
                <img src={userData?.user_avatar?.url} />
                <div>
                  <div>
                    {userData.username ?? getEmailUserName(userData.email)}
                    <KYCStatusBadge
                      status={`${
                        hasDocumentLabel
                          ? userData?.labels?.find(el => el.key === 'document')?.value === 'verified'
                            ? 'verified'
                            : 'pending'
                          : 'not-verified'
                      }`}
                    />
                  </div>
                  <div>{hideEmail(userData.email)}</div>
                </div>
              </div>

              <div className={Styles.walletBalanceTitleContainer}>
                <WalletIcon width={16} />
                <span>Wallet Balance</span>
              </div>

              <div>
                <span style={{ fontSize: 23 }}>{hideBalance ? '********' : `${formatNumber(TotalFunds, 2)}`}</span>{' '}
                <span style={{ color: 'unset' }}>USDT</span>
                {/* <span style={{ fontSize: 16, color: "var(--gray-color)" }}>
                  &#8776;{" "}
                  {hideBalance
                    ? "********"
                    : `${formatNumber(TotalBtcBalance, 6)}`}{" "}
                  BTC
                </span> */}
              </div>
            </div>
          ) : (
            <div className={Styles.signInButtonsContainer}>
              <Button
                text={<FormattedMessage id='page.header.navbar.signIn' />}
                variant='dark'
                onClick={() => navigate('/login')}
                style={{
                  width: '45%'
                }}
              />
              <Button
                text={<FormattedMessage id='page.header.navbar.signUp' />}
                onClick={() => {
                  navigate('/signup')
                }}
                style={{
                  width: '45%'
                }}
              />
            </div>
          )}
        </div>

        <div className={Styles.divider} style={{ margin: '0 auto' }}></div>

        {userData.email && (
          <DrawerNavigationMenuButton
            text='Overview'
            icon={<HomeIcon width={17} />}
            isSelected={location.pathname.startsWith('/account/wallet/overview')}
            onClick={() => handleMenuItemClick('/account/wallet/overview')}
          />
        )}

        <SidebarDropdown
          icon={<WalletIcon />}
          label='Assets'
          containerStyle={{ width: '87%', margin: '4px auto' }}
          dropdownStyle={{ marginLeft: '42px' }}
          dropdownButtons={[
            {
              icon: <WalletIcon />,
              label: 'Spot Wallet',
              href: '/account/wallet/spot/all',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <PiPiggyBankBold size={22} />,
              label: 'Earn Wallet',
              href: '/account/wallet/earn',
              onClick: () => setOpenDrawerNavigation(false)
            }
          ]}
          dropdownHeight='90px'
        />

        <SidebarDropdown
          icon={<ClockIcon width={20} />}
          label='History'
          containerStyle={{ width: '87%', margin: '4px auto' }}
          dropdownStyle={{ marginLeft: '42px' }}
          dropdownButtons={[
            {
              icon: <WalletIcon />,
              label: 'Wallet History',
              href: '/account/wallet/history',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <MenuIcon />,
              label: 'Order History',
              href: '/account/orders/open',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <MarketIcon />,
              label: 'Trade History',
              href: '/account/trade/history',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <ConvertIcon />,
              label: 'Conversion History',
              href: '/account/convert/history',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <TbCalendarDollar size={21} />,
              label: 'Staking History',
              href: '/account/staking/history',
              onClick: () => setOpenDrawerNavigation(false)
            }
          ]}
          dropdownHeight='250px'
        />

        <SidebarDropdown
          icon={<UserIcon width={20} />}
          label='My Account'
          containerStyle={{ width: '87%', margin: '4px auto' }}
          dropdownStyle={{ marginLeft: '42px' }}
          dropdownButtons={[
            {
              icon: <UserIcon width={12} />,
              label: 'My Profile',
              href: '/account/profile',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <BeneficiariesIcon style={{ marginLeft: -7, marginRight: -11.5 }} width={27} />,
              label: 'Beneficiaries',
              href: '/account/beneficiaries',
              onClick: () => setOpenDrawerNavigation(false)
            },

            {
              icon: <LockIcon width={12} />,
              label: 'Security',
              href: '/account/security',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <TVIcon width={14} />,
              label: 'Account Activities',
              href: '/account/activities',
              onClick: () => setOpenDrawerNavigation(false)
            },
            {
              icon: <DocumentIcon width={12} />,
              label: 'KYC Verification',
              href: '/account/kyc',
              onClick: () => setOpenDrawerNavigation(false)
            }
          ]}
          dropdownHeight='180px'
        />

        <DrawerNavigationMenuButton
          text='Markets'
          icon={<MarketIcon height={20} />}
          isSelected={location.pathname.startsWith('/markets')}
          onClick={() => handleMenuItemClick('/markets')}
        />

        <DrawerNavigationMenuButton
          text='Exchange'
          icon={<ExchangeIcon height={15} />}
          isSelected={location.pathname.startsWith('/trade')}
          onClick={() => handleMenuItemClick('/trade')}
        />

        <DrawerNavigationMenuButton
          text='Convert'
          icon={<ConvertIcon height={17} />}
          isSelected={location.pathname.startsWith('/convert')}
          onClick={() => handleMenuItemClick('/convert')}
        />

        <DrawerNavigationMenuButton
          text='Buy Crypto'
          icon={<MarketIcon height={20} />}
          isSelected={location.pathname.startsWith('/buy-crypto')}
          onClick={() => handleMenuItemClick('/buy-crypto')}
        />

        {userData.email && (
          <>
            <div className={Styles.divider}></div>

            <DrawerNavigationMenuButton
              text={`${languageOptions.find(el => el.value === language.code).label} | USD`}
              icon={<EarthIcon height={17} />}
              onClick={() => {
                closeDrawerNavigation()
                showLanguageCurrencySelectModal()
              }}
            />
            <div className={Styles.divider}></div>
            <DrawerNavigationMenuButton text='Log Out' icon={<LogoutIcon height={17} />} onClick={handleMobileLogout} />

            <div className={Styles.divider}></div>
          </>
        )}
      </div>
    </Drawer>
  )
}

export default DrawerNavigation
