// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** API Imports
import { _fetchSubscriptionDetails, _unstakeStakingSubscription } from './redux/actions/index'

// ** Component Imports
import LockedView from './components/LockedView'
import FlexibleView from './components/FlexibleView'
import WenbitModal from '../../../../components/Modal/Modal'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'

// ** Helpers Imports
import { hideViewStakingSubscriptionModal } from './helpers'
import { hideWarningModal, showWarningModal } from '../../warning/helpers'
import { hideBackdrop, showBackdrop } from '../../../../utility/backdrop/backdropHelpers'
import { showErrorSnackbar, showSuccessSnackbar } from '../../../snackbar/helpers'
import { setViewStakingSubscriptionModalData } from './redux/viewStakingSubscriptionModalSlice'
import { Box } from '@mui/material'

function ViewStakingSubscriptionModal() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const modalOpen = useSelector(state => state.viewStakingSubscriptionModalState.open)
  const modalData = useSelector(state => state.viewStakingSubscriptionModalState.data)

  const [loading, setLoading] = useState(false)

  // ** Handlers
  const handleClose = () => {
    hideViewStakingSubscriptionModal()
  }

  // const getFromDate = date => {
  //   const currentDate = new Date(date)
  //   const year = currentDate.getFullYear()
  //   const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  //   const day = String(currentDate.getDate()).padStart(2, '0')
  //   const hours = String(currentDate.getHours()).padStart(2, '0')
  //   const minutes = String(currentDate.getMinutes()).padStart(2, '0')

  //   return `${year}-${month}-${day} ${hours}:${minutes}`
  // }

  // const getToDate = (date, period) => {
  //   const currentDate = new Date(date)
  //   const futureDate = new Date(currentDate.getTime() + period * 24 * 60 * 60 * 1000)

  //   const year = futureDate.getFullYear()
  //   const month = String(futureDate.getMonth() + 1).padStart(2, '0')
  //   const day = String(futureDate.getDate()).padStart(2, '0')
  //   const hours = String(futureDate.getHours()).padStart(2, '0')
  //   const minutes = String(futureDate.getMinutes()).padStart(2, '0')

  //   return `${year}-${month}-${day} ${hours}:${minutes}`
  // }

  const renderLoading = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
      <ShimmerLine width='100%' height='220px' />
      <ShimmerLine width='100%' height='100px' />
      <ShimmerLine width='100%' height='45px' />
    </Box>
  )

  const fetchSubscriptionData = () => {
    setLoading(true)
    _fetchSubscriptionDetails(
      modalData.id,
      data => {
        setLoading(false)
        dispatch(setViewStakingSubscriptionModalData({ ...modalData, ...data }))
      },
      err => {
        setLoading(false)
        console.log(`Error while fetching staking subscription: ${err}`)
      }
    )
  }

  useEffect(() => {
    if (modalOpen) {
      fetchSubscriptionData()
    }
  }, [modalOpen])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title='Staking Subscription'
        render={() => (
          <>
            {loading ? (
              renderLoading()
            ) : modalData?.type === 'locked' || modalData?.type === 'fixed' ? (
              <LockedView />
            ) : (
              <FlexibleView />
            )}
          </>
        )}
      />
    </>
  )
}

export default ViewStakingSubscriptionModal

// {/* <div>
//               {/* Coin Name */}
//               <CoinDisplay useReduxStore coinId={modalData?.currency_id} />

//               {/* Amount */}
//               <div className={styles.row} style={{ marginTop: '20px' }}>
//                 <span className={styles.rowLabel}>Amount</span>
//                 <span className={styles.rowValue}>
//                   {modalData?.amount} {modalData?.currency_id.toUpperCase()}
//                 </span>
//               </div>

//               {/* Status */}
//               <div className={styles.row}>
//                 <span className={styles.rowLabel}>Status</span>
//                 <span
//                   className={styles.rowValue}
//                   style={{ color: `var(--${modalData?.active ? 'success' : 'danger'}-color)` }}
//                 >
//                   {modalData?.state === 'processing'
//                     ? 'Processing'
//                     : modalData?.state === 'staked'
//                     ? 'Active'
//                     : modalData?.state === 'unstaked'
//                     ? 'Redeemed'
//                     : modalData?.state === 'rejected' && 'Rejected'}
//                 </span>
//               </div>

//               {/* Duration */}
//               {modalData?.duration !== 0 && (
//                 <div className={styles.row}>
//                   <span className={styles.rowLabel}>Duration</span>
//                   <span className={styles.rowValue}>{modalData?.duration} Days</span>
//                 </div>
//               )}

//               {/* Amount Rewards */}
//               <div className={styles.row} style={{ marginBottom: '20px' }}>
//                 <span className={styles.rowLabel}>Reward Amount</span>
//                 <span className={styles.rowValue}>
//                   {modalData?.duration === 0 ? modalData?.daily_reward : modalData?.duration_reward}{' '}
//                   {modalData?.currency_id.toUpperCase()}
//                 </span>
//               </div>

//               {/* Timeline */}
//               <FromToTimeline
//                 fromLabel='First Date of Staking'
//                 toLabel='Redeemed At'
//                 fromValue={getFromDate(modalData?.subscription_date)}
//                 toValue={modalData?.end_date ? getToDate(modalData?.end_date, modalData?.duration) : '--/--/--'}
//               />

//               {modalData?.duration === 0 && (
//                 <Button
//                   isLoading={loading}
//                   disabled={loading || modalData?.state === 'unstaked'}
//                   text={modalData?.state === 'staked' ? 'Redeem' : 'Redeemed'}
//                   style={{ marginTop: '20px' }}
//                   onClick={handleRedeem}
//                 />
//               )}
//             </div> */}
