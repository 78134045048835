import { API, BINANCE_API } from "../../../../utility/API"
import axios from "axios"
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"


//************************************//
export const _getFAQCategories = (successCallback, errorCallback) => {
  API.get(`authenticate/public/customization/categories`)
    .then(function ({ data }) {
      successCallback(data)
    })
    .catch(function (error) {
      errorCallback(error)
    })
}
//************************************//
export const _getFAQQuestions = (successCallback, errorCallback) => {
  API.get(`authenticate/public/customization/questions`)
    .then(function ({ data }) {
      successCallback(data)
    })
    .catch(function (error) {
      errorCallback(error)
    })
}