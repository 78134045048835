import { store } from '../../../../redux/store'
import {
  setViewStakingSubscriptionModalData,
  setViewStakingSubscriptionModalOpen
} from './redux/viewStakingSubscriptionModalSlice'

export const showViewStakingSubscriptionModal = ({ id, type }) => {
  store.dispatch(setViewStakingSubscriptionModalData({ id, type }))
  store.dispatch(setViewStakingSubscriptionModalOpen(true))
}

export const hideViewStakingSubscriptionModal = () => {
  store.dispatch(setViewStakingSubscriptionModalData(null))
  store.dispatch(setViewStakingSubscriptionModalOpen(false))
}
