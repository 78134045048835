import { createSlice } from '@reduxjs/toolkit'

export const FAQQuestionsSlice = createSlice({
  name: 'FAQQuestions',
  initialState: {
    value: []
  },
  reducers: {
    setFAQQuestions: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setFAQQuestions } = FAQQuestionsSlice.actions

export default FAQQuestionsSlice.reducer