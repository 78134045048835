import React, { useEffect, useRef, useState } from 'react'
import Search from '../../../../components/Search/Search'

// styles
import Styles from './TradeHistoryTab.module.css'

// icons
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/imported/calendar.svg'
import { ReactComponent as FilterIcon } from '../../../../assets/icons/imported/filter.svg'
import { ReactComponent as DownArrowIcon } from '../../../../assets/icons/imported/down-arrow.svg'
import { ReactComponent as UpArrowIcon } from '../../../../assets/icons/imported/up-arrow.svg'
import { ReactComponent as BuyArrowSquare } from '../../../../assets/icons/imported/buy-arrow-square.svg'
import { ReactComponent as SellArrowSquare } from '../../../../assets/icons/imported/sell-arrow-square.svg'
// components
import CoinTag from '../../../../components/CoinTag/CoinTag'
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import SidebarContentWrapper from '../../../../components/sidebar-layout-components/SidebarContentWrapper'

// redux
import { useSelector } from 'react-redux'
import { _getTrades } from '../../redux/actions'

// utilities
import { formatDate } from '../../../../utility/formatDate'
import { formatDateTime } from '../../../../utility/formatDateTime'
import { debounce } from '../../../../utility/debounce'

// DATAGRID
import MobileRowDatagrid from '../../../../components/MUIDatagrid/MobileRowDatagrid'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'
import TradeHistoryFilters from './TradeHistoryFilters'
import { useSearchParams } from 'react-router-dom'
import Filters from '../../../../components/Filters'

function TradeHistoryTab() {
  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Trade History`

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 700)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // URL Params
  const [filteringParams, setFilteringParams] = useSearchParams()

  // table states
  const [trades, setTrades] = useState([])

  const pageLimit = 10
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)

  const marketList = useSelector(state => state.marketList.value)

  const fetchTrades = () => {
    setTableLoading(true)
    _getTrades(
      {
        page: filteringParams.get('page'),
        limit: pageLimit,
        market: filteringParams.get('market'),
        time_from: filteringParams.get('time_from'),
        time_to: filteringParams.get('time_to'),
        type: filteringParams.get('type')
      },
      res => {
        setTrades(res.data)
        setTableLoading(false)
        setTotalRows(res.total)
      },
      err => setTableLoading(false)
    )
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set('page', value)
      return newParams
    })
  }

  useEffect(() => {
    // If no page is provided by URL
    if (!filteringParams.get('page')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('page', 1)
        return newParams
      })
    } else {
      // Else if page is provided by URL, set the page state
      setPage(Number(filteringParams.get('page')))
    }
  }, [])

  // useEffect(() => {
  //   fetchTrades()
  // }, [page])

  const tradesColumns = isMobileView
    ? [
        {
          field: 'market',
          headerName: 'Pair',
          minWidth: 170,
          renderCell: data => {
            const marketPair = marketList.find(el => el.id === data.row.market)
            return (
              <MobileRowDatagrid
                renderRowHeader={() => (
                  <CoinDisplay
                    coinImgURL={marketPair?.icon_url}
                    coinName={marketPair?.coin_name}
                    coinTag={marketPair?.name.toUpperCase()}
                  />
                )}
                rows={[
                  {
                    title: 'Side',
                    value: (
                      <span className={Styles.sideColumn}>
                        {/* {
                          data.row.side === 'sell' ? (
                              <SellArrowSquare width={26} />
                          ) : (
                              <BuyArrowSquare width={26}/>
                          )
                      } */}
                        <span>{data.row.side === 'sell' ? 'Sell' : 'Buy'}</span>
                      </span>
                    )
                  },
                  { title: 'Price', value: data.row.price },
                  { title: 'Amount', value: data.row.amount },
                  {
                    title: 'Date',
                    value: <span>{formatDateTime(data.row.created_at)}</span>
                  }
                ]}
              />
            )
          },
          flex: 1
        }
      ]
    : [
        {
          field: 'market',
          headerName: 'Pair',
          minWidth: 180,
          renderCell: data => {
            const marketPair = marketList.find(el => el.id === data.row.market)
            return (
              <span className={Styles.pairColumn}>
                <img src={marketPair?.icon_url} />
                {marketPair?.coin_name}
                <CoinTag tag={marketPair?.name.toUpperCase()} size={11} />
              </span>
            )
          },
          flex: 1
        },
        {
          field: 'side',
          headerName: 'Side',
          minWidth: 155,
          editable: false,
          renderCell: data => (
            <span className={Styles.sideColumn}>
              {data.row.side === 'sell' ? <SellArrowSquare width={26} /> : <BuyArrowSquare width={26} />}
              <div>
                <span>{data.row.side === 'sell' ? 'Sell' : 'Buy'}</span>
                <span>{formatDate(data.row.created_at)}</span>
              </div>
            </span>
          ),
          flex: 1
        },
        {
          field: 'price',
          headerName: 'Price',
          minWidth: 60,
          editable: false,
          flex: 1
        },
        {
          field: 'amount',
          headerName: 'Amount',
          minWidth: 60,
          editable: false,

          flex: 1
        },
        {
          field: 'created_at',
          headerName: 'Date',
          minWidth: 120,
          editable: false,
          flex: 1,
          renderCell: data => <span style={{ color: 'var(--gray-color)' }}>{formatDateTime(data.row.created_at)}</span>
        }
      ]

  return (
    <SidebarContentWrapper title='Trade History' secondaryTitle='All that’s in, and all that’s gone out.'>
      <Filters
        onChange={fetchTrades}
        mobileBreakpoint='920px'
        filteringParams={filteringParams}
        setFilteringParams={setFilteringParams}
        filterDateRange
        filterMarketPair
        filterType
      />

      <MUIDatagrid
        page={page}
        rows={trades}
        disableSorting
        pageLimit={pageLimit}
        loading={tableLoading}
        columns={tradesColumns}
        hideHeader={isMobileView}
        totalRowsCount={totalRows}
        getRowId={row => row.id}
        onPageChange={handlePageChange}
        rowHeight={isMobileView && 170}
      />
    </SidebarContentWrapper>
  )
}

export default TradeHistoryTab
