import React, { useEffect, useState } from 'react'

// react router
import { useNavigate, useLocation } from 'react-router-dom'

// styles
import Styles from './wallet.module.css'

// icons
import { PiPiggyBankBold } from 'react-icons/pi'
import { TbCalendarDollar } from 'react-icons/tb'
import { FaHandHoldingDollar } from 'react-icons/fa6'
import { ReactComponent as TVIcon } from '../../../../assets/icons/imported/tv.svg'
import { ReactComponent as UserIcon } from '../../../../assets/icons/imported/profile.svg'
import { ReactComponent as LockIcon } from '../../../../assets/icons/imported/lock.svg'
import { ReactComponent as MenuIcon } from '../../../../assets/icons/imported/menu.svg'
import { ReactComponent as ClockIcon } from '../../../../assets/icons/imported/clock.svg'
import { ReactComponent as WalletIcon } from '../../../../assets/icons/imported/wallet.svg'
import { ReactComponent as MarketIcon } from '../../../../assets/icons/imported/market.svg'
import { ReactComponent as HomeIcon } from '../../../../assets/icons/imported/dashboard.svg'
import { ReactComponent as ConvertIcon } from '../../../../assets/icons/imported/convert.svg'
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/imported/document.svg'
import { ReactComponent as LaunchpadIcon } from '../../../../assets/icons/imported/launchpad.svg'
import { ReactComponent as BeneficiariesIcon } from '../../../../assets/icons/imported/beneficiaries.svg'

// components
import EarnWalletTab from '../../components/EarnWallet'
import StakingHistoryTab from '../../components/StakingHistory'
import WalletsOverviewTab from '../../components/WalletsOverviewTab'
import SpotWalletTab from '../../components/SpotWallet/SpotWalletTab'
import OrderHistoryTab from '../../components/OrdersHistory/OrderHistoryTab'
import TradeHistoryTab from '../../components/TradesHistory/TradeHistoryTab'
import WalletHistoryTab from '../../components/WalletHistory/WalletHistoryTab'
import SideNavButton from '../../../../components/SideNavButton/SideNavButton'
import LaunchpadHistoryTab from '../../components/LaunchpadHistory/LaunchpadHistoryTab'
import ConversionHistoryTab from '../../components/ConversionHistory/ConversionHistoryTab'

// MUI imports
import WenbitModal from '../../../../components/Modal/Modal'
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader'
import SidebarDropdown from '../../../../components/sidebar-layout-components/SidebarDropdown'

function Wallet() {
  const location = useLocation()
  const navigate = useNavigate()

  // modal state
  // const [walletDepositModalOpen, setWalletDepositModalOpen] = useState(false)
  // const [networkModalOpen, setNetworkModalOpen] = useState(false)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------

  const walletRoutes = [
    '/account/wallet/overview',
    '/account/wallet/spot/all',
    '/account/wallet/spot/available',
    '/account/wallet/spot',
    '/account/wallet/earn',
    '/account/wallet/history',
    '/account/orders/open',
    '/account/orders/history',
    '/account/trade/history',
    '/account/convert/history',
    '/account/staking/history'
  ]

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 820)

  const [currentTabTitle, setCurrentTabTitle] = useState('')
  useEffect(() => {
    switch (location.pathname) {
      case '/account/wallet/overview':
        setCurrentTabTitle('Overview')
        break
      case '/account/wallet/spot/all':
        setCurrentTabTitle('Spot Wallet')
        break
      case '/account/wallet/spot/available':
        setCurrentTabTitle('Spot Wallet')
        break
      case '/account/wallet/earn':
        setCurrentTabTitle('Earn Wallet')
        break
      case '/account/wallet/history':
        setCurrentTabTitle('Wallet History')
        break
      case '/account/orders/open':
        setCurrentTabTitle('Order History')
        break
      case '/account/orders/history':
        setCurrentTabTitle('Order History')
        break
      case '/account/trade/history':
        setCurrentTabTitle('Trade History')
        break
      case '/account/convert/history':
        setCurrentTabTitle('Conversion History')
        break
      case '/account/staking/history':
        setCurrentTabTitle('Staking History')
        break
    }
  }, [location])

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 820)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (['/account/wallet', '/account/wallet/'].includes(location.pathname) && !isMobileView) {
      navigate('/account/wallet/spot/all', { replace: true })
    }
  }, [isMobileView])
  // ------------------------------------
  // ------------------------------------

  return (
    <>
      {isMobileView && !walletRoutes.includes(location.pathname) && (
        <h1 className={Styles.mobilePageTitle}>My account</h1>
      )}

      <div className={Styles.pageContainer}>
        {/* side nav container */}
        <div
          className={`${Styles.sideNavContainer} ${Styles.card}`}
          style={{
            // display: ((isMobileView && !profileRoutes.includes(location.pathname)) || (isMobileView && location.pathname === '/account')) && 'none'
            display: isMobileView && walletRoutes.includes(location.pathname) && 'none'
          }}
        >
          <SideNavButton
            text='Overview'
            icon={<HomeIcon width={18} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname.includes('/account/wallet/overview')}
            onClick={() => navigate('/account/wallet/overview')}
          />
          <SidebarDropdown
            icon={<WalletIcon />}
            label='Assets'
            dropdownButtons={[
              {
                icon: <WalletIcon />,
                label: 'Spot Wallet',
                href: '/account/wallet/spot/all'
              },
              {
                icon: <PiPiggyBankBold size={22} />,
                label: 'Earn Wallet',
                href: '/account/wallet/earn'
              }
            ]}
            dropdownHeight='80px'
          />

          <SidebarDropdown
            icon={<ClockIcon width={20} />}
            label='History'
            dropdownButtons={[
              {
                icon: <WalletIcon />,
                label: 'Wallet History',
                href: '/account/wallet/history'
              },
              {
                icon: <MenuIcon />,
                label: 'Order History',
                href: '/account/orders/open'
              },
              {
                icon: <MarketIcon />,
                label: 'Trade History',
                href: '/account/trade/history'
              },
              {
                icon: <ConvertIcon />,
                label: 'Conversion History',
                href: '/account/convert/history'
              },
              {
                icon: <TbCalendarDollar size={21} />,
                label: 'Staking History',
                href: '/account/staking/history'
              }
            ]}
            dropdownHeight='250px'
          />

          <SidebarDropdown
            icon={<UserIcon width={20} />}
            label='My Account'
            dropdownButtons={[
              {
                icon: <UserIcon width={12} />,
                label: 'My Profile',
                href: '/account/profile'
              },
              {
                icon: <BeneficiariesIcon style={{ marginLeft: -7, marginRight: -11.5 }} width={27} />,
                label: 'Beneficiaries',
                href: '/account/beneficiaries'
              },

              {
                icon: <LockIcon width={12} />,
                label: 'Security',
                href: '/account/security'
              },
              {
                icon: <TVIcon width={14} />,
                label: 'Account Activities',
                href: '/account/activities'
              },
              {
                icon: <DocumentIcon width={12} />,
                label: 'KYC Verification',
                href: '/account/kyc'
              }
            ]}
            dropdownHeight='180px'
          />

          {/* <SideNavButton
            text='Spot wallet'
            icon={<WalletIcon width={18} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname.includes('/account/wallet/spot')}
            onClick={() => navigate('/account/wallet/spot/all')}
          />
          <SideNavButton
            text='Earn wallet'
            icon={<PiPiggyBankBold size={18} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname.includes('/account/wallet/earn')}
            onClick={() => navigate('/account/wallet/earn')}
          />
          <SideNavButton
            text='Wallet history'
            icon={<WalletIcon width={18} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname === '/account/wallet/history'}
            onClick={() => navigate('/account/wallet/history')}
          />
          <SideNavButton
            text='Order history'
            icon={<MenuIcon width={20} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname.includes('/account/orders')}
            onClick={() => navigate('/account/orders/open')}
          />
          <SideNavButton
            text='Trade history'
            icon={<MarketIcon width={17} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname === '/account/trade/history'}
            onClick={() => navigate('/account/trade/history')}
          />
          <SideNavButton
            text='Conversion history'
            icon={<ConvertIcon width={17} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname === '/account/convert/history'}
            onClick={() => navigate('/account/convert/history')}
          />
          <SideNavButton
            text='Staking History'
            icon={<TbCalendarDollar size={21} />}
            style={{ justifyContent: 'flex-start', width: '100%' }}
            isSelected={location.pathname === '/account/staking/history'}
            onClick={() => navigate('/account/staking/history')}
          /> */}
          {/* <SideNavButton
                text='Launchpad History'
                icon={<LaunchpadIcon width={17}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/launchpad'}
                onClick={() => navigate('/account/launchpad')}
            /> */}
        </div>

        {/* main container */}
        <div
          className={Styles.mainContainer}
          style={{
            display: isMobileView && !walletRoutes.includes(location.pathname) && 'none'
          }}
        >
          {/* {console.log(location.pathname)} */}
          {isMobileView && <GoBackHeader title={currentTabTitle} onBackClick={() => navigate('/account/wallet')} />}

          {location.pathname.includes('/account/wallet/overview') && <WalletsOverviewTab />}
          {location.pathname.includes('/account/wallet/spot') && <SpotWalletTab />}
          {location.pathname.includes('/account/wallet/earn') && <EarnWalletTab />}

          {location.pathname.includes('/account/orders') && <OrderHistoryTab />}

          {location.pathname === '/account/wallet/history' && <WalletHistoryTab />}
          {location.pathname === '/account/convert/history' && <ConversionHistoryTab />}
          {/* {
                location.pathname === '/account/launchpad' && (
                    <LaunchpadHistoryTab />
                )
            } */}
          {location.pathname === '/account/trade/history' && <TradeHistoryTab />}
          {location.pathname === '/account/staking/history' && <StakingHistoryTab />}
        </div>
      </div>
    </>
  )
}

export default Wallet
