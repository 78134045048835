import React from "react"

// styles
import Styles from "./aboutUs.module.css"

// redux
import { useSelector } from "react-redux"

// assets
import TargetPicture from "./assets/target.png"
import ScopePicture from "./assets/scope.png"

// components
import WenbitLogo from "../../components/icon-components/WenbitLogo/WenbitLogo"

function AboutUs() {
  const theme = useSelector((state) => state.theme.value)
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: About`

  return (
    <div className={Styles.pageContainer}>
      {/* WENBIT LOGO */}
      <div className={Styles.headerContainer}>
        <WenbitLogo className={Styles.wenbitLogo} size={500} />
        <p>
          A new crypto exchange in the market is set to disrupt the industry.
          WenBit may not be a familiar name to you yet, but we are no novices in
          this field. We emerged after a major transformation to create an
          exchange everyone could use, understand, and thrive on.
        </p>
      </div>
      <div className={Styles.card}>
        <div>
          <h2>Our mission</h2>
          <p>
            We get it - the world of crypto can be a bit daunting. But here at
            WenBit, we believe everything is made out of bits - and we can only
            make a whole. That's why we consulted with crypto experts, and IT
            wizards to build an easy-to-use, rock-solid secure exchange
          </p>
        </div>
        <img src={TargetPicture} />
      </div>
      <div className={Styles.card}>
        <div>
          <h2>Our mission</h2>
          <p>
            Our vision Our vision has attracted many talented individuals to
            join us, and we have built an exchange ready to challenge the
            existing norms and achieve greatness.
            <br />
            <br />
            So, if you are ready to join this exciting journey, come aboard
            WenBit and let's create some remarkable bits together.
          </p>
        </div>
        <img src={ScopePicture} />
      </div>
    </div>
  )
}

export default AboutUs
