import { createSlice } from '@reduxjs/toolkit'

export const FAQCategoriesSlice = createSlice({
  name: 'FAQCategories',
  initialState: {
    value: []
  },
  reducers: {
    setFAQCategories: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setFAQCategories } = FAQCategoriesSlice.actions

export default FAQCategoriesSlice.reducer