// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Icons Imports
import { PiPiggyBank } from 'react-icons/pi'
import { ReactComponent as WalletIcon } from '../../../../../../../../assets/icons/imported/wallet.svg'

// ** MUI Imports
import { Box } from '@mui/material'

// ** API Imports
import { _getWalletView } from '../../../../../../redux/actions'

// ** Component Imports
import AmountDisplay from '../../../../../../../../components/AmountDisplay'
import TableButton from '../../../../../../../../components/TableButton/TableButton'
import MUIDatagrid from '../../../../../../../../components/MUIDatagrid/MUIDatagrid'

function WalletView() {
  // ** States
  const [wallets, setWallets] = useState([])
  const [isWalletsLoading, setIsWalletsLoading] = useState(false)

  // ** Handlers
  const fetchWallets = () => {
    setIsWalletsLoading(true)
    _getWalletView(
      res => {
        setIsWalletsLoading(false)
        setWallets(res.data)
      },
      err => {
        setIsWalletsLoading(false)
        console.error(`Error while fetching wallets: ${err}`)
      }
    )
  }

  useEffect(() => {
    fetchWallets()
  }, [])

  // ** Table Columns
  const columns = [
    {
      minWidth: 110,
      flex: 1,
      field: 'wallet',
      headerName: 'Wallet',
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '9px' }}>
          {row.wallet_type === 'spot' ? <WalletIcon width={16} /> : <PiPiggyBank size={18} />}
          <span>{row.wallet_type === 'spot' ? 'Spot Wallet' : 'Earn Wallet'}</span>
        </Box>
      )
    },
    {
      minWidth: 120,
      flex: 1,
      field: 'amount',
      headerName: 'Amount(USDT)',
      renderCell: ({ row }) => <AmountDisplay amount={row.total_balance_usdt} coinId='usdt' />
    },
    {
      minWidth: 120,
      flex: 1,
      field: 'ratio',
      headerName: 'Ratio',
      renderCell: ({ row }) => <>{row.ratio.toFixed(6)}%</>
    },
    {
      minWidth: 120,
      maxWidth: 135,
      flex: 1,
      // field: 'id',
      headerName: 'Action',
      renderCell: ({ row }) => (
        <TableButton href={`/account/wallet/${row.type === 'spot' ? 'spot/all' : 'earn'}`} buttonText='View' />
      )
    }
  ]

  return (
    <MUIDatagrid
      disableSorting
      page={1}
      totalRowsCount={15}
      rows={wallets}
      loading={isWalletsLoading}
      getRowId={row => row.wallet_type}
      columns={columns}
      hideFooter
    />
  )
}

export default WalletView
