// ** React Imports
import React, { useState, useEffect } from 'react'

// ** React Router Imports
import { useNavigate, useParams } from 'react-router-dom'

// ** Styles Imports
import Styles from './convert.module.css'

// ** Icons Imports
import { ReactComponent as SteadyValueIcon } from '../assets/steady_value.svg'
import { ReactComponent as CountlessPairsIcon } from '../assets/countless_pairs.svg'
import { ReactComponent as LinkIcon } from '../../../assets/icons/imported/link.svg'
import { ReactComponent as InstantConvertIcon } from '../assets/instant_convert.svg'
import { ReactComponent as ConvertIcon } from '../../../assets/icons/imported/convert.svg'

// ** Assets Imports
import illustration from '../assets/illustration.svg'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { _getBalances } from '../../exchange/redux/actions'
import { setBalances } from '../../../redux/slices/balancesSlice'
import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen
} from '../../modals/select-currency/redux/selectCurrencyModalSlice'
import { setConfirmConvertModalData } from '../../modals/convert/redux/confirmConvertModalSlice'
//other
import { estimateUnitValue } from '../../helpers/estimateValueBase'
import { _getCoinKline, _getCurrencies, _previewConversion } from '../redux/actions'

// ** Third Party Imports
import { toast } from 'react-toastify'
import { FormattedMessage, injectIntl } from 'react-intl'

// ** Util Imports
import { showErrorSnackbar } from '../../snackbar/helpers'
import { checkCoinID } from '../../../utility/checkCoinID'

// ** Content Imports
import { faq } from '../faq'

// ** Component Imports
import Alert from '../../../components/Alert/Alert'
import Button from '../../../components/Button/Button'
import ConvertFeature from '../Components/ConvertFeature'
import Accordions from '../../../components/Accordions/Accordions'
import TableButton from '../../../components/TableButton/TableButton'
import ConfirmConvertModal from '../../modals/convert/ConfirmConvertModal'
import SuccessConvertModal from '../../modals/convert/SuccessConvertModal'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import MarketGraphCard from '../../../components/MarketGraphCard/MarketGraphCard'
import ExchangeRate from '../../../components/ExchangeRate'
import { _getFAQCategories, _getFAQQuestions } from '../../FAQs/redux/actions'
import { setFAQCategories } from '../../FAQs/redux/slices/FAQCategoriesSlice'
import { setFAQQuestions } from '../../FAQs/redux/slices/FAQQuestionsSlice'

function Convert() {
  // ** Content
  const convertFeaturesContent = [
    {
      icon: <InstantConvertIcon />,
      title: 'Instant Convert'
    },
    {
      icon: <CountlessPairsIcon />,
      title: 'Countless Pairs'
    },
    {
      icon: <SteadyValueIcon />,
      title: 'Steady Value'
    }
  ]

  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Convert`

  // ** Route Params
  const { marketId } = useParams()

  // ** Store States
  const theme = useSelector(state => state.theme.value)
  const markets = useSelector(state => state.marketList.value)
  const isLoggedIn = useSelector(state => state.loggedIn.value)
  const selectedMarket = useSelector(state => state.selectedMarket.value)

  // ** States
  const [fromCurrency, setFromCurrency] = useState(
    marketId
      ? marketId.split('_')[0]?.toLowerCase()
      : markets.find(el => el.id === marketId)?.base_unit && markets.find(el => el.id === selectedMarket.id)?.quote_unit
  )
  const [fromValue, setFromValue] = useState('')
  const [fromKlineArray, setFromKlineArray] = useState([])
  const [questions, setQuestions] = useState([])

  const [toCurrency, setToCurrency] = useState(
    marketId
      ? marketId.split('_')[1]?.toLowerCase()
      : markets.find(el => el.id === marketId)?.quote_unit && markets.find(el => el.id === selectedMarket.id)?.base_unit
  )
  const [toValue, setToValue] = useState('')
  const [toKlineArray, setToKlineArray] = useState([])

  const [currencies, setCurrencies] = useState('')
  const marketTickers = useSelector(state => state.marketTickers.value)
  const balances = useSelector(state => state.balances.value)
  const userData = useSelector(state => state.userData?.value)
  const tickers = useSelector(state => state.marketTickers.value)
  const categories = useSelector(state => state.FAQCategories.value)

  const [calculatedBaseCurrency, setCalculatedBaseCurrency] = useState(0)
  const [calculatedQuoteCurrency, setCalculatedQuoteCurrency] = useState(0)
  const [calculatedBasePercentageChange, setCalculatedBasePercentageChange] = useState(0)
  const [calculatedQuotePercentageChange, setCalculatedQuotePercentageChange] = useState(0)
  const [confirmConvertModalOpen, setConfirmConvertModalOpen] = useState(false)
  const [successConvertModalOpen, setSuccessConvertModalOpen] = useState(
    useSelector(state => state.successConvertModalState.open)
  )

  useEffect(() => {
    // Fetch FAQ categories
    _getFAQCategories(
      data => {
        dispatch(setFAQCategories(data))
      },
      err => {
        console.log(err)
      }
    )
  }, [dispatch]) // Only dispatch dependency needed here

  useEffect(() => {
    // Fetch FAQ questions
    _getFAQQuestions(
      data => {
        dispatch(setFAQQuestions(data))
        // Map category_id to category names and filter by "Buy Crypto"
        const filteredQuestions = data.map(question => ({
          ...question,
          category: categories.find(category => category.id === question.category_id)
        })).filter(question => question.category.name === 'Convert')

        setQuestions(filteredQuestions)
      },
      err => {
        console.log(err)
      }
    )
  }, [dispatch, categories])

  useEffect(() => {
    _getCurrencies(
      data => {
        setCurrencies(data)
      },
      err => {
        // console.log(err)
      }
    )
  }, [])

  useEffect(() => {
    // reset input values on currency change
    // setFromValue("")
    // setToValue("")

    if (isLoggedIn) {
      _getBalances(
        res => {
          dispatch(setBalances(res))
        },
        () => {}
      )
    }
  }, [fromCurrency, toCurrency])

  let convertedPrice = estimateUnitValue(
    fromValue ? toCurrency : fromCurrency,
    fromValue ? fromCurrency : toCurrency,
    parseFloat(fromValue || toValue),
    currencies,
    markets,
    marketTickers
  )

  useEffect(() => {
    convertedPrice = estimateUnitValue(
      toValue ? fromCurrency : toCurrency,
      toValue ? toCurrency : fromCurrency,
      parseFloat(toValue || fromValue),
      currencies,
      markets,
      marketTickers
    )

    // if (!fromValue) {
    //   setFromValue(convertedPrice)
    // } else if (!toValue) {
    //   setToValue(convertedPrice)
    // }
  }, [fromValue, toValue, fromCurrency, toCurrency])

  const handleReverse = () => {
    // if (fromValue) {
    //   setToValue(fromValue)
    // } else {
    //   setToValue(convertedPrice)
    // }
    // if (toValue) {
    //   setFromValue(toValue)
    // } else {
    //   setFromValue(convertedPrice)
    // }
    setFromValue(toValue || convertedPrice)
    setToValue(fromValue)
    setFromCurrency(toCurrency)
    setToCurrency(fromCurrency)
  }

  const handleFromValueChange = event => {
    setFromValue(event.target.value)
    setToValue('')
  }

  const handleToValueChange = event => {
    setToValue(event.target.value)
    setFromValue('')
  }

  const handleConvert = () => {
    const fromCurr = currencies.length && currencies?.find(el => el.id === fromCurrency)
    const toCurr = currencies.length && currencies?.find(el => el.id === toCurrency)
    if (fromValue) {
      _previewConversion(
        {
          from: fromCurr.id,
          to: toCurr.id,
          volume: fromValue
        },
        data => {
          dispatch(setConfirmConvertModalData(data))
          dispatch(setConfirmConvertModalOpen(true))
        },
        error => {
          // handleClose()
          showErrorSnackbar({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error'
          })
        }
      )
    } else {
      showErrorSnackbar({ alertMessage: 'You should insert values first' })
      // dispatch(setSnackbarOpen(true))
      // dispatch(
      //   setSnackbarData({
      //     alertMessage: "You should insert values first",
      //     severity: "error",
      //     //buttonMessage: 'See order',
      //     callback: () => {},
      //     //actionType: 'link', // link - close
      //     //linkTo: '/account/orders/open',
      //     position: "topRight"
      //   })
      // )
    }
  }

  // useEffect(() => {
  //   convertedPrice = estimateUnitValue(
  //     fromValue ? toCurrency : fromCurrency,
  //     fromValue ? fromCurrency : toCurrency,
  //     parseFloat(fromValue || toValue),
  //     currencies,
  //     markets,
  //     marketTickers
  //   )
  //   if (!fromValue) {
  //     setFromValue(convertedPrice)
  //   } else if (!toValue) {
  //     setToValue(convertedPrice)
  //   }
  // }, [fromValue])

  // get the kline data of coixns to convert to display in ApexChart
  useEffect(() => {
    _getCoinKline(
      {
        pairs: [
          fromCurrency !== 'usdt' ? `${fromCurrency}usdt` : `${fromCurrency}usd`,
          toCurrency !== 'usdt' ? `${toCurrency}usdt` : `${toCurrency}usd`
        ],
        limit: 15
      },
      data => {
        setFromKlineArray(
          fromCurrency === 'usdt'
            ? data.find(el => Object.keys(el)[0] === `${fromCurrency}usd`)
            : data.find(el => Object.keys(el)[0] === `${fromCurrency}usdt`)
        )
        setToKlineArray(
          toCurrency === 'usdt'
            ? data.find(el => Object.keys(el)[0] === `${toCurrency}usd`)
            : data.find(el => Object.keys(el)[0] === `${toCurrency}usdt`)
        )
      }
    )
  }, [fromCurrency, toCurrency])

  const defaultTicker = {
    last: 0,
    vol: 0,
    price_change_percent: '+0.00%'
  }

  useEffect(() => {
    const fromMarket = markets.find(el => el.id === `${fromCurrency}${fromCurrency === 'usdt' ? 'usd' : 'usdt'}`)
    const toMarket = markets.find(el => el.id === `${toCurrency}${toCurrency === 'usdt' ? 'usd' : 'usdt'}`)

    setCalculatedBaseCurrency((tickers[fromMarket?.id] || defaultTicker).last)
    setCalculatedQuoteCurrency((tickers[toMarket?.id] || defaultTicker).last)
    setCalculatedBasePercentageChange((tickers[fromMarket?.id] || defaultTicker).price_change_percent)
    setCalculatedQuotePercentageChange((tickers[toMarket?.id] || defaultTicker).price_change_percent)
  }, [tickers])

  // if there was no market pair in url or invalid coin names
  useEffect(() => {
    // if no currency was defined
    if (
      marketId === undefined ||
      marketId === null ||
      marketId === '' ||
      !marketId.includes('_') ||
      marketId.split('_').length > 2
    ) {
      navigate('/convert/BTC_USDT')
      setFromCurrency('btc')
      setToCurrency('usdt')
      // return
    }

    // if (marketId.includes("_")) {
    //   let id1 = ""
    //   let id2 = ""
    //   // check if id in URL is valid
    //   console.log(marketId.split("_")[0])

    //   checkCoinID(marketId.split("_")[0]).then((isValid) => {
    //     console.log('1', markets)
    //     if (isValid) {
    //       id1 = markets[0]?.base_unit
    //     } else {
    //       id1 = "BTC"
    //     }
    //     checkCoinID(marketId.split("_")[1]).then((isValid) => {
    //       console.log('2', markets[1])
    //       if (isValid) {
    //         id2 = markets[1]?.quote_unit
    //       } else {
    //         id2 = "USDT"
    //       }
    //       history.replace(`/convert/${id1.toUpperCase()}_${id2.toUpperCase()}`)
    //       setFromCurrency("btc")
    //       setToCurrency("usdt")
    //     })
    //   })
    // }
  }, [])

  const redirectToConvertHistory = id => {
    navigate(`/account/convert/history`)
  }

  // Hide body overflow-x
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflowX = 'hidden'

    return () => {
      document.getElementsByTagName('body')[0].style.overflowX = 'auto'
    }
  }, [])

  return (
    <div className={Styles.pageContainer}>
      {/* Convert Background Illustration */}
      <div className={Styles.illustrationContainer}>
        <img
          src={illustration}
          style={{ filter: `opacity(${theme === 'dark' ? '0.275' : '0.1'})` }}
          className={Styles.convertIlustrationContainer}
        />
      </div>

      <div>
        <div className={Styles.conversionContainer}>
          <h2>WenBit Convert</h2>
          <TableButton
            onClick={redirectToConvertHistory}
            buttonText='Conversion History'
            style={{
              marginTop: -20
              // backgroundColor: 'var(--secondary-color)'
            }}
            buttonIcon={
              <LinkIcon
                style={{
                  width: 12
                }}
              />
            }
          />

          {/* from container */}
          <div className={Styles.inputContainer}>
            {
              // userData?.email &&
              // ([null, 0.0, undefined, '0.0', 0].includes(balances?.find(el => el.currency === fromCurrency)?.balance)) && (
              //   <Alert
              //   alertText={`Buy ${currencies && currencies?.find(el => el.id === fromCurrency?.toLowerCase())?.name} with credit card`}
              //   showButton={true}
              //   buttonText='Buy'
              //   />
              // )
            }

            <CurrencyInput
              title='From'
              containerStyle={{
                backgroundColor: 'var(--secondary-color)',
                border: 'none',
                margin: '5px 0 10px 0',
                padding: 0
              }}
              coinName={fromCurrency?.toUpperCase()}
              currencyIcon=''
              currencyImg={currencies ? currencies.find(el => el.id === fromCurrency?.toLowerCase())?.icon_url : ''}
              secondaryTitle={
                userData.email && `Balance: ${balances?.find(el => el.currency === fromCurrency)?.balance ?? '0.00'}`
              }
              type='number'
              value={fromValue || convertedPrice ? fromValue || convertedPrice : ''}
              placeholder='0.00'
              onChange={handleFromValueChange}
              showDropdownArrow={true}
              onDropdownClick={() => {
                dispatch(
                  setSelectCurrencyModalData({
                    title: 'Select Currency',
                    searchPlaceholder: 'Search coin name',
                    showTabs: false,
                    type: 'coin',
                    currenciesToHide: [toCurrency],
                    callback: coin => {
                      setFromCurrency(coin.id)
                      navigate(`/convert/${coin.id.toUpperCase()}_${toCurrency.toUpperCase()}`)
                    }
                  })
                )
                dispatch(setSelectCurrencyModalOpen(true))
              }}
            />

            <MarketGraphCard
              icon={currencies && currencies?.find(el => el.id === fromCurrency?.toLowerCase())?.icon_url}
              coinName={currencies && currencies?.find(el => el.id === fromCurrency?.toLowerCase())?.name}
              price={calculatedBaseCurrency}
              priceChangePercentage={calculatedBasePercentageChange}
              containerStyle={{
                width: '100%'
              }}
              marketKLine={fromKlineArray}
            />
          </div>

          {/* CONVERT ICON */}
          <ConvertIcon color='var(--primary-color)' onClick={handleReverse} style={{ transform: 'scale(1.37)' }} />

          {/* to container */}
          <div className={Styles.inputContainer} style={{ paddingBottom: '5px' }}>
            <CurrencyInput
              title='To'
              containerStyle={{
                backgroundColor: 'var(--secondary-color)',
                border: 'none',
                margin: '5px 0 10px 0',
                padding: 0
              }}
              secondaryTitle={
                userData.email && `Balance: ${balances?.find(el => el.currency === toCurrency)?.balance ?? '0.00'}`
              }
              currencyIcon=''
              currencyImg={currencies ? currencies?.find(el => el.id === toCurrency?.toLowerCase())?.icon_url : ''}
              coinName={toCurrency?.toUpperCase()}
              type='number'
              value={toValue || convertedPrice ? toValue || convertedPrice : ''}
              placeholder='0.00'
              onChange={handleToValueChange}
              showDropdownArrow={true}
              onDropdownClick={() => {
                dispatch(
                  setSelectCurrencyModalData({
                    title: 'Select Currency',
                    showTabs: false,
                    searchPlaceholder: 'Search coin name',
                    type: 'coin',
                    currenciesToHide: [fromCurrency],
                    callback: coin => {
                      setToCurrency(coin.id)
                      navigate(`/convert/${fromCurrency.toUpperCase()}_${coin.id.toUpperCase()}`)
                    }
                  })
                )
                dispatch(setSelectCurrencyModalOpen(true))
              }}
            />

            <MarketGraphCard
              icon={currencies && currencies?.find(el => el.id === toCurrency?.toLowerCase())?.icon_url}
              coinName={currencies && currencies?.find(el => el.id === toCurrency?.toLowerCase())?.name}
              containerStyle={{
                width: '100%'
              }}
              price={calculatedQuoteCurrency}
              priceChangePercentage={calculatedQuotePercentageChange}
              marketKLine={toKlineArray}
            />

            <span className={Styles.convertUSDTIndicator}>
              The graph and price depicts the correlation between the coin and USDT
            </span>
          </div>

          {/* Exchange Rate */}
          <div>
            <ExchangeRate startText='Exchange rate: ' fromCurrencyId={fromCurrency} toCurrencyId={toCurrency} />
          </div>

          <Button
            type={!userData.email && 'loggedOut'}
            style={
              !userData.email && {
                backgroundColor: 'var(--secondary-color)',
                width: '100%'
              }
            }
            text='Preview Conversion'
            onClick={() => {
              handleConvert()
            }}
          />
        </div>

        {/* Features */}
        <div className={Styles.iconsContainer}>
          {convertFeaturesContent.map(el => (
            <ConvertFeature key={el.title} title={el.title} icon={el.icon} />
          ))}
        </div>

        {/* FAQ */}
        <SectionHeader title='FAQ' />
        <Accordions
          containerStyle={{
            maxWidth: 700,
            margin: '0 auto',
            padding: '0 20px'
          }}
          accordions={questions}
        />
      </div>

      {/* -------------- MODALS-------------- */}
      {confirmConvertModalOpen ? (
        <ConfirmConvertModal
          open={confirmConvertModalOpen}
          fromValue={fromValue}
          toValue={toValue}
          currencies={currencies}
          markets={markets}
          marketTickers={marketTickers}
          handleClose={() => setConfirmConvertModalOpen(false)}
          fromCurrency={currencies.length && currencies?.find(el => el.id === fromCurrency)}
          toCurrency={currencies.length && currencies?.find(el => el.id === toCurrency)}
        />
      ) : null}
      {successConvertModalOpen ? (
        <SuccessConvertModal
          open={successConvertModalOpen}
          fromValue={fromValue}
          toValue={toValue}
          currencies={currencies}
          markets={markets}
          marketTickers={marketTickers}
          handleClose={() => setSuccessConvertModalOpen(false)}
          fromCurrency={currencies.length && currencies?.find(el => el.id === fromCurrency)}
          toCurrency={currencies.length && currencies?.find(el => el.id === toCurrency)}
        />
      ) : null}
    </div>
  )
}

export default injectIntl(Convert)
