// ** React Imports
import React, { useEffect, useState } from 'react'

// ** API Imports
import { _getPublicStakingProducts } from './redux/actions'

// ** Component Imports
import FAQ from './components/FAQ'
import PageHeader from './components/pageHeader'
import Button from '../../components/Button/Button'
import StakingTable from './components/StakingTable'
import StakingTableFilters from './components/StakingTableFilters'
import { useSelector } from 'react-redux'

function Staking() {
  // ** Tab Title
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Staking`

  // ** Pagination/Filtering Params
  const [paginationParams, setPaginationParams] = useState({ page: 1, limit: 10 })
  const [filteringParams, setFilteringParams] = useState({
    searchQuery: null,
    match_asset: null,
    duration: null
  })

  // ** States
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [canFetchMore, setCanFetchMore] = useState(true)

  // ** Handlers
  const fetchStakingProducts = (page, limit, searchQuery, matchAsset, duration) => {
    setLoading(true)
    _getPublicStakingProducts(
      { page, limit, searchQuery, matchAsset, duration },
      res => {
        // Add the selected duration property to be 0 by default
        const dataArray = res.data.map(item => {
          const sortedPeriodList = item.period_summary_list.slice().sort((a, b) => a.duration - b.duration)
          return {
            ...item,
            period_summary_list: sortedPeriodList,
            selectedPeriod: sortedPeriodList[sortedPeriodList.length - 1] // Assuming you still want the first item as selectedPeriod
          }
        })

        setRows(dataArray)
        setLoading(false)

        // If no more data can be fetched
        if (res.data.length < paginationParams.limit) {
          setCanFetchMore(false)
        }
      },
      error => setLoading(false)
    )
  }

  const handleViewMore = () => {
    setPaginationParams(prevParams => {
      return {
        ...prevParams,
        page: prevParams.page + 1
      }
    })
  }

  useEffect(() => {
    // Fetch the products data on pagination of filter change
    fetchStakingProducts(
      paginationParams.page,
      paginationParams.limit,
      filteringParams.searchQuery,
      filteringParams.match_asset,
      filteringParams.duration
    )
  }, [paginationParams, filteringParams])

  return (
    <div className='pageContainer'>
      <PageHeader />

      <StakingTableFilters filteringParams={filteringParams} setFilteringParams={setFilteringParams} />

      <StakingTable rows={rows} setRows={setRows} loading={loading} />
      {canFetchMore && (
        <Button text='View more' variant='link' style={{ margin: '20px auto' }} onClick={handleViewMore} />
      )}

      <FAQ />
    </div>
  )
}

export default Staking
