import { API, BINANCE_API } from "../../../../utility/API"
import axios from "axios"
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"

//************************************//
export const _getMarketsList = (callback, callbackErr) => {
  API.get(`trading/public/markets?page=1`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getMarketKline = (by_binance, market, resolution, from, to, callback, callbackErr) => {
  // console.log(resolution)
  if (by_binance) {
    if (resolution && !resolution?.includes('D')) {
      resolution += 'm'
    } else if (resolution && resolution?.includes('D')) {
      resolution = resolution.replace('D', 'd')
    }
    if (resolution === '60m') {
      resolution = '1h'
    } else if (resolution === '120m') {
      resolution = '2h'
    } else if (resolution === '240m') {
      resolution = '4h'
    } else if (resolution === '360m') {
      resolution = '6h'
    } else if (resolution === '720m') {
      resolution = '12h'
    }
    // API.get(`trading/public/markets/${market}/k-line?period=${resolution}&time_from=${from}&time_to=${to}`)
    BINANCE_API.get(`klines?symbol=${market.toUpperCase()}&interval=${resolution}&limit=1000`)
      .then(function ({ data }) {
        callback(data)
      })
      .catch(function (error) {
        callbackErr(error)
      })
  } else {
    API.get(`trading/public/markets/${market}/k-line?period=${resolution}&time_from=${from}&time_to=${to}`)
      .then(function ({ data }) {
        callback(data)
      })
      .catch(function (error) {
        callbackErr(error)
      })
  }

}
//************************************//
export const _getMarketTickers = (callback) => {
  API.get(`trading/public/markets/tickers`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) { })
}
//************************************//
export const _getMarketTrades = (id, callback) => {
  API.get(`trading/public/markets/${id}/trades`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) { })
}
//************************************//
export const _getMarketDepth = (id, limit, callback) => {
  API.get(`trading/public/markets/depth?market=${id}&limit=${limit}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) { })
}
//************************************//
export const _getCurrencies = (callback, data) => {
  API.get(`/trading/public/currencies`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) { })
}
//************************************//
export const _getFiatCurrencies = (callback) => {
  API.get(`/trading/public/fiat_currencies`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) { })
}
//************************************//
export const _addOrder = (data, callback, callbackErr) => {
  API.post(`trading/market/orders`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _cancelOrder = (orderId, callback, callbackErr) => {
  API.post(`trading/market/orders/${orderId}/cancel`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getBalances = (callback, callbackErr) => {
  API.get(`trading/account/balances`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getOpenOrders = (callback, callbackErr) => {
  API.get("trading/market/orders?limit=25&page=1&state=wait")
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getOrderHistory = (callback, callbackErr) => {
  API.get(
    "trading/market/orders?limit=25&page=1&state[]=done&state[]=reject&state[]=cancel"
  )
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
