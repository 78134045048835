import { createSlice } from '@reduxjs/toolkit'

const stakingSubscribeModalSlice = createSlice({
  name: 'stakingSubscribeModalState',
  initialState: {
    open: false,
    data: null
  },
  reducers: {
    setStakingSubscribeModalOpen: (state, action) => {
      state.open = action.payload
    },
    setStakingSubscribeModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setStakingSubscribeModalOpen, setStakingSubscribeModalData } = stakingSubscribeModalSlice.actions

export default stakingSubscribeModalSlice.reducer
