// ** React Imports
import React, { useCallback, useEffect, useState } from "react"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** Redux Imports
import { useSelector, useDispatch } from "react-redux"
import { _getActiveBeneficiaries } from "../../../../../profile/redux/actions"
import { _withdraw } from "../../../../security/Enable2FAModal/redux/actions"
import {
  _getNameFromUID,
  _requestInternalTransfer,
  _resendEmailOTP,
  _resendPhoneOTP,
  _verifyInternalTransferOTP
} from "../../redux/actions"

// ** Form Validation Imports
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** Component Imports
import Input from "../../../../../../components/Input/Input"
import Button from "../../../../../../components/Button/Button"

// ** Helpers
import { debounce } from "@mui/material"
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../../../snackbar/helpers"
import ShimmerLine from "../../../../../../components/shimmer-components/ShimmerLine"
import {
  hideVerificationCodesInputModal,
  showVerificationCodesInputModal
} from "../../../../auth/VerificationCodesInput/helpers"
import { closeWithdrawFormModal } from "../../helpers"
import { showSuccessModal } from "../../../../success/helpers"

// ** Form Schema
const withdrawSchema = Yup.object().shape({
  amount: Yup.number("Amount must be a number")
    .typeError("Amount must be a number")
    .required("Amount is required"),
  user_id: Yup.string()
    .required("UID is required")
    .test("is-not-own-uid", "You cannot use your own UID", function (value) {
      return value !== this.options.context.uid
    })
})

function WenbitUserTab() {
  // ** States
  const balances = useSelector((state) => state.balances.value)
  const currencies = useSelector((state) => state.currencies.value)
  const modalData = useSelector((state) => state.withdrawFormModalState.data)
  const userData = useSelector((state) => state.userData.value)
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [uid, setUid] = useState(null)
  const [uidName, setUidName] = useState("")
  const [uidNameLoading, setUidNameLoading] = useState("")

  // ** Hooks
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError
  } = useForm({
    resolver: yupResolver(withdrawSchema),
    mode: "onSubmit",
    context: { uid: userData.uid }
  })

  // ** Handle max amount button click
  const handleMaxAmount = () => {
    setAmount(
      balances.find((el) => el.currency === modalData?.selectedCoinID)
        ?.balance ?? 0
    )
    setValue(
      "amount",
      balances.find((el) => el.currency === modalData?.selectedCoinID)
        ?.balance ?? 0
    )
    trigger("amount")
  }

  // ** Get UID name
  const getUidName = useCallback(
    debounce(() => {
      const uid = getValues("user_id")
      if (!uid) {
        return
      } else if (uid === userData.uid) {
        setUid("")
        setUidName("")
        return
      }
      setUidNameLoading(true)
      setUidName("")
      _getNameFromUID(
        {
          uid
        },
        (data) => {
          setUidName(data)
          setUidNameLoading(false)
        },
        (err) => {
          if (err.errors[0] === "wenbit.user.doesnt_exist") {
            setError("user_id", { message: "User not found" })
            setUid("")
          }
          setUidNameLoading(false)
        }
      )
    }, 1000),
    []
  )

  const resendEmailCode = () => {
    // Send as a promise to be able to set the timer on successful response
    return new Promise((resolve, reject) => {
      _resendEmailOTP(
        {},
        (data) => {
          showSuccessSnackbar({ alertMessage: "Email code sent" })
          resolve(data)
        },
        (err) => {
          showErrorSnackbar({ alertMessage: "Something went wrong" })
          reject(err)
        }
      )
    })
  }

  const resendSMSCode = () => {
    // Send as a promise to be able to set the timer on successful response
    return new Promise((resolve, reject) => {
      _resendPhoneOTP(
        {},
        (data) => {
          showSuccessSnackbar({ alertMessage: "SMS code sent" })
          resolve(data)
        },
        (err) => {
          showErrorSnackbar({ alertMessage: "Something went wrong" })
          reject(err)
        }
      )
    })
  }

  const handleCodeInputSubmit = (data) => {
    _verifyInternalTransferOTP(
      {
        phone_otp: data.smsCode,
        email_otp: data.emailCode,
        otp: data.otpCode
      },
      (data) => {
        showSuccessModal({
          title: "Transfer Successful",
          subtitle: `${data?.amount} ${data?.currency} has been transfered to user with the following UID: ${data?.receiver_uid}`
        })
        hideVerificationCodesInputModal()
        closeWithdrawFormModal()
      },
      (err) => {
        showErrorSnackbar({
          alertMessage: "Error while doing an internal transfer"
        })
        // hideVerificationCodesInputModal()
        // closeWithdrawFormModal()
      }
    )
  }

  // ** Submit function
  const onSumbit = (formData) => {
    setLoading(true)
    _requestInternalTransfer(
      {
        currency: modalData?.selectedCoinID,
        amount: formData.amount,
        uid
      },
      (data) => {
        showSuccessSnackbar({ alertMessage: data.message })
        setLoading(false)
        showVerificationCodesInputModal({
          title: "Withdraw Verification",
          sms: true,
          email: true,
          otp: true,
          initialEmailTimer: 60,
          initialSMSTimer: 60,
          handleEmailResendCode: resendEmailCode,
          handleSMSResendCode: resendSMSCode,
          callback: handleCodeInputSubmit
        })
      },
      (err) => {
        setLoading(false)
        showErrorSnackbar({ alertMessage: "Error while rquestion withdraw" })
      }
    )
  }

  return (
    <form
      style={{
        marginTop: 20,
        paddingTop: 20
      }}
      onSubmit={handleSubmit(onSumbit)}
    >
      {/* Wenbit User UID */}
      <Input
        inputLabel="User UID"
        showBorder
        placeholder="Enter User UID"
        value={uid}
        error={errors.user_id}
        // {...register("user_id")}
        onChange={(e) => {
          getUidName()
          setUid(e.target.value)
          setValue("user_id", e.target.value)
          trigger("user_id")
        }}
        indicatorLabel={
          uidNameLoading ? <ShimmerLine width="100px" /> : uidName
        }
      />

      {/* Amount */}
      <Input
        inputLabel="Amount"
        showBorder
        defaultValue={amount}
        value={amount}
        placeholder="Enter Desired Amount"
        error={errors.amount}
        // {...register("amount")}
        icon={
          <div className={Styles.userIdInputLabel}>
            <Button
              text="MAX"
              variant="link"
              onClick={handleMaxAmount}
              style={{
                padding: 0,
                width: "fit-content"
              }}
            />
            <div className={Styles.divider}></div>
            <span className={Styles.currencyLabel}>
              {modalData?.selectedCoinID?.toUpperCase()}
            </span>
          </div>
        }
        onChange={(e) => {
          setAmount(e?.target?.value)
          setValue("amount", e?.target?.value)
          trigger("amount")
        }}
        // indicatorLabel="Transaction Fee 0.0005 BTC"
        containerStyle={{ marginBottom: 25 }}
      />

      {/* PROCEED BUTTON */}
      <Button
        type="submit"
        isLoading={loading}
        text="Proceed withdrawal"
        style={{ marginBottom: 20 }}
      />

      {/* DISCLAIMER */}
      <div className={Styles.disclaimer}>Disclaimer</div>
      <p className={Styles.disclaimerText}>
        Please cross-check the destination address. Withdrawals to Smart
        Contract Addresses, payments or participation in ICOs/Airdrops are not
        supported and will be lost forever. Withdrawal requests cannot be
        cancelled after submission.
      </p>
    </form>
  )
}

export default WenbitUserTab
