import React, { useEffect, useState, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Styles from "./marketsList.module.css"
import { FiSearch } from "react-icons/fi"
import { ReactComponent as InfoIcon } from "../../../../assets/icons/imported/info.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  _getCurrencies,
  _getMarketsList,
  _getMarketTickers
} from "../../redux/actions"
import { setSelectedMarket } from "../../redux/slices/selectedMarketSlice"
import { setMarketTickers } from "../../redux/slices/marketTickersSlice"
import { setMarketsList } from "../../redux/slices/marketListSlice"
import { clearOrderBook } from "../../redux/slices/orderBookSlice"
import { clearTrades } from "../../redux/slices/tradesSlice"
import { setPreviousMarket } from "../../redux/slices/previousMarketSlice"
import { setCurrencies } from "../../redux/slices/currenciesSlice"
import { FormattedMessage, injectIntl } from "react-intl"
import MarketRow from "./MarketRow"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"
import Search from "../../../../components/Search/Search"
import ShimmerCirlce from "../../../../components/shimmer-components/ShimmerCircle"

function MarketsList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState("")
  const marketsList = useSelector((state) => state.marketList?.value)
  const tickers = useSelector((state) => state.marketTickers.value)
  const currencies = useSelector((state) => state.currencies.value)
  const [filteredMarketList, setFilteredMarketList] = useState(marketsList)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const defaultTicker = {
    last: 0,
    vol: 0,
    price_change_percent: "+0.00%"
  }
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const { marketName } = useParams()

  useEffect(() => {
    console.log(currencies)
  }, [currencies])

  const handleSearchChange = (event) => {
    const searchInput = event.target.value
    setSearch(searchInput)
    setFilteredMarketList(
      marketsList?.filter(
        (pair) =>
          pair.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          pair.id?.toLowerCase().includes(searchInput.toLowerCase()) ||
          pair.coin_name?.toLowerCase().includes(searchInput.toLowerCase())
      )
    )
  }

  const changeCurrency = (currency) => {
    dispatch(clearOrderBook())
    dispatch(clearTrades())
    setSelectedMarket(currency)
    dispatch(setSelectedMarket(currency))
    navigate(`/trade/${currency.id}`)
  }

  useEffect(() => {
    // setSelectedMarket({
    //   id: marketsList[0]?.id,
    //   name: marketsList[0]?.name
    // })
    // dispatch(
    //   setSelectedMarket({
    //     id: marketsList[0]?.id,
    //     name: marketsList[0]?.name
    //   })
    // )
    // setSelectedMarket({
    //   id: marketName,
    //   name: marketsList?.find((market) => market.id === marketName)?.name
    // })
    dispatch(
      setSelectedMarket({
        id: marketName,
        name: marketsList?.find((market) => market.id === marketName)?.name,
        price_precision:
          marketsList?.find((market) => market.id === marketName)
            ?.price_precision || 0
      })
    )
    if (filteredMarketList?.length) {
      setLoading(false)
    }
    if (!marketName) {
      navigate(`/trade/btcusdt`)
    } else {
      navigate(`/trade/${marketName}`)
    }
    setFilteredMarketList(marketsList)
  }, [])

  useEffect(() => {
    // console.log(tickers['btcusdt']['last'])
    setFilteredMarketList(
      filteredMarketList?.map((market) => {
        return {
          ...market,
          last: (tickers[market.id] || defaultTicker).last,
          price_change_percent: (tickers[market.id] || defaultTicker)
            .price_change_percent,
          price_change_percent_num: Number.parseFloat(
            (tickers[market.id] || defaultTicker).price_change_percent
          ),
          vol: (tickers[market.id] || defaultTicker).volume
        }
      })
    )
  }, [tickers])

  return (
    <div className={Styles.card}>
      <Search
        value={search}
        placeholder="Search Coin Pair"
        onChange={handleSearchChange}
        containerStyle={{
          width: "100%"
        }}
      />

      <div className={Styles.marketPairTableHeader}>
        <span>
          <FormattedMessage
            id={"page.body.trade.header.markets.content.market"}
          />
        </span>
        <span>
          <FormattedMessage
            id={"page.body.trade.header.markets.content.last_price"}
          />
        </span>
      </div>

      <div
        className={Styles.rowsContainer}
        style={{ overflowY: !filteredMarketList?.length && "hidden" }}
      >
        {!loading && tickers ? (
          filteredMarketList?.length !== 0 ? (
            filteredMarketList
              ?.filter((pair) => pair.last > 0)
              .map((pair) => {
                return (
                  <MarketRow
                    key={pair.name}
                    iconURL={pair.icon_url}
                    pair={pair}
                    isSelected={pair.id === marketName}
                    onClick={() => {
                      dispatch(setPreviousMarket(selectedMarket.id))
                      changeCurrency({
                        id: pair.id,
                        name: pair.name,
                        quote_symbol: ""
                      })
                      if (search.length) {
                        setFilteredMarketList(marketsList)
                      }
                      setSearch("")
                    }}
                  />
                )
              })
          ) : (
            <div className={Styles.NotFoundContainer}>
              <InfoIcon />
              Pair Not Found
            </div>
          )
        ) : (
          items.map((number) => (
            <div className={Styles.loadingRowContainer} key={number}>
              <div>
                <ShimmerCirlce />
                <ShimmerLine width="30px" height="15px" />
              </div>
              <div>
                <ShimmerLine width="70px" />
                <ShimmerLine width="32px" />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default injectIntl(MarketsList)
