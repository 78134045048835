import React, { useState, useEffect, useContext } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

// react router
import { Link, useNavigate } from 'react-router-dom'

// utilities
import _, { isEmpty } from 'lodash'

// translations
import { FormattedMessage, injectIntl } from 'react-intl'

// redux
import { _login } from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from '../../redux/slices/userDataSlice'
import { setLoggedIn } from '../../../../redux/slices/loggedIn'
import { setFunds, setWithdrawals, setBtcBalance, setDeposits } from '../../../../redux/slices/fundsSlice'
import { _getFundsData } from '../../../../redux/actions'

// styles
import Styles from './loginTab.module.css'
import tabStyles from '../tab.module.css'

// components
import { Form } from 'reactstrap'
import Button from '../../../../components/Button/Button'
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Input from '../../../../components/Input/Input'
import InputCode from '../../../../components/InputCode'
import { setSnackbarOpen, setSnackbarData } from '../../../snackbar/redux/snackbarSlice'

// ** Icons Imports
import { ReactComponent as OTPIcon } from '../../../../assets/icons/imported/OTP.svg'

// form validations
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// login schema
const logInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required')
})

const LoginTab = () => {
  // ** URL Params
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = decodeURIComponent(urlParams.get('redirect_url'))

  // ** States
  const [step, setStep] = useState(1)
  const [otpCode, setOtpCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState('')
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)

  const theme = useSelector(state => state.theme.value)
  const isLoggedIn = useSelector(state => state.userData.value.email)
  const OPEN_PRIVATE_CONNECTION = 'OPEN_PRIVATE_CONNECTION'
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(logInSchema),
    mode: 'onSubmit'
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const recaptchaOnChange = value => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  useEffect(() => {
    // if (isLoggedIn) {
    //   // Redirect URL on success if any in URL param
    //   if (redirectUrl) {
    //     console.log('HERE', redirectUrl)
    //     navigate(redirectUrl)
    //   } else {
    //     console.log('HERE', redirectUrl)
    //     navigate('/trade')
    //   }
    // }
  }, [isLoggedIn, history])
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Login`
  const _loginSuccessCallback = res => {
    // console.log(res)
    if (res?.email) {
      // dispatch(setLoggedIn(true))
      dispatch(setUser(res))
      _getFundsData(
        data => {
          dispatch(setWithdrawals(data.total_withdraw))
          dispatch(setDeposits(data.total_deposit))
          dispatch(setFunds(data.total_balance))
          dispatch(setBtcBalance(data.total_balance_btc))
        },
        error => {
          // console.log(error)
        }
      )
      if (rememberMe) {
        const user = {
          email: res.email,
          csrf_token: res.csrf_token,
          remember_me: true
        }
        sessionStorage.setItem('user', JSON.stringify(user))
      } else {
        const user = {
          email: res.email,
          csrf_token: res.csrf_token
        }
        sessionStorage.setItem('user', JSON.stringify(user))
      }
      const userJson = sessionStorage.getItem('user')
      if (userJson) {
        // const user = JSON.parse(userJson)
        // // authenticate the user based on the email and CSRF token
        // // ...
        // if (user.remember_me) {
        //   // set a persistent cookie
        //   document.cookie = `_barong_session=${res.session}; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/; Secure; SameSite=Strict`
        // } else {
        //   // set a session cookie
        //   document.cookie = `_barong_session=${res.session}; path=/; Secure; SameSite=Strict`
        // }
        // remove the user data from session storage
        //sessionStorage.removeItem('user')
      } else {
        // authentication failed, remove the user data from session storage
        //sessionStorage.removeItem('user')
      }

      // Redirect URL on success if any in URL param
      if (redirectUrl !== 'null') {
        navigate(redirectUrl)
      } else {
        navigate('/trade')
      }
    }
  }

  const onSubmit = data => {
    setIsLoading(true)
    if (isEmpty(errors)) {
      _login(
        {
          email: data.email,
          password: data.password,
          captcha_response: recaptchaResponse,
          ...(step === 2 && { otp_code: otpCode })
        },
        data => {
          setIsLoading(false)
          _loginSuccessCallback(data)
          dispatch({ type: OPEN_PRIVATE_CONNECTION })
        },
        error => {
          // console.log(error)
          if (error.errors[0] === 'identity.session.missing_otp') {
            setStep(2)
          } else {
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
                severity: 'error',
                //buttonMessage: 'See order',
                callback: () => {},
                //actionType: 'link', // link - close
                //linkTo: '/account/orders/open',
                position: 'topRight'
              })
            )
          }
          setIsLoading(false)
        }
      )
    }
  }
  return (
    <div className={Styles.tabContainer}>
      {/* form */}
      {step === 1 && (
        <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>
          {/* title */}
          <span className={tabStyles.title}>
            {/* <FormattedMessage id={"page.header.signInTo{exchangeName}"} /> */}
            It's time to level up <br />& conquer greatness!
          </span>

          {/* mail input */}
          <Input id='email' inputLabel='Email' error={errors.email} {...register('email')} />

          {/* password input */}
          <Input
            type='password'
            {...register('password')}
            id='password'
            inputLabel={
              <div className={Styles.passwordLabelContainer}>
                Password
                {/* forgot password link */}
                <Link to={'/recover-password'} className={Styles.forgotPassword}>
                  <FormattedMessage id={'page.header.signIn.forgotPassword'} />
                </Link>{' '}
              </div>
            }
            error={errors.password}
          />

          {/* remember me? */}
          {/* <CheckboxInput
                label='Remember me'
                labelFor='remember-me'
                checkboxId='remember-me'
                checked={rememberMe}
                setChecked={setRememberMe}
                containerStyle={{ margin: '20px 0' }}
              /> */}

          {/* sign in button */}
          <div className='base-sign-up-form__checkbox' style={{ margin: '30px auto 0 auto' }}>
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={recaptchaOnChange} theme={theme} />
          </div>
          <Button
            text={<FormattedMessage id={'page.header.signIn'} />}
            type='submit'
            disabled={process.env.NODE_ENV === 'production' ? !recaptchaConfirmed : false} //!recaptchaConfirmed
            isLoading={isLoading}
            style={{ marginTop: 30, height: '45px' }}
          />

          {/* register link */}
          <span className={Styles.registerSpan}>
            <FormattedMessage id={'page.signIn.signupExists'} />
            <Link to='/signup'>
              <FormattedMessage id={'page.signIn.signup'} />
            </Link>
          </span>
        </Form>
      )}
      {step === 2 && (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className={`${Styles.formDiv} ${Styles.formDivOTPCode}`}
          // style={{ padding: "20% 17% 20% 28%" }}
        >
          <OTPIcon
            width={80}
            height={80}
            // color="var(--primary-color)"
            style={{ margin: '20px auto' }}
          />
          <span
            className={tabStyles.otpTitle}
            // style={{ whiteSpace: "pre-wrap" }}
          >
            Confirm OTP Code
          </span>

          <span className={tabStyles.otpDescription}>
            Let's confirm it's really you loggin in! Enter the OTP code from your Authenticator app to proceed.
          </span>

          <div className={Styles.otpInput}>
            <InputCode
              length={6}
              label='OTP Code'
              onComplete={e => {
                setOtpCode(e)
              }}
            />
          </div>
          <Button
            text={<FormattedMessage id={'page.header.signIn'} />}
            type='submit'
            isLoading={isLoading}
            style={{ marginTop: 30, height: '45px' }}
          />
        </Form>
      )}
    </div>
  )
}
export default injectIntl(LoginTab)
