// ** React Imports
import React, { useEffect, useState } from 'react'

// ** React Router Dom Imports
import { Link } from 'react-router-dom'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Utils Imports
import { formatNumber } from '../../../helpers/formatNumber'

// ** API Imports
import {
  _createStakingSubscription,
  _getBalances,
  _getStakingEstimateRewards,
  _getStakingPeriods
} from './redux/actions/index'

// ** Component Imports
import FixedSummary from './components/FixedSummary'
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import DurationButton from '../components/DurationButton'
import FlexibleSummary from './components/FlexibleSummary'
import WenbitModal from '../../../../components/Modal/Modal'
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'

// ** Utils Imports
import { Box, debounce } from '@mui/material'

// ** Helpers Imports
import { hideStakingSubscribeModal } from './helpers'
import { showErrorSnackbar, showSuccessSnackbar } from '../../../snackbar/helpers'

// ** Form Validation Imports
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { setStakingSubscribeModalData } from './redux/subscribeStatkingModalSlice'

function StakingSubscribeModal() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const modalOpen = useSelector(state => state.stakingSubscribeModalState.open)
  const modalData = useSelector(state => state.stakingSubscribeModalState.data)
  const currencies = useSelector(state => state.currencies.value)

  const [selectedPeriod, setSelectedPeriod] = useState(null)

  const [amount, setAmount] = useState(null)
  const [coinBalance, setCoinBalance] = useState(null)
  const [rewardsData, setRewardsData] = useState({
    daily_reward: null,
    duration_reward: null,
    yearly_reward: null
  })

  const [loading, setLoading] = useState(false)
  const [fetchStakingDataLoading, setFetchStakingDataLoading] = useState(false)
  const [rewardsLoading, setRewardsLoading] = useState(false)

  // ** Form Validation
  const formSchema = Yup.object().shape({
    amount: Yup.number('Amount is required')
      .typeError('Amount is required')
      .required('Amount is required')
      .min(Number(selectedPeriod?.min_amount), `Minimum amount is ${selectedPeriod?.min_amount}`)
      .max(Number(selectedPeriod?.max_amount), `Maximum amount is ${selectedPeriod?.max_amount}`),
    termsAndConditions: Yup.boolean()
      .oneOf([true], 'You must accept the terms and conditions')
      .required('You must accept the terms and conditions')
  })

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    resetField,
    reset
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit'
  })

  // ** Handlers
  const handleClose = () => {
    hideStakingSubscribeModal()
  }

  const fetchBalances = () => {
    setLoading(true)
    _getBalances(
      res => {
        setLoading(false)
        setCoinBalance(res.find(wallet => wallet.currency === modalData?.coinId) ?? null)
      },
      () => {
        handleClose()
        setLoading(false)
      }
    )
  }

  const fetchSubscriptionData = () => {
    setFetchStakingDataLoading(true)
    _getStakingPeriods(
      { currency_id: modalData?.coinId },
      data => {
        setFetchStakingDataLoading(false)
        dispatch(setStakingSubscribeModalData({ ...modalData, ...data[0] }))
        setSelectedPeriod(data[0].period_summary_list[0])
      },
      err => {
        setFetchStakingDataLoading(false)
      }
    )
  }

  const handleDurationButtonClick = period => {
    setSelectedPeriod(period)
    resetField('amount')
    setValue('amount', null)
    setAmount(null)
  }

  const handleAmountChange = e => {
    setValue('amount', e.target.value)
    setAmount(e.target.value)
    trigger('amount')
  }
  const handleMaxAmount = () => {
    if (coinBalance?.balance > selectedPeriod?.max_amount) {
      setAmount(selectedPeriod?.max_amount)
      setValue('amount', selectedPeriod?.max_amount)
    } else {
      setAmount(coinBalance?.balance ?? 0)
      setValue('amount', coinBalance?.balance ?? 0)
    }
    trigger('amount')
  }

  const getFromDate = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  const getToDate = period => {
    const currentDate = new Date()
    const futureDate = new Date(currentDate.getTime() + period * 24 * 60 * 60 * 1000)

    const year = futureDate.getFullYear()
    const month = String(futureDate.getMonth() + 1).padStart(2, '0')
    const day = String(futureDate.getDate()).padStart(2, '0')
    const hours = String(futureDate.getHours()).padStart(2, '0')
    const minutes = String(futureDate.getMinutes()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  const renderShimmer = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
      <ShimmerLine width='100%' height='220px' />
      <ShimmerLine width='100%' height='100px' />
      <ShimmerLine width='100%' height='45px' />
    </Box>
  )

  const onSubmit = formData => {
    setLoading(true)
    _createStakingSubscription(
      { amount, staking_period_id: selectedPeriod.period_id },
      res => {
        setLoading(false)
        handleClose()
        reset()
        setAmount('')
        showSuccessSnackbar({
          alertMessage: 'Subscription created',
          actionType: 'link',
          buttonMessage: 'View history',
          linkTo: '/account/staking/history'
        })
        // showStakingSu
      },
      err => {
        setLoading(false)
        console.log(err)
        showErrorSnackbar({ alertMessage: err.response.data.errors[0] })
        console.error(`Error white creating staking subscription: ${err}`)
      }
    )
  }

  useEffect(() => {
    // Get the balances on modal Open
    if (modalOpen) {
      fetchBalances()
    }

    // On modal open, set the selected duration value
    if (modalOpen && !modalData.fetchWithApi) {
      setSelectedPeriod(modalData.selectedPeriod)
    }

    // Reset form values on modal close
    if (!modalOpen) {
      setAmount(null)
      setSelectedPeriod(null)
      reset()
    }

    // If fetchWithApi is true, then set fetch the staking data
    if (modalData?.fetchWithApi && modalOpen) {
      fetchSubscriptionData()
    }
  }, [modalOpen])

  useEffect(() => {
    const fetchRewardsDebounced = debounce(() => {
      setRewardsLoading(true)
      _getStakingEstimateRewards(
        { amount, staking_period_id: selectedPeriod?.period_id },
        data => {
          setRewardsData(data)
          setRewardsLoading(false)
        },
        err => setRewardsLoading(false)
      )
    }, 1000)

    if (amount) {
      fetchRewardsDebounced()
    } else {
      setRewardsData({ daily_reward: null, duration_reward: null, yearly_reward: null })
    }

    // Clean up the debounced function on component unmount
    return () => {
      fetchRewardsDebounced.clear()
    }
  }, [amount])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title='Subscribe'
        render={
          fetchStakingDataLoading
            ? renderShimmer
            : () => (
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* Durations Container */}
                  <div className={styles.durationsContainer}>
                    {modalData?.period_summary_list?.map(el => (
                      <DurationButton
                        key={el.duration}
                        apr={el.apr}
                        isSelected={selectedPeriod?.duration === el.duration}
                        onClick={() => handleDurationButtonClick(el)}
                        value={el.duration}
                        duration={el.duration === 0 ? 'Flexible' : el.duration}
                      />
                    ))}
                  </div>

                  {/* Amount Input */}
                  <Input
                    type='number'
                    inputLabel='Amount'
                    variant='primary'
                    showBorder
                    containerStyle={{ margin: '20px 0' }}
                    indicatorLabel={`Available: ${formatNumber(coinBalance?.balance ?? 0, 6) ?? 0.0}`}
                    style={{ fontSize: 13, background: 'transparent' }}
                    value={amount || ''}
                    placeholder={`${selectedPeriod?.min_amount} - ${selectedPeriod?.max_amount} ${modalData?.asset}`}
                    // {...register('amount')}
                    error={errors.amount}
                    onChange={handleAmountChange}
                    icon={
                      <div className={styles.inputEndContainer}>
                        <div className={styles.inputCoinName}>{modalData?.asset}</div>
                        <div className={styles.divider} />
                        <Button
                          text='MAX'
                          variant='link'
                          onClick={handleMaxAmount}
                          style={{
                            padding: 0,
                            width: 'fit-content'
                          }}
                        />
                      </div>
                    }
                  />

                  <h5>Summary</h5>
                  {selectedPeriod?.duration === 0 ? (
                    <FlexibleSummary
                      dailyRewardLabel={`${Number(rewardsData.daily_reward)?.toFixed(
                        currencies.find(coin => coin.id === modalData?.asset?.toLowerCase())?.precision
                      )} ${modalData?.asset}`}
                      dailyRewardPercentage={`${Number(selectedPeriod?.daily_apr).toPrecision(2)}%`}
                    />
                  ) : (
                    <FixedSummary
                      rewardsAmountLabel={`${Number(rewardsData.duration_reward)?.toFixed(
                        currencies.find(coin => coin.id === modalData?.asset?.toLowerCase())?.precision
                      )} ${modalData?.asset}`}
                      rewardsPercentage={`${selectedPeriod?.apr}%`}
                      fromDate={getFromDate()}
                      toDate={getToDate(selectedPeriod?.duration)}
                    />
                  )}

                  <CheckboxInput
                    label={
                      <>
                        I have read and agreed to <Link>WenBit Staking Service Terms & Conditions</Link>
                      </>
                    }
                    containerStyle={{ margin: '40px 0' }}
                    error={errors.termsAndConditions}
                    register={{ ...register('termsAndConditions') }}
                  />

                  <Button text='Confirm' isLoading={loading} type='submit' />
                </form>
              )
        }
      />
    </>
  )
}

export default StakingSubscribeModal
