import React from "react"
import { Link } from "react-router-dom"

// assets
import wenbitMockup from "./assets/wenbit_mockup.png"

// styles
import Styles from "./downloads.module.css"

// icons
import { ReactComponent as DownloadIcon } from "../../assets/icons/imported/download.svg"

// components
import Button from "../../components/Button/Button"
import { useSelector } from "react-redux"

function Downloads() {
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Resources`

  return (
    <div className={Styles.pageContainer}>
      <section className={Styles.headerContainer}>
        <span>Resources</span>
        <h1>Downloads</h1>
        <p>
          We know that crypto can be a bit like rocket science, but don't worry,
          we've got your back. Whether you're a seasoned pro or just starting
          out, we've got all the relevant info you need to invest with
          confidence. And if you can't find what you're looking for, no problem
          - just give us a <Link>shout</Link>.
        </p>
      </section>

      <section className={Styles.contentContainer}>
        <img src={wenbitMockup} />

        <div className={Styles.manualContainer}>
          <h2>WenBit manual</h2>
          <p>
            Struggling to navigate through WenBit? <br />
            No worries! Simply grab our manual, take a glance, <br />
            and you'll be trading like a seasoned pro in a jiffy.
          </p>
          <Button
            text="Download"
            icon={<DownloadIcon width={13.5} />}
            iconPosition="right"
          />
        </div>
      </section>
    </div>
  )
}

export default Downloads
