// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'

function AmountDisplay({ coinId, amount }) {
  // ** States
  const currencies = useSelector(state => state.currencies.value)
  const hidePrice = useSelector(state => state.userData.value.hide_balance)

  // ** Variables
  const coin = coinId && currencies.find(coin => coin.id === coinId)
  const amountDisplay = coinId ? Number(amount).toFixed(coin?.precision) : amount

  return <>{hidePrice ? '********' : amountDisplay}</>
}

export default AmountDisplay
