import React from "react"
import { useSelector } from "react-redux"
import MegaMenuItem from "../../MegaMenuItem" // Update the import path as needed
import { BiTimer, BiNews } from "react-icons/bi"
import { HiTrendingUp } from "react-icons/hi"
import { ReactComponent as CalendarIcon } from "../../../assets/icons/imported/calendar.svg"

function BlogsMegaMenu() {
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)
  const assets = useSelector(state => state.exchangeAssets.value)

  const IconImage = (props) => {
    console.log(props.src)
    return <img src={props.src} alt={props.alt} style={{ width: '24px', height: '24px' }} />
  }

  return (
    <div>
      <MegaMenuItem
        name="What's Trending"
        description="See the latest up-to-date trends"
        Icon={HiTrendingUp}
        to="/blogs/trending"
      />
      <MegaMenuItem
        name="Wen’s new bits"
        description={`The ${exchangeName} news`}
        Icon={() => <IconImage src={assets?.find(asset => asset.logo_type === 'emblem_logo')?.image.url} alt="emblem logo" />}
        to="/blogs/wen-new-bits"
      />
      <MegaMenuItem
        name="Events"
        description="Ongoing crypto events"
        Icon={CalendarIcon}
        to="/blogs/events"
      />
      <MegaMenuItem
        name="Inside Scoop"
        description="Inside scoop news here"
        Icon={BiNews}
        to="/blogs/inside-scoop"
      />
      <MegaMenuItem
        name="The latest buzz"
        description="Recent fresh news"
        Icon={BiTimer}
        to="/blogs/latest-buzz"
      />
    </div>
  )
}

export default BlogsMegaMenu
