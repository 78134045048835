// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** MUI Imports
import { Box, IconButton, useMediaQuery } from '@mui/material'

// ** Utils Imports
import { convertToUnixEpoch } from '../../utility/convertToUnixEpoch'

// ** Component Imports
import Dialog from '../Dialog'
import Input from '../Input/Input'
import Button from '../Button/Button'
import SelectCoinAutocomplete from '../SelectCoinAutocomplete'

// ** Icons Imports
import { ReactComponent as FilterIcon } from '../../assets/icons/imported/filter.svg'
import SelectMarketAutocomplete from '../SelectMarketAutocomplete'

function Filters({
  filteringParams,
  setFilteringParams,
  onChange = () => {},
  mobileBreakpoint,
  filterDateRange = false,
  filterBaseCoin = false,
  filterQuoteCoin = false,
  filterMarketPair = false,
  filterType = false,
  filterCoin = false,
  filterWalletAction = false,
  resetValues = {}
}) {
  // ** Media Queries
  const isFilterView = useMediaQuery(`(max-width:${mobileBreakpoint})`)

  // ** States
  const markets = useSelector(state => state.marketList.value)
  const [startDateRange, setStartDateRange] = useState(null)
  const [endDateRange, setEndDateRange] = useState(null)

  const [filterDialogOpen, setFilterDialogOpen] = useState(false)

  // ** Handlers
  // -- BASE COIN --
  const handleBaseCoinChange = (e, v) => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (!v) {
        newParams.delete('base_unit')
      } else {
        newParams.set('base_unit', v.id)
      }
      return newParams
    })
  }

  // -- QUOTE COIN --
  const handleQuoteCoinChange = (e, v) => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (!v) {
        newParams.delete('quote_unit')
      } else {
        newParams.set('quote_unit', v.id)
      }
      return newParams
    })
  }

  // -- COIN --
  const handleCoinChange = (e, v) => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (!v) {
        newParams.delete('coin')
      } else {
        newParams.set('coin', v.id)
      }
      return newParams
    })
  }

  // -- MARKET PAIR --
  const handleMarketChange = (e, v) => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (!v) {
        newParams.delete('market')
      } else {
        newParams.set('market', v.id)
      }
      return newParams
    })
  }

  // -- RANGE DATE START --
  const handleStartDateChange = date => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (date) {
        newParams.set('time_from', convertToUnixEpoch(date))
        return newParams
      } else {
        newParams.delete('time_from')
        return newParams
      }
    })
  }

  // -- RANGE DATE END --
  const handleEndDateChange = date => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (date) {
        newParams.set('time_to', convertToUnixEpoch(date))
        return newParams
      } else {
        newParams.delete('time_to')
        return newParams
      }
    })
  }

  // -- SINGLE DATE --
  const handleDateChange = values => {
    console.log(convertToUnixEpoch(values[0]))
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (values.length === 2 && values[0] && values[1]) {
        newParams.set('time_from', convertToUnixEpoch(values[0]))
        newParams.set('time_to', convertToUnixEpoch(values[1]))
        return newParams
      } else {
        newParams.delete('time_from')
        newParams.delete('time_to')
        return newParams
      }
    })

    if (values.length === 2 && values[0] === null && values[1] === null) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.delete('time_from')
        newParams.delete('time_to')
        return newParams
      })
    }
  }

  // -- ORDER/TRADE TYPE --
  const handleTypeChange = (e, v) => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (v) {
        newParams.set('type', v)
        return newParams
      } else {
        newParams.delete('type')
        return newParams
      }
    })
  }

  // -- WALLET ACTION --
  const handleWalletActionChange = (e, v) => {
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      if (v) {
        newParams.set('action', v)
        return newParams
      } else {
        newParams.delete('action')
        return newParams
      }
    })
  }

  // -- FILTER RESETS --
  const handleReset = () => {
    setFilteringParams({ page: 1, ...resetValues })
    setStartDateRange(null)
    setEndDateRange(null)
  }

  useEffect(() => {
    // Get time_from and time_to values from filteringParams and convert them to Date objects
    const timeFrom = filteringParams.get('time_from')
    const timeTo = filteringParams.get('time_to')
    const startDate = timeFrom ? new Date(Number(timeFrom) * 1000) : null
    const endDate = timeTo ? new Date(Number(timeTo) * 1000) : null

    // Update startDateRange and endDateRange states with default values
    setStartDateRange(startDate)
    setEndDateRange(endDate)

    // Make the onchange prop accessible to parent components
    onChange(filteringParams)
  }, [filteringParams])

  const renderFilterInputs = () => (
    <>
      {/* Date Range */}
      {filterDateRange ? (
        isFilterView ? (
          <>
            <Input
              type='date'
              inputLabel='From'
              variant='secondary'
              placeholder='Start Date'
              onChange={handleStartDateChange}
              selectedDate={startDateRange}
              setDate={setStartDateRange}
            />
            <Input
              type='date'
              inputLabel='To'
              variant='secondary'
              placeholder='End Date'
              onChange={handleEndDateChange}
              selectedDate={endDateRange}
              setDate={setEndDateRange}
            />
          </>
        ) : (
          <Input
            type='date'
            variant='secondary'
            placeholder='From - To'
            monthsShown={2}
            onChange={handleDateChange}
            startDateRange={startDateRange}
            endDateRange={endDateRange}
            setStartDateRange={setStartDateRange}
            setEndDateRange={setEndDateRange}
            selectsRange={!isFilterView}
            inputLabel={isFilterView && 'Date Range'}
          />
        )
      ) : (
        <></>
      )}

      {/* Base Coin */}
      {filterBaseCoin && (
        <Input
          inputLabel={isFilterView && 'Base Coin'}
          renderInputField={() => (
            <SelectCoinAutocomplete
              placeholder='Base Coin'
              onChange={handleBaseCoinChange}
              value={filteringParams.get('base_unit') ? { id: filteringParams.get('base_unit') ?? '' } : null}
            />
          )}
        />
      )}

      {/* Quote Coin */}
      {filterQuoteCoin && (
        <Input
          inputLabel={isFilterView && 'Quote Coin'}
          renderInputField={() => (
            <SelectCoinAutocomplete
              placeholder='Quote Coin'
              onChange={handleQuoteCoinChange}
              value={filteringParams.get('quote_unit') ? { id: filteringParams.get('quote_unit') ?? '' } : null}
            />
          )}
        />
      )}

      {/* Coin */}
      {filterCoin && (
        <Input
          inputLabel={isFilterView && 'Coin'}
          renderInputField={() => (
            <SelectCoinAutocomplete
              placeholder='BTC'
              onChange={handleCoinChange}
              value={filteringParams.get('coin') ? { id: filteringParams.get('coin') ?? '' } : null}
            />
          )}
        />
      )}

      {/* Market Pair */}
      {filterMarketPair && (
        <Input
          inputLabel={isFilterView && 'Pair'}
          renderInputField={() => (
            <SelectMarketAutocomplete
              placeholder='BTC/USDT'
              onChange={handleMarketChange}
              value={
                filteringParams.get('market')
                  ? markets.find(el => el.id === filteringParams.get('market')) ?? {
                      id: ''
                    }
                  : null
              }
            />
          )}
        />
      )}

      {/* Order/Trade Type */}
      {filterType && (
        <Input
          inputLabel={isFilterView && 'Type'}
          variant='secondary'
          type='select'
          onChange={handleTypeChange}
          selectOptions={[
            { label: 'All', value: null },
            { label: 'Buy', value: 'buy' },
            { label: 'Sell', value: 'sell' }
          ]}
          selectDefaultValue={null}
          selectValue={filteringParams.get('type')}
        />
      )}

      {/* Wallet Action */}
      {filterWalletAction && (
        <Input
          inputLabel={isFilterView && 'Wallet Action'}
          variant='secondary'
          type='select'
          onChange={handleWalletActionChange}
          selectOptions={[
            { label: 'All', value: null },
            { label: 'Deposit', value: 'deposit' },
            { label: 'Withdraw', value: 'withdraw' }
          ]}
          selectDefaultValue={null}
          selectValue={filteringParams.get('action')}
        />
      )}
    </>
  )

  if (isFilterView) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginLeft: 'auto' }}>
        <IconButton sx={{ w: 30, h: 30, ml: 'auto' }} onClick={() => setFilterDialogOpen(true)}>
          <FilterIcon color='var(--gray-color)' style={{ width: 23, height: 23 }} />
        </IconButton>
        <Dialog open={filterDialogOpen} setOpen={setFilterDialogOpen} fullScreen>
          <Box
            sx={{
              width: '100%',
              maxWidth: '450px',
              mx: 'auto',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {renderFilterInputs()}

            {/* Buttons Container */}
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '25px', mt: 'auto' }}
            >
              <Button
                text='Reset'
                onClick={() => {
                  handleReset()
                  setFilterDialogOpen(false)
                }}
              />
              <Button text='Close' variant='dark' onClick={() => setFilterDialogOpen(false)} />
            </Box>
          </Box>
        </Dialog>
      </Box>
    )
  }

  if (!isFilterView) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        {renderFilterInputs()}
        <Button text='Reset' variant='link' onClick={handleReset} style={{ marginTop: -10 }} />
      </Box>
    )
  }
}

export default Filters
