// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Helpers Imports
import { showViewStakingSubscriptionModal } from '../../../../../modals/staking/view-staking-subscription-modal/helpers'

// ** Component Imports
import CollapsableTable, {
  CustomCollapseTable,
  CustomCollapseTableCell,
  CustomCollapseTableRow,
  CustomCollapseTableContainer,
  CustomCollapseTableHead,
  CustomCollapseTableBody
} from '../../../../../../components/CollapsableTable'
import CoinDisplay from '../../../../../../components/CoinDisplay/CoinDisplay'
import TableButton from '../../../../../../components/TableButton/TableButton'
import SidebarContentWrapper from '../../../../../../components/sidebar-layout-components/SidebarContentWrapper'
import { _getEarnWallets } from '../../../../redux/actions'
import { Box, capitalize, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import AmountDisplay from '../../../../../../components/AmountDisplay'
import { BiChevronUp } from 'react-icons/bi'

function EarnWalletTable() {
  // ** Media Queries
  const isMobileView = useMediaQuery('(max-width:866px)')

  const filteringModelDefaultValue = {
    // limit: 10,
    // page: 1
  }

  // ** States
  const [earnWallets, setEarnWallets] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [filteringModel, setFilteringModel] = useState(filteringModelDefaultValue)

  // ** Handlers
  const fetchEarnWallets = filteringParams => {
    setIsDataLoading(true)
    _getEarnWallets(
      filteringParams,
      res => {
        setEarnWallets(res.data)
        setIsDataLoading(false)
      },
      err => {
        console.error(`Error fetching earn wallets: ${err}`)
        setIsDataLoading(false)
      }
    )
  }

  useEffect(() => {
    fetchEarnWallets(filteringModel)
  }, [filteringModel])

  // ** Handlers
  const renderCollapseContent = row => {
    const subscriptions = row.subscriptions

    if (isMobileView)
      return (
        <Box sx={{ borderBottom: '1px var(--border-color) solid !important' }}>
          {subscriptions.map(subscription => (
            <Box
              sx={{
                m: '10px',
                ml: '60px',
                '& > *': { fontSize: '12px !important' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  {capitalize(subscription.type)}
                  {subscription.type !== 'flexible' && (
                    <Box
                      sx={{ border: '1px var(--text-color) solid', borderRadius: '2.5px', p: '2px', fontSize: '8px' }}
                    >
                      {subscription.duration} Days
                    </Box>
                  )}
                </Box>
                <span>
                  <AmountDisplay amount={subscription.total_amount} coinId={row.currency_id} />
                </span>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ color: 'var(--gray-color)' }}>APR</span>
                <span style={{ color: 'var(--gray-color)' }}>Cumulative Intrest</span>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ color: 'var(--success-color)' }}>{subscription.apr}%</span>
                <span style={{ color: 'var(--success-color)' }}>
                  <AmountDisplay amount={subscription.real_time_apr_rewards} coinId={row.currency_id} />
                </span>
              </Box>
            </Box>
          ))}
        </Box>
      )

    if (!isMobileView)
      return (
        <CustomCollapseTableContainer>
          <CustomCollapseTable>
            <CustomCollapseTableHead
              sx={{
                zIndex: 999,
                '& .MuiTableCell-root': { p: '0 !important' },
                '& .MuiTableCell-head': { border: 'none !important' }
              }}
            >
              <CustomCollapseTableRow sx={{ height: 0 }}>
                <CustomCollapseTableCell sx={{ width: '40px', height: 0 }} />
                {columns?.map((column, index) => (
                  <CustomCollapseTableCell key={index} sx={{ width: column.width }}>
                    {/* {column.headerTitle} */}
                  </CustomCollapseTableCell>
                ))}
              </CustomCollapseTableRow>
            </CustomCollapseTableHead>
            <CustomCollapseTableBody>
              {subscriptions.map(subscription => (
                <CustomCollapseTableRow
                  key={subscription.id}
                  sx={{
                    '&': {
                      borderBottom: '1.15px var(--border-color) solid !important'
                    },
                    ':hover .coinTagContainer': { backgroundColor: 'var(--secondary-color)' }
                  }}
                >
                  <CustomCollapseTableCell sx={{ width: '40px', minWidth: '40px' }}>
                    <IconButton sx={{ opacity: 0 }} aria-label='expand row' size='small'>
                      <BiChevronUp />
                    </IconButton>
                  </CustomCollapseTableCell>
                  <CustomCollapseTableCell
                    sx={{
                      width: '220px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                      <span className={styles.subscriptionDurationSpan}>{capitalize(subscription.type)}</span>
                      {subscription.duration ? (
                        <span className={styles.daysLabel}>{subscription.duration} Days</span>
                      ) : (
                        ''
                      )}
                    </Box>
                    <span className={styles.aprSpan}>APR {subscription.apr}%</span>
                  </CustomCollapseTableCell>
                  <CustomCollapseTableCell sx={{ width: '220px' }}>{subscription.total_amount}</CustomCollapseTableCell>
                  <CustomCollapseTableCell sx={{ width: '250px', display: 'flex', alignItems: 'center', gap: '60px' }}>
                    <div>
                      <AmountDisplay amount={subscription.real_time_apr_rewards} coinId={row.currency_id} />
                    </div>
                    <TableButton
                      buttonText='View'
                      onClick={() => {
                        showViewStakingSubscriptionModal({ id: subscription.id, type: subscription.type })
                      }}
                    />
                  </CustomCollapseTableCell>
                </CustomCollapseTableRow>
              ))}
            </CustomCollapseTableBody>
          </CustomCollapseTable>
        </CustomCollapseTableContainer>
      )
  }

  const columns = isMobileView
    ? [
        {
          id: 'id',
          width: '100%',
          headerTitle: 'Coin',
          renderCell: row => (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <CoinDisplay useReduxStore coinId={row.currency_id} />

              <AmountDisplay amount={row.total_amount} coinId={row.currency_id} />
            </Box>
          )
        }
      ]
    : [
        {
          id: 'id',
          width: '220px',
          headerTitle: 'Coin',
          renderCell: row => <CoinDisplay useReduxStore coinId={row.currency_id} />
        },
        {
          id: 'balance',
          width: '220px',
          headerTitle: 'Total Amount',
          renderCell: row => <AmountDisplay amount={row.total_amount} coinId={row.currency_id} />
        },
        {
          id: 'amount',
          width: '250px',
          headerTitle: 'Cumulative Real-Time APR Rewards',
          renderCell: row => <AmountDisplay amount={row.real_time_apr_rewards} coinId={row.currency_id} />
        }
      ]
  return (
    <SidebarContentWrapper>
      <CollapsableTable
        hideFooter
        hideHeader={isMobileView}
        isTableLayoutFixed={!isMobileView}
        columns={columns}
        rows={earnWallets}
        renderCollapseContent={renderCollapseContent}
        isCollapseDisabled={row => row.subscriptions.length === 0}
        height='465px'
        showScroll
      />
    </SidebarContentWrapper>
  )
}

export default EarnWalletTable
