import React, { useState, useEffect } from 'react'

// react router
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// styles
import Styles from './orderHistoryTab.module.css'

// icons
import { ReactComponent as BuyArrowSquare } from '../../../../assets/icons/imported/buy-arrow-square.svg'
import { ReactComponent as SellArrowSquare } from '../../../../assets/icons/imported/sell-arrow-square.svg'

// components
import NavLink from '../../../../components/Header/NavLink'
import Search from '../../../../components/Search/Search'
import CoinTag from '../../../../components/CoinTag/CoinTag'
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import SidebarContentWrapper from '../../../../components/sidebar-layout-components/SidebarContentWrapper'

// redux
import { _getOrders } from '../../redux/actions'
import { addOrders } from '../../../exchange/redux/slices/openOrdersSlice'
import { _cancelOrder } from '../../../exchange/redux/actions'
import { setSnackbarOpen, setSnackbarData } from '../../../snackbar/redux/snackbarSlice'
// helpers
import { isoStringToDate } from '../../../helpers/localeDate'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../../components/Button/Button'
import MobileRowDatagrid from '../../../../components/MUIDatagrid/MobileRowDatagrid'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'
import getOrderStateColor from '../../../../utility/orders/getOrderStateColor'
import { FormattedMessage, injectIntl } from 'react-intl'
import { IconButton, capitalize } from '@mui/material'
import Filters from '../../../../components/Filters'

function OrderHistoryTab() {
  const exchangeName = useSelector((state) => state.exchangeCustomization.value.name)

  document.title = `${exchangeName}: Order History`

  // ** Hooks
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** URL Params
  const [filteringParams, setFilteringParams] = useSearchParams()

  // ** States
  const markets = useSelector(state => state.marketList.value)
  // const openOrders = useSelector((state) => state.openOrders.value)

  const [selectedTab, setSelectedTab] = useState('open')

  // table states
  const pageLimit = 10
  const [page, setPage] = useState(1)

  const [totalRows, setTotalRows] = useState(0)
  const [orders, setOrders] = useState('')
  const [tableLoading, setTableLoading] = useState(false)
  const [marketRows, setMarketRows] = useState([])

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 677)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 677)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const handlePageChange = (event, value) => {
    setPage(value)
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set('page', value)
      return newParams
    })
  }

  useEffect(() => {
    if (location.pathname === '/account/orders/open') {
      setSelectedTab('open')
    }
    if (location.pathname === '/account/orders/history') {
      setSelectedTab('history')
    }
  }, [location])

  const fetchOrder = () => {
    setTableLoading(true)
    _getOrders(
      {
        state: selectedTab === 'open' ? 'wait' : ['done', 'reject', 'cancel'],
        page: filteringParams.get('page'),
        limit: pageLimit,
        market: filteringParams.get('market'),
        time_from: filteringParams.get('time_from'),
        time_to: filteringParams.get('time_to')
      },
      res => {
        setTableLoading(false)
        setMarketRows(res.data)
        setTotalRows(res.total)
        setOrders(res.data)
      },
      err => setTableLoading(false)
    )
  }

  const handleCancelOrder = id => {
    _cancelOrder(
      id,
      () => {
        dispatch(
          setSnackbarData({
            alertMessage: 'This order will be canceled shortly',
            callback: () => {},
            severity: 'success',
            position: 'topRight'
          })
        )
        dispatch(setSnackbarOpen(true))
        fetchOrder()
      },
      error => {
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
      }
    )
  }

  useEffect(() => {
    // If no page is provided by URL
    if (!filteringParams.get('page')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('page', 1)
        return newParams
      })
    } else {
      // Else if page is provided by URL, set the page state
      setPage(Number(filteringParams.get('page')))
    }
  }, [selectedTab])

  const ordersColumns = [
    {
      field: 'pair',
      headerName: 'Pair',
      minWidth: 160,
      maxWidth: 190,
      editable: false,
      flex: 1,
      renderCell: data => {
        const marketPair = markets.find(el => el.id === data.row.market)
        return (
          <span className={Styles.pairColumn}>
            <img src={marketPair?.icon_url} />
            {marketPair?.coin_name}
            <CoinTag
              tag={`${markets.find(el => el.id === data.row.market)?.base_unit.toUpperCase()}/${markets
                .find(el => el.id === data.row.market)
                ?.quote_unit.toUpperCase()}`}
              style={{ width: 'fit-content' }}
              size={11}
            />
          </span>
        )
      }
    },
    // {
    //   field: 'date',
    //   headerName: 'Date',
    //   minWidth: 150,
    //   renderCell: (data) => (
    //     <div className={Styles.orderHistoryRow}>
    //       <span>{isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}</span>
    //     </div>
    //   )
    // },
    {
      field: 'side',
      headerName: 'Side',
      minWidth: 190,
      maxWidth: 200,
      editable: false,
      renderCell: data => (
        <span className={Styles.sideColumn}>
          {data.row.side === 'sell' ? <SellArrowSquare height={24} /> : <BuyArrowSquare height={24} />}

          <div>
            <span>{data.row.side.charAt(0).toUpperCase() + data.row.side.slice(1)}</span>
            <span>
              {isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}
            </span>
          </div>
        </span>
      )
      // flex: 1
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 130,
      maxWidth: 150,
      editable: false,
      flex: 1,
      renderCell: data => <>{<div className={Styles.orderHistoryRow}>{data.row.origin_volume}</div>}</>
    },
    {
      field: 'price',
      headerName: 'Price',
      minWidth: 130,
      maxWidth: 150,
      editable: false,
      flex: 1,
      renderCell: data => <>{<div className={Styles.orderHistoryRow}>{data.row.price}</div>}</>
    },
    {
      field: 'ord_type',
      headerName: 'Type',
      minWidth: 130,
      maxWidth: 150,
      editable: false,
      // flex: 1,
      renderCell: data => (
        <>
          {
            <div className={Styles.orderHistoryRow}>
              {data.row.ord_type.charAt(0).toUpperCase() + data.row.ord_type.slice(1)}
            </div>
          }
        </>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      minWidth: 110,
      maxWidth: 140,
      editable: false,
      flex: 1,
      renderCell: data => (
        <span className={Styles.actionColumn}>
          <span
            style={{
              color: getOrderStateColor(data.row.state)
            }}
          >
            {data.row.state}
          </span>
        </span>
      )
    },
    selectedTab !== 'open'
      ? {} // If selectedTab is 'open', don't include the "Actions" column (empty object)
      : {
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          flex: 1,
          minWidth: 110,
          maxWidth: 140,
          editable: false,
          renderCell: data => (
            <span>
              <Button
                text='Cancel'
                variant='link'
                // icon={<TrashIcon width={14} style={{marginLeft: 10}}/>}
                style={{
                  padding: 0,
                  marginTop: -10
                }}
                onClick={() => handleCancelOrder(data.row.id)}
              />
            </span>
          )
        }
  ]

  return (
    <SidebarContentWrapper
      title={selectedTab === 'open' ? 'Open Orders' : 'Order History'}
      secondaryTitle='You asked for it; you got it. View all your orders here.'
    >
      {/* tabs container */}
      <div className={Styles.tabsContainer}>
        <Button
          text='Open Orders'
          type='tab'
          onClick={() => {
            if (tableLoading) return
            navigate('/account/orders/open', { replace: true })
          }}
          isTabSelected={location.pathname === '/account/orders/open'}
        />
        <Button
          text='Order History'
          type='tab'
          onClick={() => {
            if (tableLoading) return
            navigate('/account/orders/history', { replace: true })
          }}
          isTabSelected={location.pathname === '/account/orders/history'}
        />

        <Filters
          filterDateRange
          filterMarketPair
          onChange={fetchOrder}
          mobileBreakpoint='1220px'
          filteringParams={filteringParams}
          setFilteringParams={setFilteringParams}
        />
      </div>

      {/* rows container */}
      <div>
        <MUIDatagrid
          disableSorting
          page={page}
          pageLimit={pageLimit}
          rows={selectedTab === 'open' ? orders : marketRows}
          // hideHeader={isMobileView}
          totalRowsCount={totalRows}
          loading={tableLoading}
          getRowId={row => row.id}
          onPageChange={handlePageChange}
          columns={ordersColumns}
          rowHeight={isMobileView && 170}
        />
      </div>
    </SidebarContentWrapper>
  )
}

export default injectIntl(OrderHistoryTab)
