import { store } from '../../../../redux/store'
import { setStakingSubscribeModalData, setStakingSubscribeModalOpen } from './redux/subscribeStatkingModalSlice'

export const showStakingSubscribeModal = selectedProduct => {
  store.dispatch(setStakingSubscribeModalData({ ...selectedProduct, fetchWithApi: false }))
  store.dispatch(setStakingSubscribeModalOpen(true))
}

export const showStakingSubscribeModalWithApiCall = coinId => {
  store.dispatch(setStakingSubscribeModalData({ coinId, fetchWithApi: true }))
  store.dispatch(setStakingSubscribeModalOpen(true))
}

export const hideStakingSubscribeModal = () => {
  store.dispatch(setStakingSubscribeModalOpen(false))
}
