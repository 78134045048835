import { call, cancel, fork, delay, put, select, take, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { setMarketTickers } from './modules/exchange/redux/slices/marketTickersSlice'
import { addOrder } from './modules/exchange/redux/slices/openOrdersSlice'
import {
  addOrderBook,
  updateOrderBookIncrement,
  updateOrderBookSnapshot,
  setOrderBookLoad,
  clearOrderBook,
  addAsks,
  addBids
} from './modules/exchange/redux/slices/orderBookSlice'
import { addTrade, lastTrade } from './modules/exchange/redux/slices/tradesSlice'
import { addKline, clearKline } from './modules/exchange/redux/slices/klineSlice'
import { store } from './redux/store'
import { setGlobalConversions } from './modules/exchange/redux/slices/globalConversionsSlice'

const SUBSCRIBE_TO_PUBLIC_MARKET = 'SUBSCRIBE_TO_PUBLIC_MARKET'
const SUBSCRIBE_TO_PRIVATE_MARKET = 'SUBSCRIBE_TO_PRIVATE_MARKET'
const UNSUBSCRIBE_FROM_MARKET = 'UNSUBSCRIBE_FROM_MARKET'
const CLOSE_PUBLIC_WEBSOCKET = 'CLOSE_PUBLIC_WEBSOCKET'
const CLOSE_PRIVATE_WEBSOCKET = 'CLOSE_PRIVATE_WEBSOCKET'
const OPEN_PRIVATE_CONNECTION = 'OPEN_PRIVATE_CONNECTION'
const OPEN_PUBLIC_CONNECTION = 'OPEN_PUBLIC_CONNECTION'
const PUBLIC_WEBSOCKET_OPENED = 'PUBLIC_WEBSOCKET_OPENED'
const PRIVATE_WEBSOCKET_OPENED = 'PRIVATE_WEBSOCKET_OPENED'
const CHANGE_KLINE_RESOLUTION = 'CHANGE_KLINE_RESOLUTION'
const SUBSCRIBE_TO_SOCKET = 'SUBSCRIBE_TO_SOCKET'
const UNSUBSCRIBE_TO_SOCKET = 'UNSUBSCRIBE_TO_SOCKET'
const UNSUBSCRIBE_TRADES_FROM_MARKET = 'UNSUBSCRIBE_TRADES_FROM_MARKET'
const OPEN_TRADES_CONNECTION = 'OPEN_TRADES_CONNECTION'
const CLOSE_TRADES_WEBSOCKET = 'CLOSE_TRADES_WEBSOCKET'
const TRADES_WEBSOCKET_OPENED = 'TRADES_WEBSOCKET_OPENED'
const SUBSCRIBE_TO_TRADES_MARKET = 'SUBSCRIBE_TO_TRADES_MARKET'

let PublicWsInstance = null
let PrivateWsInstance = null
let TradesWsInstance = null
let publicWsTask = null
let PrivateWsTask = null
let tradesWsTask = null
let subscribedMarket = null
let subscribedTradesMarket = null
let publicWsUrl = ''
let tradesWsUrl = ''
let privateWsUrl = ''
let newPublicWebsocket = false
let newTradesWebsocket = false
let newPrivateWebsocket = false
let resolution = null
const ORDERBOOK_LEVELS = 25 // rows count

// ** Values that will periodically update the values in the UI
// -- ORDERBOOK --
const orderbookUpdateThreshold = 500 // 500ms
const canReadOrderbookWSData = true

// -- TICKERS --
const tickersUpdateThreshold = 100 // 250ms
const canReadTickersWSData = true

// -- TRADES --
const tradesUpdateThreshold = 100 // 250ms
let canReadTradesWSData = true

const createPublicWebSocketChannel = ws => {
  return eventChannel(emitter => {
    ws.onopen = () => {
      console.log('WebSocket connection opened')
      newPublicWebsocket = true
      setTimeout(() => {
        emitter({ type: PUBLIC_WEBSOCKET_OPENED })
        emitter({ type: SUBSCRIBE_TO_PUBLIC_MARKET })
      }, 5000)
      // Additional logic if needed
    }

    ws.onmessage = event => {
      // Handle incoming WebSocket messages and emit corresponding actions
      const tickers = JSON.parse(event.data)
      const key = Object.keys(tickers)[0]
      const orderIds = new Set()
      const lastTrade = store.getState().trades.last
      // console.log(lastTrade)
      // if (lastTrade && eventSnapMessage) {
      //   const eventSnap = { data: eventSnapMessage, lastTrade: lastTrade.price }

      //   emitter(updateOrderBookSnapshot(eventSnap))

      // }

      let isProcessing = false
      const throttleInterval = 5000 // Process messages every 5 seconds

      const process = data => {
        if (!isProcessing) {
          isProcessing = true
          if (subscribedMarket === "btcusd") {
            setTimeout(() => {
              emitter(addBids({ bids: data.bids, lastTrade: lastTrade.price }))
              emitter(addAsks({ asks: data.asks, lastTrade: lastTrade.price }))

              isProcessing = false
            }, throttleInterval)
          } else {
            emitter(addBids({ bids: data.bids, lastTrade: lastTrade.price }))
            emitter(addAsks({ asks: data.asks, lastTrade: lastTrade.price }))
          }
        }
      }

      switch (key) {
        case 'global.tickers':
          emitter(setMarketTickers(tickers['global.tickers']))
          break
        case 'global.conversions':
          emitter(setGlobalConversions(tickers['global.conversions']))
          break
        case `${subscribedMarket}.ob-inc`:
          const event = tickers[`${subscribedMarket}.ob-inc`]
          process(event)
          break
        default:
          break
      }
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
      newPublicWebsocket = false
      console.log('newPublicWebsocket', newPublicWebsocket)
    }
    // Return the unsubscribe function
    return () => {
      // Cleanup logic if needed
    }
  })
}

export function* handlePublicWebSocket(publicWsUrl) {
  PublicWsInstance = new WebSocket(publicWsUrl)
  const channel = yield call(createPublicWebSocketChannel, PublicWsInstance)
  try {
    while (true) {
      const action = yield take(channel)
      yield put(action)
    }
  } finally {
    // Cleanup logic if needed
  }
}

export function* openPublicWebSocketConnection() {
  const selectedMarket = yield select(state => state.selectedMarket.value)
  resolution = yield select(state => state.kline.resolution)
  const oldResolution = yield select(state => state.kline.oldResolution)
  const subscribeMessage = {
    event: 'subscribe',
    streams: [
      // `${selectedMarket.id}.trades`,
      // `${selectedMarket.id}.ob-snap`,
      `${selectedMarket.id}.ob-inc`,
      'global.tickers',
      'global.conversions'
    ]
  }
  const subscribeKlineMessage = {
    event: 'subscribe',
    streams: [`${selectedMarket.id}.kline-${resolution}`]
  }
  const unSubscribeKlineMessage = {
    event: 'unsubscribe',
    streams: [`${selectedMarket.id}.kline-${oldResolution}`]
  }
  yield put(setOrderBookLoad(true))
  publicWsUrl = window.location.host.includes('localhost')
    ? `wss://exchange-stage.wenbit.com/api/v2/ws/public`
    : `wss://${window.location.host}/api/v2/ws/public`

  if (!PublicWsInstance) {
    publicWsTask = yield call(handlePublicWebSocket, publicWsUrl)
    yield take(PUBLIC_WEBSOCKET_OPENED) // Wait for WebSocket connection to be opened
  }

  // yield take(SUBSCRIBE_TO_PUBLIC_MARKET)
  if (subscribedMarket && subscribedMarket !== selectedMarket.id) {
    yield put({ type: UNSUBSCRIBE_FROM_MARKET, market: subscribedMarket })
  }
  if (!newPublicWebsocket) {
    yield take(PUBLIC_WEBSOCKET_OPENED)
  }
  if ((newPublicWebsocket || !subscribedMarket) && subscribedMarket !== selectedMarket.id) {
    PublicWsInstance.send(JSON.stringify(subscribeMessage))
    // PublicWsInstance.send(JSON.stringify(subscribeKlineMessage))
  }
  subscribedMarket = selectedMarket.id
}

function* openPublicConnection() {
  publicWsUrl = window.location.host.includes('localhost')
    ? `wss://exchange-stage.wenbit.com/api/v2/ws/public`
    : `wss://${window.location.host}/api/v2/ws/public`

  // Start a new WebSocket task with the updated URL
  publicWsTask = yield call(handlePublicWebSocket, publicWsUrl)
}

export function* closePublicWebSocketConnection() {
  if (PublicWsInstance) {
    PublicWsInstance.close()
    PublicWsInstance = null
    newPublicWebsocket = false
  }
}

export function* unsubscribeFromMarket(action) {
  const { market } = action
  resolution = yield select(state => state.kline.resolution)

  const unsubscribeMessage = {
    event: 'unsubscribe',
    streams: [
      // `${market}.trades`,
      // `${market}.ob-snap`,
      `${market}.ob-inc`
      // `${market}.kline-${resolution}`,
    ]
  }

  PublicWsInstance.send(JSON.stringify(unsubscribeMessage))
}

//*******************************************************************************/

const createPrivateWebSocketChannel = ws => {
  return eventChannel(emitter => {
    ws.onopen = () => {
      console.log('Private webSocket connection opened')
      newPrivateWebsocket = true
      emitter({ type: PRIVATE_WEBSOCKET_OPENED })
      emitter({ type: SUBSCRIBE_TO_PRIVATE_MARKET })
      // Additional logic if needed
    }

    ws.onmessage = event => {
      // Handle incoming WebSocket messages and emit corresponding actions
      const tickers = JSON.parse(event.data)
      const key = Object.keys(tickers)[0]
      const orderIds = new Set()
      switch (key) {
        case 'order': {
          const order = tickers['order']

          // Check if the order ID already exists
          if (!orderIds.has(order.id)) {
            // Add the order ID to the set
            orderIds.add(order.id)

            // Dispatch the addOrder action
            emitter(addOrder(tickers.order))
          }
          break
        }
      }
    }

    ws.onerror = err => {
      console.log(err)
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
      newPrivateWebsocket = false
      // console.log("newPrivateWebsocket", newPrivateWebsocket)
      // Additional logic if needed
    }

    // Return the unsubscribe function
    return () => {
      // Cleanup logic if needed
    }
  })
}

export function* handlePrivateWebSocket(privateWsUrl) {
  PrivateWsInstance = new WebSocket(privateWsUrl)
  const channel = yield call(createPrivateWebSocketChannel, PrivateWsInstance)
  try {
    while (true) {
      const action = yield take(channel)
      yield put(action)
    }
  } finally {
    // Cleanup logic if needed
  }
}

export function* openPrivateWebSocketConnection() {
  const loggedIn = yield select(state => state.loggedIn.value)
  const subscribeMessage = {
    event: 'subscribe',
    streams: ['order', 'account']
  }
  privateWsUrl = window.location.host.includes('localhost')
    ? `wss://exchange-stage.wenbit.com/api/v2/ws/private`
    : `wss://${window.location.host}/api/v2/ws/private`

  if (!PrivateWsInstance) {
    PrivateWsTask = yield call(handlePrivateWebSocket, privateWsUrl)
    yield take(PRIVATE_WEBSOCKET_OPENED) // Wait for WebSocket connection to be opened
  }
  PrivateWsInstance.send(JSON.stringify(subscribeMessage))
}

function* openPrivateConnection() {
  privateWsUrl = window.location.host.includes('localhost')
    ? `wss://exchange-stage.wenbit.com/api/v2/ws/private`
    : `wss://${window.location.host}/api/v2/ws/private`

  if (PrivateWsInstance) {
    PrivateWsInstance.close()
    PrivateWsInstance = null
    newPrivateWebsocket = false
  }

  // Start a new WebSocket task with the updated URL
  PrivateWsTask = yield call(handlePrivateWebSocket, privateWsUrl)
}

export function* closePrivateWebSocketConnection() {
  if (PrivateWsInstance) {
    PrivateWsInstance.close()
    PrivateWsInstance = null
    newPrivateWebsocket = false
  }
}

//*******************************************************************************/

const createTradesWebSocketChannel = ws => {
  return eventChannel(emitter => {
    ws.onopen = () => {
      console.log('WebSocket connection opened')
      newTradesWebsocket = true
      setTimeout(() => {
        emitter({ type: TRADES_WEBSOCKET_OPENED })
        emitter({ type: SUBSCRIBE_TO_TRADES_MARKET })
      }, 5000)
      // Additional logic if needed
    }

    ws.onmessage = event => {
      // Handle incoming WebSocket messages and emit corresponding actions
      const tickers = JSON.parse(event.data)
      const key = Object.keys(tickers)[0]

      switch (key) {
        case `${subscribedTradesMarket}.trades`: {
          if (canReadTradesWSData) {
            const trades = tickers[`${subscribedTradesMarket}.trades`].trades
            emitter(addTrade(trades[0]))

            canReadTradesWSData = false
            setTimeout(() => {
              canReadTradesWSData = true
            }, tradesUpdateThreshold)
          }
          break
        }
        default:
          break
      }
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
      newTradesWebsocket = false
      console.log('newTradesWebsocket', newTradesWebsocket)
    }
    // Return the unsubscribe function
    return () => {
      // Cleanup logic if needed
    }
  })
}

export function* handleTradesWebSocket(tradesWsUrl) {
  TradesWsInstance = new WebSocket(tradesWsUrl)
  const channel = yield call(createTradesWebSocketChannel, TradesWsInstance)
  try {
    while (true) {
      const action = yield take(channel)
      yield put(action)
    }
  } finally {
    // Cleanup logic if needed
  }
}

export function* openTradesWebSocketConnection() {
  const selectedMarket = yield select(state => state.selectedMarket.value)
  resolution = yield select(state => state.kline.resolution)
  const subscribeMessage = {
    event: 'subscribe',
    streams: [`${selectedMarket.id}.trades`]
  }
  yield put(setOrderBookLoad(true))
  tradesWsUrl = window.location.host.includes('localhost')
    ? `wss://exchange-stage.wenbit.com/websocket/trades/market`
    : `wss://exchange-stage.wenbit.com/websocket/trades/market`

  if (!TradesWsInstance) {
    tradesWsTask = yield call(handleTradesWebSocket, tradesWsUrl)
    yield take(TRADES_WEBSOCKET_OPENED) // Wait for WebSocket connection to be opened
  }

  // yield take(SUBSCRIBE_TO_TRADES_MARKET)
  // console.log(subscribedTradesMarket)
  // console.log(selectedMarket.id)
  if (subscribedTradesMarket && subscribedTradesMarket !== selectedMarket.id) {
    yield put({ type: UNSUBSCRIBE_TRADES_FROM_MARKET, market: subscribedTradesMarket })
  }
  if (!newTradesWebsocket) {
    yield take(TRADES_WEBSOCKET_OPENED)
  }
  if ((newTradesWebsocket || !subscribedTradesMarket) && subscribedTradesMarket !== selectedMarket.id) {
    TradesWsInstance.send(JSON.stringify(subscribeMessage))
  }
  // if (!subscribedTradesMarket) {
  // TradesWsInstance.send(JSON.stringify(subscribeMessage))
  // }
  subscribedTradesMarket = selectedMarket.id
}

function* openTradesConnection() {
  tradesWsUrl = window.location.host.includes('localhost')
    ? `wss://exchange-stage.wenbit.com/api/v2/ws/public`
    : `wss://${window.location.host}/api/v2/ws/public`

  // Start a new WebSocket task with the updated URL
  tradesWsTask = yield call(handleTradesWebSocket, tradesWsUrl)
}

export function* closeTradesWebSocketConnection() {
  if (TradesWsInstance) {
    TradesWsInstance.close()
    TradesWsInstance = null
    newTradesWebsocket = false
  }
}

export function* unsubscribeTradesFromMarket(action) {
  const { market } = action
  resolution = yield select(state => state.kline.resolution)
  const unsubscribeMessage = {
    event: 'unsubscribe',
    streams: [`${market}.trades`]
  }
  console.log(unsubscribeMessage)

  TradesWsInstance.send(JSON.stringify(unsubscribeMessage))
}

function* subscribeSocket() { }

function* unsubscribeSocket() { }

function* handleResolutionChange() {
  yield take(CHANGE_KLINE_RESOLUTION)
  console.log('testtttttt')
  const oldResolution = yield select(state => state.kline.oldResolution)
  const currentResolution = yield select(state => state.kline.resolution)
  const selectedMarket = yield select(state => state.selectedMarket.value)
  console.log('---RESOLUTIONS---', oldResolution, currentResolution)
  if (oldResolution && oldResolution !== currentResolution) {
    // Unsubscribe from old resolution stream
    const unSubscribeKlineMessage = {
      event: 'unsubscribe',
      streams: [`${selectedMarket.id}.kline-${oldResolution}`]
    }
    // PublicWsInstance.send(JSON.stringify(unSubscribeKlineMessage))

    // Subscribe to new resolution stream
    const subscribeKlineMessage = {
      event: 'subscribe',
      streams: [`${selectedMarket.id}.kline-${currentResolution}`]
    }
    // PublicWsInstance.send(JSON.stringify(subscribeKlineMessage))
  }
}

export function* websocketSaga() {
  yield takeEvery(SUBSCRIBE_TO_PUBLIC_MARKET, openPublicWebSocketConnection)
  yield takeEvery(SUBSCRIBE_TO_PRIVATE_MARKET, openPrivateWebSocketConnection)
  yield takeEvery(UNSUBSCRIBE_FROM_MARKET, unsubscribeFromMarket)

  yield takeEvery(UNSUBSCRIBE_TRADES_FROM_MARKET, unsubscribeTradesFromMarket)
  yield takeEvery(OPEN_TRADES_CONNECTION, openTradesConnection)
  yield takeEvery(CLOSE_TRADES_WEBSOCKET, closeTradesWebSocketConnection)
  yield takeEvery(SUBSCRIBE_TO_TRADES_MARKET, openTradesWebSocketConnection)

  yield takeEvery(CLOSE_PUBLIC_WEBSOCKET, closePublicWebSocketConnection)
  yield takeEvery(CLOSE_PRIVATE_WEBSOCKET, closePrivateWebSocketConnection)
  yield takeEvery(OPEN_PRIVATE_CONNECTION, openPrivateConnection)
  yield takeEvery(OPEN_PUBLIC_CONNECTION, openPublicConnection)
  yield takeEvery(CHANGE_KLINE_RESOLUTION, handleResolutionChange)
  yield takeEvery(SUBSCRIBE_TO_SOCKET, subscribeSocket)
  yield takeEvery(UNSUBSCRIBE_TO_SOCKET, unsubscribeSocket)
}