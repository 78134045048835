import { store } from '../../../../redux/store'
import {
  setViewStatkingSubscriptionHistoryModalData,
  setViewStatkingSubscriptionHistoryModalOpen
} from './redux/viewStatkingSubscriptionHistoryModalSlice'

export const showViewStakingSubscriptionHistoryModal = data => {
  store.dispatch(setViewStatkingSubscriptionHistoryModalData(data))
  store.dispatch(setViewStatkingSubscriptionHistoryModalOpen(true))
}

export const closeViewStakingSubscriptionHistoryModal = () => {
  store.dispatch(setViewStatkingSubscriptionHistoryModalData(null))
  store.dispatch(setViewStatkingSubscriptionHistoryModalOpen(false))
}
