import { createSlice } from '@reduxjs/toolkit'

export const preferredCurrencySlice = createSlice({
  name: 'preferredCurrency',
  initialState: {
    value: {
      id: "USD",
      label: "USD",
      value: "USD",
      symbol: "$",
      state: "active"
    }
  },
  reducers: {
    setPreferredCurrency: (state, action) => {
      state.value = {
        ...action.payload,
        id: action.payload.id.toUpperCase()
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setPreferredCurrency } = preferredCurrencySlice.actions

export default preferredCurrencySlice.reducer