// ** API Imports
import { API } from '../../../../../../utility/API'

//************************************//
export const _getBalances = (callback, callbackErr) => {
  API.get(`trading/account/balances`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getStakingEstimateRewards = ({ amount = null, staking_period_id = null }, callback, callbackErr) => {
  API.post(`trading/account/staking/estimate_reward`, { amount, staking_period_id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _createStakingSubscription = ({ amount = null, staking_period_id = null }, callback, callbackErr) => {
  API.post(`trading/account/staking/new`, { amount, staking_period_id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getStakingPeriods = ({ currency_id, product_name, currency_name }, callback, callbackErr) => {
  API.get(`trading/earn/staking_periods`, { params: { search: { currency_id, product_name, currency_name } } })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
